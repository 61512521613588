/* Main elements */

html, body {
    background-color: #FBFBFB;
}

body {
    /*font-family: Ubuntu, "times new roman", times, roman, serif;*/
    font-family: Ubuntu, sans-serif;
}

/*body {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

body::-webkit-scrollbar {
    display: none;
}*/

/**
 * ID elements - Start
 */

#login, #loginForm,
#register, #registerForm,
#forgotPassword, #forgotPasswordForm,
#resetPassword, #resetPasswordForm,
#sidebar, #mainContent {
    min-height: calc(100vh);
}

#login-register-back {
    margin-top: -10px;
}

/**
 * ID elements - End
 */

/* ########################################################################## */

/**
 * Login, Registration and Forgot password
 */

#login, #forgotPassword, #resetPassword {
    /*background-image: url('./assets/images/login.5ad69cdb.png');
    background-size: cover;
    background-repeat: no-repeat;*/
    background-color: #374D6C;
}

#register {
    /*background-image: url('./assets/images/signup.d73ccc0b.png');
    background-size: cover;
    background-repeat: no-repeat;*/
    background-color: #374D6C;
}

#loginForm, #registerForm, #forgotPasswordForm {
    background-color: #fff;
    color: #999;
    overflow-y: auto;
}

#registerForm .button--sign {
    margin-top: 25px !important;
}

#registerForm .tbs--form--title {
    margin-top: 30px !important;
    padding-top: 20px;
}

#registration-alert {
    margin: 10px auto;
    width: 80%;
}

#registration-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

#registerError {
    display: none;
}

#registerError.showError {
    display: block;
    color: #ff0000;
}

#success-register-alert,
#success-reset-password-alert {
    width: 80%;
    margin: 10px auto;
}

#success-register-alert > div,
#success-reset-password-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

#email-login,
#password-login {
    position: relative;
}

#email-login img,
#password-login img {
    position: absolute;
    top: 11px;
    left: 10px;
    width: 25px;
    height: 25px;
}

#loginForm label,
#registerForm label,
#forgotPasswordForm label {
    font-weight: 500 !important;
    color: #141C27 !important;
}

#loginForm button,
#registerForm button,
#forgotPasswordForm button {
    width: 100%;
}

#loginForm .button--sign {
    margin-top: 50px;
    margin-bottom: 30px;
}

#loginForm .button--sign button {
    font-weight: 500 !important;
    padding: 10px 30px !important;
}

/*#loginForm .login-email::before {
    content: url("./assets/icons/IconUser.png");
    width: 25px;
    height: 25px;
    display: block;
}

#loginForm .login-password::before {

}*/


#loginForm .tbs--form--title {
    font-size: 30px;
    margin-bottom: 50px;
    /*margin-top: 100px;*/
}

/**
 * Login, Registration and Forgot password - End
 */

#forgotPasswordForm .tbs--form--title {
    margin-bottom: 30px;
}

#forgotPasswordForm .button--sign {
    margin-top: 20px;
    margin-bottom: 10px;
}

/* ########################################################################## */

/**
 * Onboarding (Register) - Start
 */

#onboarding-step-one a > img,
#onboarding-step-two a > img {
    width: 200px;
    margin-top: 15px;
}

#onboarding-step-one .tbs--form--title,
#onboarding-step-two .tbs--form--title {
    margin-bottom: 30px;
}

.tbs--onboarding--steps {
    margin: 25px 0px;
    text-align: center;
}

.tbs--onboarding--steps img {
    margin-bottom: 20px;
    width: 35px;
    height: 35px;
    text-align: center;
}

/**
 * Onboarding (Register) - End
 */

/* ########################################################################## */

/**
 * Main page
 */

#main {
    position: relative;
    height: calc(85vh);
    background-color: #fff;
    margin-left: -30px !important;
    margin-right: -30px !important;
}

#main > div {
    text-align: center;
    margin: auto;
}

#main .tbs--dashboard--title{
    color: #141c27;
}

#main hr {
    width: 300px;
}

/**
 * Main page - End
 */

/* ########################################################################## */

/**
 * Main navigation - Start
 */

#homepage-dashboard {
    text-decoration: none;
}

#mainNavi {
    border-bottom: 1px solid #eee;
    z-index: 1050;
    background-color: #374D6C !important;
}

#mainNavi a .logo--headline {
    text-decoration: none;
}

#mainNavi a .logo--headline {
    text-decoration: none;
}


#mainNavi a:focus,
#mainNavi a:link,
#mainNavi a:visited,
#mainNavi a:hover,
#mainNavi a:active {
    text-decoration: none;
}

#mainNavi .logo--headline {
    font-weight: 500;
    text-decoration: none;
    color: #fff;
    margin-top: -3px;
}

#mainContent {
    background-color: #fafafa;
}

.navbar-expand-sm,
.navbar-expand-md,
.navbar-expand-lg,
.navbar-expand-xl {
    justify-content: flex-end;
}

.navbar-brand > a {
    left: 20px;
    position: fixed;
    font-size: 28px;
    font-weight: bold;
    margin-left: -5px !important;
    top: 15px;
}

nav {
    box-shadow: none !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    background-color: #fff;
}

.dropdown-toggle::after {
    display: none;
}

.mainNav {
    padding-left: 0;
    padding-right: 0;
}

/**
 * Main navigation - End
 */

/* ########################################################################## */

/**
 * Main content - Start
 */

#mainContent > .row > div {
    padding-left: 0;
    padding-right: 0;
}

/**
 * Main content - End
 */

/* ########################################################################## */

/**
 * Sidebar - Start
 */

#sidebar {
    background-color: #fff;
    z-index: 1100;
    box-shadow: 0px 75px 10px #ccc;

}

#sidebar > div:nth-child(2) {
    margin-top: 65px;
}

#sidebar .tbs--image {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border: 0.5px solid #141c27;
}

.sidebar--menu--headline {
    padding-bottom: 20px;
    position: fixed;
    flex: 0 0 25%;
    max-width: 25%;
    margin-left: -15px;
    z-index: 10000;
}

.tbs--sidebar--menu {
    list-style: none;
    padding-left: 0;
    height: calc(87vh);
    margin-left: 20px;
}

.tbs--sidebar--menu li {
    padding: 15px 0px;
    /*margin: auto;*/
    max-width: 240px;
}

.sidebar--menu--item {
    padding: 15px 0px;
    text-align: center;
}

.sidebar--menu--item li:nth-child(1) > a.tbs--active--link::after,
.sidebar--menu--item li:nth-child(2) > a.tbs--active--link::after,
.sidebar--menu--item li:nth-child(3) > a.tbs--active--link::after,
.sidebar--menu--item li:nth-child(4) > a.tbs--active--link::after,
.sidebar--menu--item li:nth-child(5) > a.tbs--active--link::after {
    content: "-";
    width: 8.5px;
    height: 40px;
    background-color: #141c27;
    border-radius: 15px 0px 0px 15px;
    position: absolute;
    left: -15px;
    transform: rotate(180deg);
}

.sidebar--menu--item li:nth-child(1) > a.tbs--active--link::after {
    top: 25px;
}

.sidebar--menu--item li:nth-child(2) > a.tbs--active--link::after {
    top: 85px;
}

.sidebar--menu--item li:nth-child(3) > a.tbs--active--link::after {
    top: 140px;
}

.sidebar--menu--item li:nth-child(4) > a.tbs--active--link::after {
    top: 205px;
}

.sidebar--menu--item li:nth-child(5) > a.tbs--active--link::after {
    top: 265px;
}

.sidebar--menu--item li:nth-child(4) img,
.sidebar--menu--item li:nth-child(5) img {
    width: 32px;
}

.sidebar--menu--image {
    padding: 0px 25px 0px 20px;
}

.sidebar--menu--image--custom {
    padding: 0px 26px 0px 20px;
}

.sidebar--menu--item:nth-child(6) img {
    width: 33px;
}

#sidebar > div.sidebar--menu--item > ol > li:nth-child(1) > a.tbs--active--link > img {
    border: 2px solid #141c27;
}

.tbs--quick--action {
    /*border: 1px solid #141c27;
    border-radius: 5px;*/
    padding: 5px;
    max-width: 100%;
    margin: 10px auto;
}

.tbs--quick--action a,
.tbs--quick--action a:hover {
    text-decoration: none;
    color: #91959A;
}

.tbs--quick--action img {
    /*border: 1px solid #141c27;
    border-radius: 5px;*/
    max-width: 100%;
}

.tbs--quick--action .tbs--quick--action--image {
    border: 1px solid #141c27;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    padding: 2.5px;
}

.tbs--quick--action--image svg {
    margin-top: -5px;
    margin-left: 1px;
}

.tbs--line--equal {
    font-size: 16px;
    line-height: 1.8;
    color: #141c27 !important;
}

/**
 * Sidebar - End
 */

/* ########################################################################## */

/**
 * Dashboard - Start
 */

#dashboard nav a,
#dashboard nav > div {
    margin-right: 10px;
    margin-left: 10px;
}

#dashboardContent {
    padding-top: 68px;
    margin-bottom: 50px;
}

#dashboardContent > div {
    margin-left: 20px;
    margin-right: 20px;
}

#sidebar .link--navi--style.tbs--active--link:before {
    position: relative;
    right: 0;
    width: 10px;
    height: 5px;
    background-color: #141c27;
    border-radius: 25px;
}

.tbs--active--link {
    color: #141c27 !important;
    font-weight: 600;
}

#logout-modal .button--sign {
    margin: 30px 0px 10px 0px;
}

#logout-modal .btn,
#awards-info .btn {
    width: 150px;
}

/**
 * Dashboard - End
 */

/* ########################################################################## */

/**
 * Awards - Start
 */

#awards,
#awards--other {
    padding-left: 0;
    padding-right: 0;
}

#awards a,
#awards--other a {
    text-decoration: none;
    color: #000;
}

#awards > .divider--line,
#awards--other > .divider--line {
    width: 97%;
    z-index: 0;
}

#awards img,
#awards .tbs--contact--image,
#awards--other img,
#awards--other .tbs--contact--image {
    border: none !important;
}

.tbs--awards--headline {
    color: #141c27;
    /*font-weight: bold;*/
    font-size: 18px;
    margin-top: 10px;
}

.tbs--awards--headline > span {
    padding-left: 10px;
}

.tbs--contact--friends--awards {
    position: relative;
}

.tbs--contact--friends--awards img {
    border-radius: 50%;
    border: 5px solid #E0F9FF;
    background-color: #fff;
}

.tbs--category--awards,
.tbs--contact--awards {
    margin-top: 20px;
}

.tbs--contact--awards {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
}

.tbs--contact--awards > .tbs--contact--awards--profiles {
    display: inline-block;
    margin-right: 20px;
}

.tbs--contact--awards::-webkit-scrollbar {
    display: none;
}

.tbs--contact--awards {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/**
 * Awards - End
 */

/* ########################################################################## */

/**
 * Menu Bottom - Start
 */

#menuBottom {
    margin: 0px !important;
    padding: 15px 0px;
    border-top: 1px solid #eee;
    z-index: 10500;
    background-color: #374D6C !important;
}

#menuBottom .tbs--image {
    width: 25px;
    border-radius: 20px;
    object-fit: cover;
    height: 25px;
    margin-top: 5px;
    border: none !important;
}

#menuBottom svg,
#menuBottom img {
    width: 25px;
    height: 35px;
}

.bottom--menu--item {
    /*position: fixed;*/
    bottom: 25px;
    width: 25%;
    /*margin-left: -51px;*/
}

#menuBottom > div:nth-child(5) > a.tbs--active--link > img {
    border: 2px solid #141c27;
}

/**
 * Menu Bottom - End
 */

/* ########################################################################## */

/**
 * Submenu - Start
 */

.new-feedlist .tbs--headline,
#profile #profile--submenu .tbs--container--feedlist .tbs--headline,
#network--submenu .tbs--container--feedlist .tbs--headline {
    cursor: pointer;
}

#profile--submenu {
    margin-top: 10px;
}

/**
 * Submenu - End
 */

/* ########################################################################## */

/**
 * Profile and other profile - Start
 */

#business > .row,
#business--other > .row,
#business--other-two > .row,
#business-two > .row {
    padding-bottom: 15px;
}

#business--other > .row,
#business--other-two > .row {
    padding-bottom: 15px;
}

#moreProfile .row:first-child > div:first-child {
    padding-bottom: 20px;
}

#network-publication > div {
    text-align: center;
}

#network-publication a {
    display: inline-block;
    margin-left: 10px;
    width: 200px;
}

.tbs--main--data {
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    padding-top: 75px;
    background-color: #fff;
}

.tbs--main--data--contact {
    padding: 20px 0px;
    background-color: #fff;
    position: relative;
    display: block;
    margin: auto;
}

.tbs--main--data--contact--feature {
    display: inline-block;
    /*margin-left: 25px;*/
}

.tbs--main--data--contact--introduce {
    display: inline-block;
    /*margin-left: 25px;
    margin-right: 25px;*/
}

.tbs--main--data--contact--chat {
    display: inline-block;
    /*margin-right: 25px;*/
}

.tbs--main--data--contact--feature img,
.tbs--main--data--contact--introduce img,
.tbs--main--data--contact--chat img {
    width: 25px;
    height: 25px;
    object-fit: scale-down;
}

.tbs--main--data--contact--introduce img {
    width: 29px !important;
}

.tbs--main--data--contact--chat {
    /*max-width: 50px;*/
    /*margin: auto;*/
}

.tbs--accordion--info--block {
    margin-bottom: 10px;
    font-size: 14px;
}

.tbs--block--user {
    background-color: #fff;
    margin: 10px auto;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
}

.tbs--main--data--contact--feature,
.tbs--main--data--contact--introduce,
.tbs--main--data--contact--chat {
    width: 125px;
}

.tbs--main--data--contact--feature div:first-child,
.tbs--main--data--contact--introduce div:first-child,
.tbs--main--data--contact--chat div:first-child {
    color: #fff;
    /*background-color: #141c27;
    border: 1px solid #141c27;*/
    padding: 10px;
    border-radius: 25px;
}

.tbs--main--data--contact--feature div:first-child,
.tbs--main--data--contact--introduce div:first-child,
.tbs--main--data--contact--chat div:first-child {
    display: block;
    margin: auto;
    margin-bottom: 10px;
    max-width: 62px;
}

.tbs--main--data--contact--feature div:last-child,
.tbs--main--data--contact--introduce div:last-child,
.tbs--main--data--contact--chat div:last-child {
    color: #141c27;
    font-weight: bold;
}

.tbs--main--data--contact--mutual--featured--user a {
    color: #141c27;
    text-decoration: underline;
}

.tbs--main--data--contact--mutual--featured--user span {
    margin: 0px 20px
}

.tbs--more--data {
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 20px;
    background-color: #fff;
}

.tbs--image--main {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    left: 48.3%;
    top: 30%;
    transform: translateX(-50px);
    z-index: 1;
    object-fit: cover;
    background-color: #fff;
}

.tbs--image--menu--profile {
    position: relative;
    right: -50px;
    /*top: 25px;*/
    transform: translateX(-50px);
    z-index: 1;
    height: 25px;
    object-fit: cover;
}

.tbs--contact--data--name {
    font-weight: bold;
    font-size: 18px;
}

.tbs--contact--data--work,
.tbs--contact--data--region {
    color: #727272;
    font-size: 16px;
    line-height: 1;
}

.tbs--about--data {
    padding: 20px 0px;
    font-size: 14px;
}

.divider--line {
    color: #ccc;
    width: 95%;
}

#tbs--menu--other--profile {
    position: absolute;
    right: 8.5px;
    top: 65%;
    z-index: 10;
    display: none;
    color: #ccc;
}

#tbs--menu--other--profile:before {
    content: "";
    position: absolute;
    top: -10px;
    right: 25px;
    width: 0;
    height: 0;
    background-image: linear-gradient(to bottom right, #4BADC7, #49ACC6);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #fff;
    z-index: 1;
}

#tbs--menu--other--profile > div {
    width: 125px;
    height: 125px;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 15px;
}

#tbs--menu--other--profile > div > div:first-child {
    margin: 5px 0px 10px 0px;
}

#tbs--menu--other--profile > div > div {
    margin: 10px 0px;
}

.open-menu {
    display: block !important;
}

#profile,
.tbs--friends {
    margin-top: 30px !important;
    max-width: 1050px;
    margin: 20px auto 0px !important;
}

.tbs--submit--introduce--button .button--sign button {
    width: 125px;
}

#profile > div:first-child,
.tbs--friends > div:first-child {
    padding: 0px !important;
}

.tbs--friends > div:nth-child(4) {
    padding: 0;
}

#profile #awards div:first-child {
    margin-bottom: 10px;
}

#profile .tbs--contact--data--feeds--infos > span {
    float: left;
}

#profile .tbs--contact--data--feeds--infos > span:last-child:after {
    clear: both;
}

#profile .tbs--sub--post--line {
    text-align: center;
    margin-top: 20px;
}

#profile .tbs--sub--post--line span > span,
#profile .tbs--sub--post--line span > a > span {
    margin-left: 10px;
}

#profile .button--sign {
    margin-top: 30px;
    margin-bottom: 0px;
}

.card,
.card-header {
    border: 0px;
}

.card-header {
    background-color: transparent;
    padding-top: 0px;
    padding-bottom: 0px;
}

.card-header > button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;
    color: #141c27;
    font-weight: bold;
}

.arrow {
    position: absolute;
    top: 7px;
    margin-left: 10px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 12px solid #141c27;
    z-index: 1;
}

.arrow--active {
    position: absolute;
    top: 7px;
    margin-left: 10px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 12px solid #141c27;
    z-index: 1;
    transform: rotate(180deg);
}

.arrow-browser-safari {
    margin-left: 110px !important;
}

.tbs--category--data--headline {
    font-weight: bold;
    color: #141c27;
    padding-bottom: 20px;
    font-size: 18px;
}

.tbs--category--data--subheadline {
    font-weight: bold;
    color: #1D1D1D;
    font-size: 14px;
}

.tbs--category--data--subcontent {
    color: #707070;
    /*font-weight: 500;*/
    padding-right: 10px;
    font-size: 14px;
    margin-top: 10px;
}

#own--feeds,
#other--profile--feeds{
    margin-top: 30px;
}

#own--feeds a,
#other--profile--feeds a,
#own--feeds a:hover,
#other--profile--feeds a:hover,
#own--feeds a:visited,
#other--profile--feeds a:visited,
#own--feeds a:active,
#other--profile--feeds a:active,
#own--feeds a:link,
#other--profile--feeds a:link,
#own--feeds a:focus,
#other--profile--feeds a:focus {
    text-decoration: none;
    color: #000;
}

#own--feeds a > div:nth-child(2) {
    margin: 10px 15px 10px 0px;
}

#own--feeds a > div {
    margin: 10px 0px;
}

#own--feeds a > div div {
    padding: 0px;
}

#own--feeds [src=''],
#other--profile--feeds [src='']{
    display: none;
}

.tbs--mutual--friends--image > img,
.tbs--featured--users--image > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.tbs--mutual--friends--name,
.tbs--featured--users--name {
    font-size: 12px;
}

.tbs--friends .btn.btn-primary,
.tbs--friends .btn.btn-primary:focus,
.tbs--friends .btn.btn-primary:link,
.tbs--friends .btn.btn-primary:visited,
.tbs--friends .btn.btn-primary:hover,
.tbs--friends .btn.btn-primary:active  {
    color: transparent !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.tbs--update--post > a {
    color: #ccc !important;
}

#audio-recorder.button--sign {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}

.tbs-highlight-posts {
    position: relative;
}

.tbs-my-posts > div:first-child,
.tbs-friend-posts > div:first-child > a,
.tbs-friend-posts-profile > div:first-child,
.tbs-highlight-posts > div:nth-child(2) {
    padding-right: 0 !important;
    display: table-row;
    margin-top: -30px !important;
}

.tbs-friend-posts > div:first-child > a {
    text-decoration: none;
    color: #000;
}

.tbs-friend-posts > div:first-child > a > div > .tbs--container--feedlist--image,
.tbs-friend-posts > div:first-child > a > div > .tbs--container--post--image{
    margin-top: 0 !important;
}

.tbs-friend-posts > div:first-child > a > div:nth-child(2) {
    position: relative;
    top: -16px;
}

.tbs-my-posts > div:first-child > div,
.tbs-friend-posts > div:first-child > a > div,
.tbs-highlight-posts > div:nth-child(2) > div {
    display: table-cell;
}

.tbs-my-posts > div:first-child > div:first-child,
.tbs-my-posts > div:first-child > a > div:first-child,
.tbs-my-posts > div:first-child > div:last-child,
.tbs-friend-posts > div:first-child > div:first-child,
.tbs-highlight-posts > div:nth-child(2) > div:first-child,
.tbs-friend-posts > div:first-child > div:last-child,
.tbs-highlight-posts > div:nth-child(2) > div:last-child {
    display: table-cell;
    width: 50px;
}

.tbs-friend-posts > div:first-child > div:nth-child(2),
.tbs-highlight-posts > div:nth-child(2) > div:nth-child(2),
.tbs-my-posts > div:first-child > div:nth-child(2),
.tbs-friend-posts > div:first-child > div:nth-child(2),
.tbs-highlight-posts > div:nth-child(2) > div:nth-child(2) {
    display: table-cell;
    width: 82%;
}

.tbs-my-posts > div:first-child > div:nth-child(2),
.tbs-my-posts > div:first-child > a > div:nth-child(2) {
    display: table-cell;
    width: 100%;
}

#dropdown-menu-post {
    background-color: #fff !important;
    border-color: #fff !important;
}

#dropdown-menu-post ~ .dropdown-menu {
    /*margin-top: 8px !important;*/
    margin-left: 5px !important;
}

#tbs--post--submenu #dropdown-menu-post ~ .dropdown-menu {
    /*margin-top: 8px !important;*/
    margin-left: -3px !important;
}

#dropdown-menu-post ~ .dropdown-menu:before {
    right: 15px;
}

#dropdown-menu-post > img {
    width: 10px !important;
    margin-left: -5px;
}

.tbs--own--post--submenu {
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.tbs--own--post--submenu span {
    margin-left: 10px;
}

.tbs-highlight-posts > div:nth-child(2) > div > img {
    width: 50px !important;
    height: 50px !important;
    object-fit: cover;
}

.tbs-my-posts > div:last-child,
.tbs-friend-posts > div:last-child
/*.tbs-highlight-posts > div:last-child*/ {
    padding-right: 15px !important;
}

#network-publication button {
    width: 185px;
    position: relative;
}

#network-publication span {
    margin-left: 10px;
    line-height: 2;
}

#network-publication img {
    width: 30px;
    height: 30px;
    /*object-fit: cover;*/
    margin-right: 10px;
}

#network-publication a:nth-child(2) button {
    padding: 14.5px 25px !important;
}

#network .tbs--network--post--bookmark {
    margin-left: -60px !important;
    margin-top: -7.5px;
}

#remove-user.button--sign {
    margin: 0 !important;
}

#edit-profile {
    position: absolute;
    right: 15px;
    top: 10px;
}

#edit-profile img {
    width: 20px;
    height: 20px;
    /*border: 2.5px solid #fff;
    border-radius: 50%;*/
}

.delete-post-confirmation .button--sign {
    margin-top: 20px;
}

#profile .report-post-container,
#profile .bookmark-post-container {
    margin-left: 10px;
    cursor: pointer;
}

#profile .report-post-container > div,
#profile .bookmark-post-container > div {
    margin-top: 0px !important;
}

/* Introduce - Start */

.tbs--avatar--select--user img {
    width: 125px;
    height: 125px;
    object-fit: cover;
}

.tbs--avatar--select--user > img,
.tbs--avatar--select--user > div {
    display: inline-grid;
}

.tbs--avatar--select--user img:nth-child(2),
.tbs--avatar--select--user > div > div > img {
    margin-left: 20px;
    border-radius: 15px;
}

.tbs--avatar--select--user > div > div:last-child {
    margin-left: 20px;
}

.tbs--contact--introduce {
    color: #000 !important;
}

.tbs--contact--introduce div {
    margin: 10px 0px;
}

.tbs--contact--introduce img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 25px;
}

.tbs--contact--introduce > div {
    /*line-height: 2.5;*/
    height: 50px;
}

.tbs--contact--introduce > div:nth-child(3) {
    line-height: 2.75;
}

.tbs--contact--introduce > div:nth-child(2) {
    margin-left: 10px;
    padding-top: 10px;
}

.tbs--contact--introduce input[type=radio] {
    width: 15px;
    margin-right: 10px;
}

.tbs--submit--contact .button--sign {
    margin-top: 0;
}

.tbs--submit--introduce--button .button--sign {
    margin-top: 10px;
}

#creator-image > div:first-child {
    margin-top: 10px;
    margin-bottom: 20px;
}

#creator-image > div a {
    text-decoration: none;
}

#creator-image > div img,
#creator-image > div div {
    width: 100%;
}

#creator-image > div:first-child img {
    height: calc(35vh);
}

#creator-image > div:not(:first-child) img {
    height: calc(35vh);
}

#creator-image > div img {
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
}

#creator-image > div div {
    text-align: center;
    color: #fff;
    background-color: #141C27;
    border-radius: 0px 0px 10px 10px;
    padding: 10px;
}

#default-text-introduce {
    margin-top: 20px;
}

#default-text-introduce > div {
    color: #000 !important;
    background-color: #fff !important;
    text-align: left !important;
}

/*#creator-image > div:nth-child(2),
#creator-image > div:nth-child(3) {
    height: 100%;
}*/

#creator-image > .tbs--receiver--block {
    position: relative;
}

#creator-image > .tbs--receiver--block > span {
    position: absolute;
    right: 25px;
    top: 10px;
    width: 45px;
    height: 45px;
    background-color: #141C27;
    color: #fff;
    text-align: center;
    border-radius: 50%;
}

#creator-image > .tbs--receiver--block > span svg {
    width: 25px;
    height: 25px;
    margin-top: 10px;
}

/* Introduce - End */

/**
 * Profile and other profile - End
 */

/* ########################################################################## */

/**
 * Mutual friends - Start
 */

.modal {
    z-index: 22040 !important;
}

.modal-backdrop {
    z-index: 22000 !important;
}

.modal-body > .row > div > a {
    margin: 10px 0px;
    text-decoration: none;
    color: #000;
}

.modal-body > .row > div {
    margin-bottom: 15px;
}

#image-profile-big {
    padding-top: 0;
    padding-bottom: 1px;
}

#image-profile-big img {
    width: 100%;
}

/**
 * Mutual friends - End
 */

/* ########################################################################## */

/**
 * Audio Recording - Start
 */

#audio-recording-update-post {
    margin: 0;
    padding: 0;
}

#audio-recording-update-post > div > div {
    margin: 0;
    padding: 0;
}

#audio-recording-update-post > div > div > div {
    background-color: transparent;
    color: #000;
    min-height: 300px;
    max-height: 350px;
}

#audio-recording-update-post > div > div > div > div:first-child,
#audio-recording-update-post > div > div > div > div:nth-child(2) > div:first-child,
#audio-recording-update-post > div > div > div > div > div > a:nth-child(2) {
    display: none;
}

/*#audio-recording-update-post > div > div > div > div > div > p {
    display: none;
}*/

#audio-recording-update-post > div > div > div > div > div > div > span,
#audio-recording-update-post > div > div > div > div > div > p {
    color: #000;
    text-align: center;
}

#audio-recording-update-post > div > div > div > div > div > a:first-child > span {
    background-color: #fff;
}


#audio-recording-update-post > div > div > div > div > div > a:nth-child(1) {
    background-color: #141c27 !important;
    margin-right: 0;
}

#audio-recording-update-post > div > div > div > div > a {
    background-color: #141c27 !important;
}

#audio-recording-update-post > div > div > div > div > a:hover > span > svg {
    fill: #fff !important;
}

#audio-recording-update-post-reset {
    background-color: #141c27 !important;
    width: 60px;
    height: 60px;
    line-height: 3.75;
    border-radius: 50%;
    color: #fff;
    margin: auto;
}

.show-record {
    display: flex !important;
}

.hide-record {
    display: none !important;
}

.show-audio-player {
    display: block !important;
}

.hide-audio-player {
    display: none !important;
}

.add-bottom {
    margin-bottom: -100px !important;
}

.remove-bottom {
    margin-bottom: 0 !important;
}

/**
 * Audio Recording - End
 */

/* ########################################################################## */

/**
 * Tips - Start
 */

.tbs--container--tips {
    margin-bottom: 25px;
    border: 1px solid #E7EEF0;
    border-radius: 10px;
    padding: 20px;
    background-color: #C6CDDC;
    position: relative;
}

.tbs--container--tips > .row {
    margin-right: 0;
    margin-left: 0;
}

.tbs--container--tips svg {
    width: 75px;
    height: 75px;
    cursor: pointer;
}

.tbs--container--tips > div > div:nth-child(2) {
    margin-bottom: -20px;
}

.tbs--container--tips > div > div:first-child > div:last-child {
    color: #141C27;
    margin-top: 10px;
}

.tbs--container--tips > div > div:nth-child(2) {
    position: relative;
    text-align: right;
}

.tbs--tips--close {
    margin-bottom: -10px;
}

.tbs--tips--close > img {
    width: 20px;
    height: 20px;
    margin: 5px;
}

.tbs--tips--context > div:first-child {
    padding-bottom: 10px;
}

.tbs--tips--subheadline {
    /*font-weight: bold;*/
    color: #141C27;
}

#tip-feature {
    position: relative;
    display: block;
}

.tips-disabled {
    display: none !important;
}

#tip-trusted .tbs--container--tips,
#tip-feature .tbs--container--tips {
    padding-bottom: 20px !important;
    margin-bottom: 0px !important;
}

/**
 * Tips - End
 */

/* ########################################################################## */

/**
 * Custom Offcanvas Menu - Start
 */

#offcanvas-menu {
    height: 100%;
    width: 280px;
    position: fixed;
    z-index: 1400;
    top: 0;
    right: -280px;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    margin-top: 71px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px #ccc;
}

.right-slide {
    right: 280px !important;
}

.width-menu{
    right: 0 !important;
}

/**
 * Custom Offcanvas Menu - End
 */

/* ########################################################################## */

/**
 * Forms & Co. - Start
 */

.tbs--main--block {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    text-align: center;
}

.tbs--form--block {
    position: absolute;
    top: 45%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    color: #141C27;
}

.tbs--main--block {
    color: #fff;
}

.tbs--main--block .tbs--main--subtitle {
    margin-top: 170px;
    font-size: 20px;
}

.tbs--main--block--community {
    position: fixed;
    transform: translate(-150%, -50%) !important;
}

.tbs--form--title {
    color: #000;
}

.tbs--main--title,
.tbs--form--title {
    text-align: center;
    font-weight: 500;
}

.tbs--forget--password {
    margin: 10px 0px;
    font-weight: 500;
}

.tbs--main--title {
    line-height: 2;
    font-size: 28px;
}

.tbs--main--title svg,
.tbs--main--title img {
    width: 100px;
}

.tbs--form--title {
    font-size: 30px;
    /*margin-bottom: 100px;
    margin-top: 100px;*/
}

/*.tbs--form--title--register {
    margin-bottom: 25px !important;
}*/

/**
 * Forms & Co. - End
 */

/* ########################################################################## */

/**
 * Links - Start
 */

.link--native--style {
    text-decoration: none;
    color: #000;
}

.link--native--style:hover,
.link--native--style:focus,
.link--native--style:active,
.link--native--style:link {
    text-decoration: none;
    color: #000;
}

.link--custom--headline {
    text-decoration: none;
    color: #141c27;
    margin-left: -30px;
    margin-right: -15px;
    background-color: #374D6C;
    padding-left: 50px;
    text-align: left;
}

.link--custom--headline h1 {
    line-height: 2.125;
    color: #fff;
    font-size: 2em !important;
}

.link--custom--headline:hover,
.link--custom--headline:focus,
.link--custom--headline:active,
.link--custom--headline:link {
    text-decoration: none;
    color: #141c27;
}

.link--custom--style {
    text-decoration: underline !important;
    color: #141c27;
}

.link--custom--back--main,
.link--custom--back--main:hover,
.link--custom--back--main:focus,
.link--custom--back--main:active,
.link--custom--back--main:link {
    text-decoration: none !important;
    cursor: pointer;
}

.link--custom--back--overview {
    color: #fff;
    background-color: #374D6C;
    padding: 10px;
    border: 1px solid #374D6C;
    border-radius: 10px;
    margin: 10px 0px;
    text-decoration: none !important;
    text-align: center;
}

.link--custom--public {
    text-align: center;
}

.link--navi--style {
    text-decoration: none;
    color: #777;
    /*font-weight: 600;*/
}

.link--navi--style:hover,
.link--navi--style:focus,
.link--navi--style:active,
.link--navi--style:link {
    text-decoration: none;
    color: #141c27;
}

.link--custom--style:hover,
.link--custom--style:focus,
.link--custom--style:active,
.link--custom--style:link {
    text-decoration: none;
    color: #141c27;
}

/**
 * Links - End
 */

/* ########################################################################## */

/**
 * Dropdown - Start
 */

.dropdown-item {
    margin-top: 10px;
    margin-bottom: 10px;
}

.dropdown-item > img {
    margin-right: 20px;
    width: 30px;
    height: 25px;
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active,
.dropdown-item:link {
    background-color: transparent;
    color: #000;
}

.dropdown-menu {
    border-radius: 15px !important;
    margin-top: 0px !important;
    margin-right: 10px !important;
    padding: 10px 10px;
}

.dropdown-menu:before {
    content: "";
    position: absolute;
    top: -8px;
    right: 25px;
    width: 15px;
    height: 15px;
    transform: rotate(-45deg);
    background-color: #fff;
    border-right: 1px solid rgba(0,0,0,.15);
    border-top: 1px solid rgba(0,0,0,.15);
    z-index: 1;
}

#invitation-overlay {
    margin-right: 0 !important;
}

#invitation-overlay a,
#invitation a,
#notification a,
#notification-overlay a {
    text-decoration: none;
    color: #000;
}

#invitation > div:nth-child(3) > div > div.row > div > p {
    color: #000;
    padding: 0px 10px;
}

#notification > div:nth-child(1) > div {
    border: none;
    background-color: transparent !important;
    padding-bottom: 0px !important;
}

#new-invitation > div:nth-child(3) > div > div:nth-child(1) > div:nth-child(2) {
    padding-right: 30px;
}

#new-invitation .button--sign > button{
    width: 190px;
}

notification .container > .row:first-child,
#notification .container-fluid > .row:first-child {
    border: none !important;
}

#notification > div:nth-child(2) {
    margin-top: 65px;
}

#notification > div:nth-child(2) > div {
    padding-top: 20px;
}

#invitation > div:nth-child(3) {
    padding: 0px;
    margin-top: 60px;
}

#invitation-overlay .dropdown-menu-right {
    right: -17.5px;
    left: auto;
    top: 60px;
}

#invitation-overlay .dropdown-item,
#invitation .dropdown-item,
#notification .dropdown-item,
#notification-overlay .dropdown-item {
    margin-top: 0;
    margin-bottom: 0;
}

#notification-overlay .dropdown-item a,
#invitation-overlay .dropdown-item a {
    color: #141c27;
    font-weight: 500;
    text-decoration: none;
}

#notification-overlay .dropdown-menu-right,
#invitation-overlay .dropdown-menu-right {
    min-width: 300px;
}

#notification-overlay .row.dropdown--element,
#invitation-overlay .row.dropdown--element {
    margin-right: 0px;
    margin-left: 0px;
}

#notification-overlay .row.dropdown--element:not(:first-child),
#invitation-overlay .row.dropdown--element:not(:first-child) {
    margin-bottom: 15px;
}

#notification-overlay .dropdown-menu-right {
    right: -16.5px;
    left: auto;
    top: 63px;
}

#invitation .invitation--element,
#notification .notification--element {
    background-color: #FBFBFB !important;
    border: 2px solid #F9F9F9;
    border-radius: 10px;
    max-width: 75%;
    margin: 0px auto 7px !important;
    padding-bottom: 5px;
}

#invitation .invitation--element > div:first-child,
#notification .notification--element > div:first-child {
    color: #a2a2a2;
    font-size: 12px;
}

#invitation-overlay .dropdown--element,
#notification-overlay .dropdown--element {
    background-color: #FBFBFB;
    border: 2px solid #F9F9F9;
    border-radius: 10px;
}

#invitation .invitation--element .invitation--contact > div:first-child,
#invitation .invitation--element .invitation--details {
    color: #000 !important;
}

#notification-overlay > div > div.dropdown-menu > a:nth-child(2) > div:first-child {
    margin-bottom: -10px;
}

#invitation,
#notification,
#new-invitation {
    margin: 20px auto !important;
    max-width: 1050px;
}

#invitation .row:first-child,
#notification .tbs--container > .row:first-child {
    padding: 0px 0px 10px 5px;
    background-color: transparent;
    color: #fff;
    margin-left: -20px;
    border: none;
}

#invitation .row:first-child > .tbs--headline,
#notification .tbs--container > .row:first-child > .tbs--headline {
    color: #141C27;
}

#invitation .container > .row:nth-child(2),
#invitation .container-fluid > .row:nth-child(2) {
    border-bottom: 1px solid #ccc;
    margin: 0px 15px 0px 0px;
}

#invitation .container > .row:nth-child(2) > div,
#invitation .container-fluid > .row:nth-child(2) > div {
    padding: 0;
}

#invitation .container > .row:nth-child(2) > div > p,
#invitation .container-fluid > .row:nth-child(2) > div > p {
    margin-top: 10px;
}

#invitation .container > .row:nth-child(2) > div:nth-child(2),
#invitation .container-fluid > .row:nth-child(2) > div:nth-child(2) {
    position: relative;
    clear: both;
}

#invitation .container > .row:nth-child(2) > div:nth-child(2) p,
#invitation .container-fluid > .row:nth-child(2) > div:nth-child(2) p,
#invitation .container > .row:nth-child(2) > div:nth-child(2) img,
#invitation .container-fluid > .row:nth-child(2) > div:nth-child(2) img {
    float: left;
}

#invitation .container > .row:nth-child(2) > div:nth-child(2) img,
#invitation .container-fluid > .row:nth-child(2) > div:nth-child(2) img {
    margin-left: 10px;
    width: 25px !important;
    margin-top: 3px;
}

#notification .container > .row:first-child,
#notification .container-fluid > .row:first-child {
    border-bottom: 1px solid #ccc;
    margin: 0px 0px 0px -20px;
}

#notification .container > .row:nth-child(2),
#notification .container-fluid > .row:nth-child(2) {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

#invitation .container > .nav,
#invitation .container-fluid > .nav {
    margin-left: 0px !important;
    margin-right: 15px !important;
    box-shadow: none;
}

#invitation .container > .nav > a,
#invitation .container-fluid > .nav > a {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

#invitation .row.dropdown--element,
#invitation a .row.dropdown--element,
#notification .row.dropdown--element,
#notification a .row.dropdown--element{
    margin-left: 0px !important;
    margin-right: 15px !important;
    margin-bottom: 25px !important;
}

#invitation .tbs--headline--invitation,
#new-invitation .tbs--headline--invitation,
#notification .tbs--headline--notification {
    color: #141C27;
    margin: 15px 0px 20px 0px !important;
    font-size: 24px;
    font-weight: 600;
    z-index: 1;
    background-color: #FAFAFA;
    padding-right: 10px;
    position: absolute;
}

#notification .tbs--headline--notification {
    padding-left: 20px;
    font-size: 24px;
    font-weight: 600;
    z-index: 1;
    padding-right: 10px;
    background-color: #FAFAFA;
}

#new-invitation .tbs--container > .row:first-child {
    margin-right: -20px;
}

#new-invitation .tbs--container > .row:first-child > div:first-child {
    background-color: #fff;
    /*margin-left: -20px;*/
    margin-top: 10px;
    margin-bottom: 10px;
}

#new-invitation .tbs--container > .row:first-child > div:first-child > .tbs--headline {
    padding-left: 20px;
}

#new-invitation > div:nth-child(3) {
    margin-top: 65px;
    padding: 0px;
}

#invitation-overlay a:first-child .dropdown--element:first-child,
#notification-overlay a:first-child .dropdown--element:first-child {
    margin-top: -15px;
    margin-bottom: -15px;
}

#invitation-overlay a:first-child .dropdown--element:not(:first-child),
#notification-overlay a:first-child .dropdown--element:not(:first-child) {
    margin-bottom: -15px;
}

#notification .dropdown--element:first-child,
#notification .dropdown--element:not(:first-child) {
    margin-top: -15px;
    margin-bottom: 25px;
}

#notification a .dropdown--element {
    color: #000;
    text-decoration: none;
}

.dropdown--element > a > .row > div
.dropdown--element > .row > div {
    padding-right: 0;
    padding-left: 0;
}

.invitation--image,
.notification--image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 10px;
}

#invitation-overlay .invitation--image,
#notification-overlay .notification--image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 0px;
}

.invitation--image--custom {
    width: 25px;
    height: 20px;
    /*object-fit: cover;*/
}

.tbs--invitation--headline--info,
.tbs--notification--headline--info {
    display: table-row;
    margin-top: -10px;
}

.tbs--invitation--headline--info div,
.tbs--notification--headline--info div {
    display: table-cell;
}

.tbs--invitation--headline--info div:nth-child(2),
.tbs--notification--headline--info div:nth-child(2) {
    padding-left: 10px;
}

.invitation--contact > div {
    display: table !important;
}

.invitation--contact > div:nth-child(2) {
    padding-left: 0;
}

.invitation--contact > div:first-child,
.notification--contact > div:first-child {
    font-weight: bold;
}

.invitation--contact > div:nth-child(2){
    color: #ccc;
    font-size: 12px;
}

.invitation--details,
.notification--details {
    margin: 10px 0px;
    display: flex;
}

.invitation--details div,
.notification--details div {
    flex: 50%;
}

.invitation--details div:first-child,
.notification--details div:first-child {
    text-align: right;
}

.invitation--details div:last-child,
.notification--details div:last-child {
    text-align: left;
}

.invitation--details span,
.notification--details span {
    margin: 0px 10px;
}

#invitation-overlay .row.dropdown--element > div:first-child,
#invitation .row.dropdown--element > div:first-child,
#invitation a > .row.dropdown--element > div:first-child,
#notification .row.dropdown--element > div:first-child,
#notification a > .row.dropdown--element > div:first-child,
#notification-overlay .row.dropdown--element > div:first-child {
    font-size: 12px;
    color: #a2a2a2;
}

#menu-other-profile .dropdown-menu-right,
#menu-friends .dropdown-menu-right {
    right: -26.5px !important;
    left: auto !important;
    top: 50px !important;
    position: absolute;
    border: 1px solid #ccc;
    transform: none !important;
}

#menu-friends .dropdown-item a,
#menu-other-profile .dropdown-item a {
    text-decoration: none;
    color: #000;
}

#menu-friends .dropdown,
#menu-other-profile .dropdown {
    position: absolute;
    right: 30px;
    top: 20px;
}

#menu-friends .dropdown > div,
#menu-other-profile .dropdown > div {
    padding: 15px;
}

#mainNavi .btn.btn-primary,
#mainNavi .btn.btn-primary:focus,
#mainNavi .btn.btn-primary:link,
#mainNavi .btn.btn-primary:visited,
#mainNavi .btn.btn-primary:hover,
#mainNavi .btn.btn-primary:active,
.show > .btn-primary.dropdown-toggle,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle:link,
.show > .btn-primary.dropdown-toggle:visited,
.show > .btn-primary.dropdown-toggle:hover,
.show > .btn-primary.dropdown-toggle:active {
    color: transparent !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

#dropdown-menu-invitation,
#dropdown-menu-notifications {
    padding: 10px;
}

.tbs--notification--header--block {
    position: relative;
}

.tbs--notification--red--dot {
    width: 12px;
    height: 12px;
    position: absolute;
    background-color: #ff0000;
    border-radius: 50%;
    right: -7px;
    top: -3px;
}

/**
* Dropdown - End
*/

/* ########################################################################## */

/**
 * Invitation - Start
 */

#invitation--tabs--main-tab-receivedMain,
#invitation .nav {
    cursor: auto;
}

/**
 * Invitation - End
 */

/* ########################################################################## */

/**
 * Favourites - Start
 */

#favourites {
    margin: 20px auto !important;
    max-width: 1050px;
}

#favourites [src='']{
    display: none;
}

#favourites a {
    color: #000;
    text-decoration: none;
}

#favourites  .tbs--contact--details {
    min-width: 200px;
    max-width: 1100px;
    position: relative;
}

#favourites  .tbs--contact--details > div {
    display: inline-block;
}

#favourites .tbs--contact--details .tbs--contact--image {
    margin-top: 0px !important;
    position: absolute;
}

#favourites .tbs--contact--details .tbs--contact--data--favourites {
    margin-left: 70px !important;
}

/**
 * Favourites - End
 */

/* ########################################################################## */

/**
 * Features and trusted - Start
 */

#trusted,
#feature {
    /*margin-top: 30px;*/
    margin-top: 10px;
}

#trusted {
    margin-left: -50px;
    margin-right: -30px;
}

#trusted .tbs--container {
    padding-bottom: 0px !important;
}

#trusted > div > .tbs--container > .row:last-child > div {
    margin-bottom: 20px;
}

#trusted > div > .tbs--container > .row > div > a {
    text-decoration: none;
    color: #000;
}

#feature > div > .tbs--container > .row {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}

#feature > div > .tbs--container > .row:last-child {
    border-bottom: 0px solid #ccc;
    margin-bottom: 15px;
}

/* Custom draggable slider */
/*#feature > div > .tbs--container > .row > div:last-child {
    position: absolute;
    top: 30px;
}*/

#feature > div > .tbs--container > .row,
#trusted > div > .tbs--container > .row {
    padding: 0px 10px;
}

#feature > div > .tbs--container > .row > div:first-child {
    padding: 0px 0px 10px 0px;
    z-index: 1;
}

#feature > div > .tbs--container > .row > div:not(:first-child) {
    padding: 10px 0px 10px 0px;
    z-index: 1;
}

/*#feature > div > .tbs--container > #scroll > div:not(:first-child) {
    border-top: solid 1px #ccc;
    width: 90%;
}*/

#feature > div > .tbs--container > .row > div > hr {
    width: 100%;
}

#scroll > div::-webkit-scrollbar {
    display: none;
}

#scroll > div {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#scroll .feature-container {
    height: 160px;
}

.tbs--container {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 0px 0px 20px 20px;
    overflow: hidden;
    background-color: #fff;
}

#trusted .tbs--container,
#feature .tbs--container{
    border: 0px solid #ccc;
    border-radius: 0;
    padding: 10px 0px 20px 0px;
    overflow: hidden;
    background-color: #fff;
}

.tbs--container--features,
.tbs--container--favourites {
    padding: 0px 0px 20px 0px;
}

.tbs--container--favourites > .row {
    background-color: transparent;
    color: #141C27;
    /*border: 1px solid #ccc;
    border-radius: 25px;*/
    margin: 0px -15px 10px;
}

.tbs--container--favourites > .row .tbs--headline {
    color: #141C27;
    font-size: 24px;
    font-weight: 600;
    background-color: #FAFAFA;
    padding-right: 10px;
    z-index: 1;
    margin: 15px 0px;
}

#no-data-favorites {
    background-color: #fff !important;
    color: #141c27 !important;
    text-align: center;
}

#no-data-feeds-old {
    background-color: #fff !important;
    color: #141c27 !important;
}

.tbs--container--favourites--image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.tbs--container--favourites--item {
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 10px 10px;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 20px;
}

.tbs--container--favourites--item > div {
    margin: 10px 0px;
}

.tbs--container--favourites--item > .row:not(:first-child) > div:first-child {
    padding-right: 15px !important;
}
.tbs--container--favourites--item > .row:not(:first-child) > div:not(:first-child) {
    padding-left: 0px !important;
}

.tbs--contact--data--favourites {
    font-size: 12px;
}

.tbs--contact--data--favourites--activities--count {
    border: 1px solid #141c27;
    border-radius: 50%;
    text-align: center;
    width: 20px;
    height: 20px;
    background-color: #141c27;
    color: #fff;
}

.tbs--contact--data--favourites--title {
    font-size: 24px;
    margin-top: 10px;
}

.tbs--contact--data--favourites--image {
    width: 100%;
    min-height: 200px;
    object-fit: cover;
    /*height: 50%;*/
    margin-top: 20px;
}

.tbs--contact--data--favourites--image > img {
    width: 100%;
    min-height: 250px;
    max-height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.tbs--container > .row:nth-child(2) {
    padding: 0px;
}

.tbs--image--trusted {
    width: 100px;
    height: 100px;
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
    background-color: #141c27;
}

#trusted .tbs--image--trusted--custom {
    width: 100px;
    height: 100px;
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
    background-color: #141c27;
}

.tbs--image--trusted--custom {
    width: 100px;
    height: 100px;
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
    background-color: #fff; /* #141c27 */
}

#scroll {
    margin-right: 2.5px;
    margin-left: 0px;
    position: relative;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}

#scroll > div {
    overflow-x: auto;
    white-space: nowrap;
}

#scroll > div a {
    /*display: inline-block;
    float: none;*/
    padding-right: 20px;
    color: #000;
    text-decoration: none;
}

.tbs--headline {
    color: #fff;
    font-weight: 500;
    margin: 25px 0px 25px 15px;
}

.my--complete--highlights {
    font-size: 22px;
}

.tbs--own--profile {
    display: none !important;
}

.tbs--contact,
.tbs--contact--friends {
    width: 100px;
}

.tbs--contact--friends {
    position: relative;
    padding-right: 25px !important;
}

/*.tbs--contact--friends::before {
    content: "";
    width: 100px;
    height: 10px;
    background-color: #E0F9FF;
    position: absolute;
    top: 25px;
    z-index: -1;
    right: 75px;
}*/

.tbs--contact--friends img {
    border-radius: 50%;
    background-color: #fff;
}

.tbs--contact--image {
    margin-left: 0px;
}

.tbs--contact--main > img {
    border-radius: 10px !important;
}

.tbs--contact--trusted--friend {
    position: relative;
}

.tbs--feedlist-old--list {
    padding-top: 30px;
}

.tbs--contact--trusted,
.tbs--contact--feature {
    font-size: 13px;
    padding-top: 5px;
}

#feature .tbs--contact--feature {
    margin-top: 10px;
}

.tbs--contact--trusted,
.tbs--contact--feature {
    width: 100px;
    text-align: center;
}

.tbs--contact--trusted--friend .tbs--contact--trusted {
    width: 100px;
    text-align: center;
    /*margin-top: -20px;*/
    margin-right: auto !important;
    margin-left: auto !important;
    background-color: #374d6c;
    color: #fff;
    border-radius: 0px 0px 10px 10px;
    padding: 5px;
    min-height: 50px;
    max-height: 100px;
}

.tbs--contact--trusted--position,
.tbs--contact--feature--position {
    color: #ccc;
}

.feature-navi {
    position: relative;
    width: 96.5%;
}

.feature-navi .left,
.feature-navi .right {
    display: inline-block;
    /*position: absolute;*/
    /*top: 0;*/
    color: #fff;
    width: 30px;
    background-color: #C6CDDC;
    padding-left: 9px;
    font-size: 20px;
    border-radius: 50%;
    height: 30px;
    /*margin-top: 30px;*/
}

.feature-navi .right {
    right: 0;
    position: absolute;
}

/**
 * Features and trusted - End
 */

/* ########################################################################## */

/**
 * Feeds - Start
 */

#feeds {
    margin-top: 30px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin: 20px auto !important;
    max-width: 1050px;
}

#feeds a,
#feeds a:hover,
#feeds a:visited,
#feeds a:active,
#feeds a:link,
#feeds a:focus {
    text-decoration: none;
    color: #000;
}

#feeds .tbs--container > .row:first-child {
    background-color: #374d6c;
    margin: 0px 0px 10px -20px;
}

#feeds-trusted-profile > div {
    margin-bottom: 25px;
}

.tbs--headline--feeds {
    margin: 25px 0px 20px 30px;
}

.tbs--image--feeds {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.tbs--image--feeds--custom {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.tbs--contact--feeds {
    font-size: 10px;
}

.tbs--contact--feeds--position {
    color: #ccc;
}

#profile .tbs--contact--data--feeds--infos > span:nth-child(5) {
    position: relative;
    right: 0;
}

.tbs--contact--data--feeds--infos > .tbs--activity--post {
    position: absolute;
    right: 10px;
}

.tbs--contact--data--feeds--infos {
    color: #ccc !important;
    display: flex;
}

#feeds-trusted-profile .tbs--image--profile {
    margin: auto;
    width: 60px;
    position: relative;
    object-fit: cover;
}

.tbs--feeds--counter {
    position: absolute;
    right: -5px;
    background-color: #141c27;
    width: 22px;
    border-radius: 25px;
    border: 2px solid #fff;
    font-size: 12px;
    color: #fff;
}

.tbs--contact--details > div {
    display: inline-block;
}

.tbs--contact--details .tbs--contact--data--post,
.tbs--contact--details .tbs--contact--data--feedlist {
    padding-left: 10px;
}

.tbs--contact--details .tbs--contact--data--post > div:first-child,
.tbs--contact--details .tbs--contact--data--feedlist > div:first-child {
    /*font-weight: bold;*/
    font-size: 16px;
}

.tbs--comment--default--discussion {
    padding-right: 0;
}

.tbs--comment--default--discussion > .tbs--contact--image > img,
.tbs--comment--default--discussion--reply > .tbs--contact--image > img{
    margin-top: -130px;
}

.tbs--comment--default--discussion > div {
    display: inline-block;
}

.tbs--comment--default--discussion > .tbs--discussion--comment--detail--block {
    width: 90%;
    margin-left: 10px;
}

.tbs--comment--default--discussion > .reply--comment {
    margin-left: 50px;
}

.tbs--discussion--comment--reply--awards {
    margin-left: 40px !important;
}

.tbs--comment--default--discussion--reply .tbs--discussion--comment--reply--awards {
    margin-left: 0 !important;
    cursor: pointer;
}

.tbs--comment--default--discussion .tbs--line--form {
    margin-left: 30px;
    width: 100%;
}

.tbs--comment--default--discussion--reply .tbs--line--form {
    margin-left: 25px;
}

.tbs--comment--default--discussion .tbs--line--form > div:first-child > div:first-child > form > div:first-child > div:first-child {
    max-width: 75% !important;
}

.tbs--comment--default--discussion .tbs--line--form .error-text-reply,
.tbs--comment--default--discussion .tbs--line--form .error-text-reply-of-reply {
    margin-left: 15px;
}

#post-feed .container-fluid nav {
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
}

#post-feed .tbs--discussion--text a {
    word-wrap: break-word;
}

#post-feed .tbs--contact--data--feeds--infos svg {
    width: 20px;
    height: 20px;
}

.tbs--no--conversation {
    margin-top: 20px;
}

.tbs--no--conversation > img {
    width: 100px !important;
    height: 100px !important;
    border-radius: 0 !important;
}

/**
 * Feeds - End
 */

/* ########################################################################## */


/**
 * Feed list - Start
 */

#feed-list {
    margin: 20px auto !important;
    max-width: 1050px;
}

#feed-list [src=''] {
    visibility: hidden;
}

#feed-list a,
#feed-list a:hover,
#feed-list a:visited,
#feed-list a:active,
#feed-list a:link,
#feed-list a:focus {
    text-decoration: none;
    color: #000;
}

#feed-list .tbs--container--feedlist {
    padding: 0px 0px 15px 0px;
}

.tbs--container--feedlist {
    padding: 0px 0px 20px 0px;
}

#favourites .tbs--container--feedlist > .row:first-child {
    background-color: transparent;
    color: #141c27;
    border: none;
    border-radius: 0px;
    margin: 0px -15px 10px;
}

.tbs--container--feedlist > .row:first-child {
    background-color: #141c27;
    color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 0px -15px 10px;
}

.new-feedlist .tbs--container--feedlist > .row:first-child {
    color: #141c27;
    background-color: transparent;
    margin: 0px -15px 10px;
    border: none;
    border-radius: 0px;
}

#profile #tbs--create--new--post .tbs--container--feedlist > .row:first-child {
    color: #141c27;
    background-color: transparent;
    margin: 0px -15px 10px;
    border: none;
    border-radius: 0px;
}

#settings > div:first-child > .tbs--container--feedlist > .row:first-child {
    color: #141c27;
    background-color: transparent;
    margin: 0px -15px 10px;
    border: none;
    border-radius: 0px;
}

#profile #tbs--create--new--post .tbs--container--feedlist--item {
    margin-bottom: -25px !important;
    margin-top: 20px !important;
}

#profile #profile--submenu .tbs--container--feedlist > .row,
#network--submenu .tbs--container--feedlist > .row {
    background-color: transparent;
    color: #141c27;
    border: none;
    border-radius: 0px;
}

#network--submenu .tbs--container--feedlist > .row {
    margin: 0px 0px 20px 0px;
}

#network--submenu .tbs--container--feedlist > .row .tbs--divider--title {
    right: 0px !important;
    z-index: 0;
}

#profile #profile--submenu .tbs--container--feedlist,
#network--submenu .tbs--container--feedlist {
    padding: 0 !important;
}

#profile #profile--submenu .tbs--container--feedlist .tbs--headline,
#network--submenu .tbs--container--feedlist .tbs--headline {
    background-color: #FBFBFE;
    color: #141c27;
    border: none;
    border-radius: 0px;
    z-index: 1000;
    margin: 15px 10px 0px 0px !important;
    padding-right: 10px;
}

#profile #profile--submenu .tbs--container--feedlist .tbs--headline > span:first-child
/*#network--submenu .tbs--container--feedlist .tbs--headline > span:first-child*/ {
    padding-right: 15px;
    font-weight: 600;
    font-size: 24px;
}

#network--submenu .tbs--container--feedlist .tbs--headline > span {
    padding-right: 15px;
}

#profile #profile--submenu .tbs--container--feedlist .tbs--headline > a,
#network--submenu .tbs--container--feedlist .tbs--headline > a {
    text-decoration: none;
    color: #141c27;
    font-size: 18px;
}

.network--submenu--active {
    font-weight: 600;
    font-size: 24px;
}

.network--submenu--inactive {
    font-weight: 500;
    font-size: 18px;
}

.tbs--additional--menu {
    top: 22px !important;
}

.tbs--additional--menu--chats {
    top: 10px !important;
    right: 0 !important;
}

.tbs--new--title {
    position: relative;
}

.tbs--create--post--container {
    padding: 0px !important;
    display: block;
}

.tbs--create--post--container img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}

.tbs--create--post--container .tbs--container--feedlist--item > div:last-child {
    margin: 10px 15px !important;
}

#tbs--create--new--post > div > div > div > div:nth-child(2) > form {
    /*position: absolute;
    bottom: 28px;
    left: 85px;
    width: 95%;*/
    display: contents;
}

.tbs--create--post--container .tbs--container--feedlist--item input {
    padding: 10px !important;
    margin-left: 15px;
    max-width: 83.5%;
    width: 100%;
    border: 1px solid #ccc !important;
    border-radius: 10px 0px 0px 10px !important;
}

.create--new--post--button {
    margin-top: -4px;
    border-radius: 0px 10px 10px 0px !important;
}

.tbs--divider--title {
    position: absolute;
    width: 100%;
    right: -10px !important;
    top: 4px;
    z-index: 0;
}

.tbs--divider--title--group {
    position: absolute;
    width: 100% !important;
    left: -10px !important;
    top: 5px !important;
    z-index: 0;
}

.tbs--setting--submenu--divider {
    position: absolute;
    width: 90% !important;
    right: 0px !important;
    z-index: 0;
}

.tbs--bug-report--submenu--divider,
.tbs--feedback--submenu--divider {
    position: absolute;
    width: 82% !important;
    right: 20px !important;
    top: 6px;
    z-index: 0;
}

#report-user .tbs--report--submenu--divider,
#report-post .tbs--report--submenu--divider,
#report-group-conversation .tbs--report--submenu--divider,
#report-chat-conversation .tbs--report--submenu--divider {
    position: absolute;
    width: 90% !important;
    right: 20px !important;
    top: 6px;
    z-index: 0;
}

.tbs--divider--title--favourites {
    top: 20px;
    z-index: 0;
}

.tbs--divider--title--invitation {
    top: 20px;
    width: 90%;
    margin-right: 0px;
    z-index: 0;
}

.tbs--divider--title--notification {
    top: 20px;
    width: 90%;
    margin-right: 30px;
    z-index: 0;
}

.tbs--divider--title hr {
    /*width: 100%;*/
    border-right : 0;
    border-left: 0;
    margin-top: 0.75rem !important;
    border-color: #141c27;
    z-index: 0;
}

.new-feedlist .tbs--headline {
    color: #141c27;
    background-color: #FBFBFE;
    font-weight: 600;
    margin: 0px !important;
    padding-right: 10px !important;
    z-index: 1000;
    font-size: 24px;
}

.tbs--title--new--post {
    font-size: 18px;
    font-weight: 600;
    padding: 0px 15px 15px 15px !important;
}

.tbs--title--new--post--container {
    padding: 10px 25px !important;
}

.tbs--container--feedlist > .row {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 10px -15px;
}

.tbs--container--feedlist--image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 35px;
    /*margin-bottom: 20px;*/
}

.tbs--container--feedlist--item {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px 10px;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 20px;
}

.tbs--container--feedlist--item > div{
    margin: 10px 0px;
}

.tbs--feedlist--item--no--repost {
    margin: 26px 0px 10px 0px !important;
}

.tbs--container--feedlist--item > div:last-child{
    margin: 30px 0px 10px 0px;
}

.tbs--container--feedlist--item > .row:not(:first-child) > div:first-child {
    padding-right: 15px !important;
}

.tbs--container--feedlist--item--custom {
    margin-top: -30px;
}

.tbs--container--feedlist--item > .row:not(:first-child) > div:not(:first-child) {
    padding-left: 0px !important;
}

.tbs--contact--data--feedlist {
    font-size: 12px;
    /*padding-right: 20px;*/
}

.tbs--container--feedlist > a > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > .tbs--contact--data--feedlist,
.tbs--container--feedlist > a > div > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > .read-more,
#own--feeds > div:nth-child(2) > div:nth-child(2) > .tbs--container--feedlist--item > div:nth-child(3) > div > a > div:first-child > div > .tbs--contact--data--feedlist,
#own--feeds > div:nth-child(2) > div:nth-child(2) > .tbs--container--feedlist--item > div:nth-child(3) > div > a > div:first-child > div > .read-more,
#other--profile--feeds > div > a:nth-child(1) > div > div > div:nth-child(3) > div:first-child > .tbs--contact--data--feedlist,
#other--profile--feeds > div > a:nth-child(1) > div > div > div:nth-child(3) > div:first-child > .read-more {
    padding-right: 25px;
}

.tbs-my-posts > div:last-child .tbs--contact--data--feedlist,
.tbs-friend-posts > div:last-child .tbs--contact--data--feedlist {
    text-align: right;
}

.tbs--contact--data--feedlist--favourite {
    text-align: right;
    width: 20px;
    height: 20px;
}

.tbs--contact--data--feedlist--title {
    font-size: 24px;
}

.tbs--contact--data--feedlist--description {
    font-size: 16px;
    margin-top: 20px;
}

.tbs--contact--data--feedlist--image {
    width: 100%;
    height: 50%;
    object-fit: cover;
}

.tbs--contact--data--feedlist--image > img {
    width: 100%;
    height: calc(35vh) !important;
    border-radius: 10px;
    object-fit: cover;
}

.tbs--contact--data--feedlist--without--image {
    width: 100%;
    height: 200px !important;
}

.tbs--contact--data--feedlist--activities--count {
    border: 1px solid #141c27;
    border-radius: 50%;
    text-align: center;
    width: 20px;
    height: 20px;
    background-color: #141c27;
    color: #fff;
}

.tbs--audio--feed {
    position: absolute;
    top: 35%;
    left: 47.5%;
    width: 50px;
    height: 50px;
    transform: translate(-25px, 25px);
    display: block;
}

.tbs--audio--feed img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tbs--contact--audio--overview {
    position: absolute;
    display: none;
}

/**
 * Feed list - End
 */

/* ########################################################################## */


/**
 * Post feed - Start
 */

#post-feed {
    margin: 20px auto !important;
    max-width: 1050px !important;
}

#post-feed [src=''] {
    visibility: hidden;
}

#post-feed .tbs--line--form {
    margin-right: 0;
}

#post-feed .tbs--line--form .row > div:nth-child(2){
    padding: 0;
}

#post-feed .tbs--line--form .row > div:nth-child(2) button {
    width: 100%;
    font-size: 15px;
}

#post-feed input[type="text"] {
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 10px 0px 0px 10px !important;
    /*margin-left: 10px;*/
}

#post-feed .new-comment {
    margin-left: 10px;
}

#post-feed .button--sign {
    margin-top: 0px !important;
    margin-left: -8px !important;
}

#new-comment-postfeed .button--sign {
    margin-top: -8px !important;
    margin-left: -8px !important;
}

#post-feed #new-comment-postfeed .button--sign {
    margin-top: 0px !important;
    margin-left: -8px !important;
    z-index: 10000;
}

#postButtonComment {
    margin: 0 !important;
}

#post-feed > div:nth-child(3) {
    padding-left: 15px;
}

#post-feed .reply--comment {
    display: flex;
}

#post-feed button {
    font-size: 18px;
    padding: 12.5px 20px !important;
    background-color: #374d6c;
    border-color: #374d6c;
    border-radius: 0px 10px 10px 0px;
}

#post-feed button img {
    /*width: 40px;
    height: 40px;*/
    top: 0;
    right: -10px;
    height: 100%;
}

/* , .button--sign > button:hover, .button--sign > button:focus, .button--sign > button:active, .button--sign > button:link */

#post-feed .button--sign > button{
    /*background-color: transparent;
    border: none;*/
    width: 100%;
    height: 100%;
}

#new-comment-postfeed {
    display: flex;
}

#new-comment-postfeed img {
    position: absolute;
}

/*#post-feed #new-comment-postfeed img {
    position: relative !important;
}*/

#new-comment-postfeed input,
#new-comment-postfeed textarea {
    flex: 0 0 95%;
    padding: 2px 10px;
    border: 1px solid #ccc !important;
}

#post-feed #new-comment-postfeed textarea {
    flex: 0 0 87% !important;
    padding: 2px 10px;
    border: 1px solid #ccc !important;
}

#post-feed #new-comment-postfeed textarea::placeholder {
    color: #141c27;
}

#post-feed #new-comment-postfeed textarea:-ms-input-placeholder {
    color: #141c27;
}

#post-feed #new-comment-postfeed textarea::-ms-input-placeholder {
    color: #141c27;
}

#new-comment-postfeed > div {
    flex: 0 0 15%;
}

.form--comments form > .row {
    margin-left: -15px;
    margin-right: 0;
}

.form--comments form > .row > div {
    padding: 0px;
}

.form--comments form > .row > div:last-child {
    padding-left: 0px;
}

.form--comments form button {
    width: 100%;
}

.form--comments form > .row input[type="text"] {
    width: 100%;
}

.tbs--bookmark {
    padding-left: 0px !important;
}

.tbs--container--post {
    padding: 0px 0px 20px 0px;
}

.tbs--container--post--image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 35px;
}

.tbs--container--post--item {
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 10px 10px;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 20px;
}

.tbs--container--post--item > div{
    margin: 10px 0px;
}

.tbs--container--post--item > .row:not(:first-child) > div:first-child {
    padding-right: 15px !important;
}
.tbs--container--post--item > .row:not(:first-child) > div:not(:first-child) {
    padding-left: 0px !important;
}

.tbs--contact--data--post {
    font-size: 12px;
}

#profile .tbs--contact--data--post--favourite,
div[id*="profile-"] .tbs--contact--data--post--favourite,
#group-publication .tbs--contact--data--post--favourite,
#chat-publication .tbs--contact--data--post--favourite {
    text-align: right;
    width: 25px;
    height: 25px;
}

#post-feed .tbs--contact--data--post--favourite {
    text-align: left;
    margin-left: 10px;
}

#post-feed .tbs--contact--data--post--favourite img {
    width: 125px;
    margin-right: 17px;
}

#post-feed .report-post-icon img {
    width: 125px;
}

#post-feed .report-post-container {
    margin-left: 18px;
}

#post-feed .report-post-container a {
    text-decoration: none;
    color: #141C27;
}

#post-feed .report-post-container img {
    width: 125px;
    margin-right: 17px;
}

.tbs--contact--data--post--favourite svg,
.tbs--contact--data--feedlist svg,
.tbs--contact--data--favourites svg {
    width: 25px;
    height: 25px;
}

#post-feed .edit-post-container {
    margin-left: 18px;
    margin-top: 10px;
    cursor: pointer;
}

#post-feed .edit-post-container a {
    text-decoration: none;
    color: #141C27;
}

#post-feed .delete-post-container {
    margin-left: 18px;
    margin-top: 10px;
    cursor: pointer;
}

#post-feed .delete-post-container a {
    text-decoration: none;
    color: #141C27;
}

.tbs--data--feeds--subline svg {
    width: 20px;
    height: 20px;
}

.tbs--contact--data--post--title {
    font-size: 24px;
}

.tbs--contact--data--post--description {
    font-size: 16px;
    margin-top: 20px;
    white-space: pre-line;
}

.tbs--contact--data--post--description-detail {
    margin-bottom: 25px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
}

.tbs--contact--data--post--image {
    width: 100%;
    height: 50%;
    margin-top: 20px;
    object-fit: cover;
    position: relative;
}

.tbs--contact--data--post--image .tbs--contact--data--post {
    position: absolute;
    top: 0px;
    left: 0px;
    /*background-color: #141c27;*/
    background: rgba(55, 77, 108, 1.0);
    color: #fff;
    padding: 10px;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
}

.tbs--contact--data--post--image .tbs--contact--data--post.no-image-available {
    position: relative !important;
}

.tbs--contact--data--post--image > img {
    width: 100%;
    max-height: calc(50vh);
    border-radius: 10px;
    object-fit: cover;
}

.tbs--contact--data--feeds--dca nav {
    box-shadow: none;
    padding-bottom: 0;
    padding-top: 0;
    background-color: transparent;
}

.tbs--contact--discussion--profiles > .row:first-child > div:nth-child(2),
.tbs--contact--discussion--reply--profiles > .row:first-child > div:nth-child(2) {
    padding-right: 0 !important;
    padding-left: 20px !important;
}

.tbs--contact--discussion--reply--profiles {
    margin-left: 0px;
}

.tbs--contact--discussion--default {
    font-size: 16px;
}

.nav-tabs {
    border: none !important;
}

.nav-tabs .nav-link {
    border: none;
    border-radius: 0;
}

.nav-link {
    font-size: 16px;
    /*font-weight: bold;
    color: #ccc !important;*/
}

.nav-link.active {
    font-size: 16px;
    font-weight: bold;
    color: #141c27 !important;
}

.nav-tabs a {
    text-decoration: none;
    color: #000;
}

#invitation-overlay nav{
    padding: 10px 0px !important;
}

#invitation-overlay .send--invitation--new {
    background-color: #374d6c;
    color: #fff;
    border-radius: 10px;
}

#invitation-overlay .send--invitation--new > a {
    color: #fff;
}

#invitation-overlay nav a {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
}

#invitation-overlay .nav-tabs {
    padding: 0px 10px;
    position: relative;
    display: -webkit-box;
    overflow: hidden;
}

#invitation-overlay .nav-tabs .nav-link {
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}

#invitation-overlay .nav-tabs .nav-link.active {
    border-bottom: 1px solid #141c27;
    border-radius: 0;
}

#invitation-overlay nav {
    box-shadow: none;
    padding-top: 10px !important;
    background-color: #fff !important;
}

.tbs--contact--data--feeds--dca .tbs--contact--awards a {
    text-decoration: none;
    color: #000;
}

.tbs--contact--data--feeds--groups nav a {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.tbs--contact--discussion--user > div,
.tbs--contact--contributors--user > div,
.tbs--contact--awards--user > div {
    margin: 10px 0px;
}

.tbs--contact--discussion--user img,
.tbs--contact--contributors--user img,
.tbs--contact--awards--user img {
    border-radius: 50%;
    border: none;
    background-color: #fff;
}

.tbs--contact--discussion--user input[type="text"] {
    width: 100% !important;
}

.tbs--discussion--comment--detail--block {
    border: 1px solid #F7F7F7;
    background-color: #FBFBFB;
    color: #000;
    border-radius: 15px;
    padding: 10px;
}

.tbs--discussion--comment--reply--awards {
    margin: 10px 0px;
    cursor: pointer;
}

.tbs--discussion--comment--reply--awards > div {
    float: left;
}

.tbs--discussion--comment--reply--awards > div:last-child:after {
    clear: both;
}

.tbs--discussion--comment--reply--awards span {
    margin-right: 10px;
    line-height: 2;
}

.tbs--discussion--comment--reply--awards--icon {
    margin-left: 10px !important;
}

.tbs--discussion--comment--reply--awards img {
    width: 18px !important;
    height: 18px !important;
    margin-right: 10px;
    border-radius: 0 !important;
}

.tbs--discussion--comment--reply--awards svg {
    color: #141c27;
    width: 15px !important;
    height: 15px !important;
    margin-bottom: -2.5px;
    transform: scaleX(-1);
}

.tbs--contact--discussion--user img {
    width: 40px;
    height: 40px;
}

.tbs--contact--discussion--profiles > .row:first-child {
    margin-right: 0;
}

.tbs--contact--discussion--reply {
    margin: 20px 0px 20px 50px;
}

.tbs--contact--discussion--reply--profiles > div > div:first-child {
    padding: 0;
}

.tbs--discussion--user {
    font-weight: bold;
    margin-bottom: 10px;
}

.tbs--discussion--comment--date {
    font-weight: normal;
    color: #141c27;
    margin: 10px 0px;
}

.tbs--discussion--comment--date,
.tbs--discussion--text {
    font-size: 14px;
}

.tbs--favourite--image {
    width: 25px !important;
    height: 25px !important;
}

#chat-group-select-contact > .row:first-child {
    display: none;
}

.is-show {
    display: block !important;
}

#chat-group-select-contact img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 25px;
}

#chat-group-select-contact img.tbs--image--chats--introduce {
    background-color: #ccc !important;
}

#chat-group-select-contact .button--sign {
    margin-top: 20px;
}

#chat-group-select-contact button {
    width: 100%;
}

#chat-group-alert {
    width: 80%;
    margin: 10px auto;
}

#chat-group-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

.tbs--introduce--image img {
    background-color: #ccc;
}

#chat-group-select-contact input[type="text"],
#chat-group-select-contact input[type="search"] {
    width: 100% !important;
    border: 2px solid #ccc !important;
    margin: 10px 0px 0px 0px !important;
    padding: 10px !important;
}

.post-awards img,
.reply-awards img {
    width: 20px;
    object-fit: scale-down;
    margin: -10px 0px;
    padding-right: 3px;
    background-color: #FBFBFB;
}

.post-awards-image-counter {
    padding-left: 0 !important;
    color: #141c27;
    font-size: 14px;
}

.post-awards-image-counter-reply {
    padding-left: 0 !important;
    color: #141c27;
}

.post-awards > div:first-child {
    font-size: 14px;
}

.tbs--awards--sizer img {
    object-fit: scale-down;
}

#awards-alert {
    width: 80%;
    margin: 10px auto;
}

#awards-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

/* Audio */

.tbs--contact--audio {
    margin: 20px 0px;
}

.tbs--contact--audio > audio {
    width: 100%;
}

.tbs--contact--audio > audio,
#default-audio-player > audio,
#audio-recording-update-post audio{
    background-color: #f1f3f4;
    border-radius: 10px;
}

.back-button-newsfeed {
    margin-bottom: 10px;
    margin-left: 10px;
}

.back-button-newsfeed a {
    text-decoration: none;
    color: #141c27 !important;
    /*display: table;*/
}

.back-button-newsfeed img {
    width: 125px;
    /*height: 20px;*/
    margin-right: 17px;
}

.back-button-newsfeed a div {
    width: 25px;
    height: 25px;
    display: table-cell;
}

.back-button-newsfeed span {
    padding-left: 10px;
    font-size: 16px;
    display: table-cell;
    position: absolute;
    left: 35px;
    top: -3px;
}

.read-more {
    font-size: 14px;
    margin-bottom: 10px;
}

.read-more a {
    color: #007bff !important;
}

/*.layout-desktop {
    display: flex;
    flex-wrap: wrap;
}

.tbs--container--feedlist > a:nth-child(even) > .layout-desktop {
    margin-right: 10.5px;
}

.tbs--container--feedlist > a:nth-child(odd) > .layout-desktop {
    margin-left: 10.5px;
}

.tbs--container--feedlist > a {
    width: 50%;
}*/

.layout-desktop {
    background-color: transparent !important;
    border: none !important;
}

/*.tbs--container--feedlist > a > .row > div {
    min-height: calc(75vh);
}*/

#tbs--post--submenu .tbs--contact--data--post--favourite {
    cursor: pointer;
}

/**
* Post feed - End
*/

/* ########################################################################## */

/**
 * Reposted feed - Start
 */

.tbs--reposted {
    font-size: 12px;
}

.reposted-post-icon {
    margin-right: 10px;
}

.reposted-post-icon svg {
    width: 20px;
    height: 20px;
}

#reposted-post-alert {
    margin: 10px auto;
    width: 80%;
}

#reposted-post-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

/**
 * Reposted feed - End
 */

/* ########################################################################## */

/**
 * Create post - Start
 */

#new-post {
    margin: 20px auto !important;
    max-width: 1050px;
}

#new-post input[type="text"]{
    width: 99.75%;
}

#new-post .tbs--container > .row:nth-child(2) {
    margin: 0 !important;
}

#new-post .tbs--headline--create-post {
    margin: 25px 0px 20px 0px !important;
}

#new-post .tbs--form--complete--post {
    margin-left: -15px;
}

#new-post .tbs--form--complete--post label {
    color: #141c27;
}

#new-post .tbs--form--complete--post .button--sign {
    margin-top: 20px !important;
}

#new-post .tbs--form--complete--post .button--sign:last-child {
    margin-top: 40px !important;
}

#new-post button {
    width: 100%;
}

.new-post-top-layout {
    margin-top: -5px;
}

.new-post-top-layout-added {
    margin-top: -10px;
}

.new-post-top-layout img {
    object-fit: cover !important;
    width: 100%;
}

.audio-message-record,
.audio-message-record > label,
.upload-new-image {
    cursor: pointer;
}

.upload-new-image {
    border-radius: 10px;
    border: 2px solid #ccc;
    color: #374D6C;
    padding: 10px 10px 10px 15px !important;
    text-align: left;
}

.create-new-community .upload-new-image {
    width: 97%;
}

#new-post .tbs--default--image,
#new-post .tbs--user--profile--image {
    width: 100%;
    cursor: pointer;
}

.tbs--upload--image--new--post {
    width: 100%;
    margin-top: 20px;
    object-fit: cover;
    position: relative;
}

.tbs--upload--image--new--post img {
    border-radius: 10px;
}

#new-post > div:nth-child(2) > .tbs--container > .row:first-child {
    background-color: #374d6c;
    margin-left: -20px;
    padding-left: 5px;
}

#new-post textarea {
    resize: none;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    /*max-height: 100px;*/
}

#new-post #audio {
    cursor: pointer;
}

.tbs--contact--contributors--profiles > div:last-child {
    margin: auto;
}

/**
 * Create post - End
 */

/* ########################################################################## */

/**
 * Edit profile - Start
 */

#my-profile {
    margin: 20px auto !important;
    max-width: 1050px;
}

#user-update-alert {
    width: 80%;
    margin: 10px auto;
}

#user-update-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

#my-profile input[type="text"],
#my-profile input[type="email"] {
    width: 97%;
    margin: 10px 0px;
}

#my-profile .form-update-user label {
    color: #141c27;
}

#my-profile form .row > div:first-child div:not(:first-child) label,
#my-profile form .row > div:last-child div:not(:first-child) label {
    margin-top: 20px;
}

#my-profile .form-update-user input[name="country"],
#my-profile .form-update-user input[name="company"],
#my-profile .form-update-user input[name="education"],
#my-profile .form-update-user input[name="school"],
#my-profile .form-update-user input[name="socialLinks"] {
    margin-top: 10px;
}

#my-profile .form-update-user .button--sign {
    margin-top: 20px !important;
    margin-right: 10px;
}

#my-profile .tbs--container > .row:first-child {
    border-bottom: 1px solid #ccc;
    margin-right: 0 !important;
    margin-left: -10px;
    background-color: #374d6c;
    padding-left: 15px;
}

#edit-profile-image {
    position: relative;
    margin-bottom: 10px;
}

#edit-profile-image .tbs--user--profile--image,
#edit-profile-image .tbs--user--profile--default--image {
    height: calc(50vh);
}

#edit-profile-image .tbs--user--profile--default--image {
    object-fit: scale-down !important;
    width: 98.75%;
}

#edit-profile-image label {
    position: absolute;
    bottom: 0px;
    right: 15px;
}

#my-profile .tbs--container > .row:nth-child(2) {
    margin-top: 0px;
}

#my-profile .tbs--container > .row:nth-child(2) > div > div.form-update-user > div:nth-child(2) > form > div:first-child > div:first-child {
    padding: 0px;
}

#my-profile .tbs--container > .row:first-child > .tbs--headline {
    margin-left: 0px;
    font-size: 24px;
}

#tip-edit-profile {
    padding: 0px 15px !important;
}

#my-profile > div:nth-child(2) > .container,
#my-profile > div:nth-child(2) > .container-fluid {
    padding-left: 10px;
}

#my-profile button {
    width: 100%;
}

/**
 * Edit profile - End
 */

/* ########################################################################## */

/**
 * Edit my account - Start
 */

#my-account {
    margin: 20px auto !important;
    max-width: 1050px;
}

#my-account .tbs--container > .row:first-child {
    border-bottom: 1px solid #ccc;
    margin-right: 0 !important;
    margin-left: -20px;
    background-color: #374d6c;
    padding-left: 20px;
}

#my-account .tbs--container > .row:nth-child(4) {
    border-top: 1px solid #ccc;
    margin-right: 15px !important;
    margin-left: 0px;
    padding-top: 15px;
    padding-left: -15px;
}

#my-account .tbs--container > .row:nth-child(4) > div {
    padding-left: 0px !important;
}

#my-account .tbs--container > .row:nth-child(4) > div:first-child {
    padding: 10px 0px !important;
}

#my-account .tbs--step--de-activate,
#my-account .tbs--step--delete {
    margin: 20px 0px;
    min-height: 85px;
}

#my-account .tbs--headline--update-profile {
    margin: 25px 0px 25px 0px;
    font-size: 24px;
}

#my-account .tbs--container > .row:nth-child(2) {
    margin-top: 20px;
}

#my-account .tbs--form--complete--profile {
    margin-left: 0px;
}

#my-account .tbs--form--complete--profile label {
    color: #141c27;
}

#my-account .tbs--form--complete--profile .button--sign {
    margin-top: 20px !important;
}

#my-account .tbs--other--email {
    position: relative;
}

#my-account .tbs--other--email span {
    position: absolute;
    right: 20px;
    top: 10px;
    color: #141c27;
}

#user-account-alert {
    width: 80%;
    margin: 10px auto 0px;
}

#user-account-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

#my-account input[type="text"],
#my-account input[type="email"],
#my-account input[type="password"] {
    width: 97.75%;
}

#other-email-form > .row > div {
    padding-right: 30px;
}

#otherEmail {
    width: 100% !important;
}

.update-status,
.delete {
    padding: 10px 0px 10px 10px !important;
}

#my-account .form-update-account label {
    color: #141c27;
}

#my-account .form-update-account .button--sign {
    margin-top: 20px !important;
}

#account-de-activate-alert,
#account-delete-alert {
    margin: 10px auto;
    width: 80%;
}

#account-de-activate-alert > div,
#account-delete-alert > div{
    font-size: 16px;
    margin-top: 5px;
}

.form-update-account form input[type="password"] {
    width: 97%;
}

.form-update-account .button--sign {
    margin-top: 20px !important;
}

.tbs--account--status,
.tbs--account--status--delete {
    padding-left: 60px;
}

.tbs--de-activate--slider,
.tbs--delete--slider {
    margin: auto;
    width: 150px;
}

.tbs--other--emails:not(:last-child) {
    border-bottom: 1px solid #ccc;
    margin: 5px 0px !important;
    padding-bottom: 15px;
}

.tbs--other--emails--main {
    width: 95%;
    margin: auto;
    padding: 8px 16px !important;
}

#my-account > div:nth-child(1) > div > div:nth-child(2),
#my-account > div:nth-child(1) > div > div:nth-child(3) {
    padding-left: 0px;
}

/* Toggle input slider */

.switch {
    position: relative;
    display: block;
    width: 50px;
    height: 26px;
    color: #000 !important;
}

.switch input {
    display: none;
    width: 0;
    height: 0;
}

.slider,
.slider-two {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before,
.slider-two:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #21F396;
}

input:checked + .slider-two {
    background-color: #21F396;
}

input:focus + .slider {
    box-shadow: 0 0 1px #F31030;
}

input:focus + .slider-two {
    box-shadow: 0 0 1px #F31030;
}

input:checked + .slider:before,
input:checked + .slider-two:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
}

.slider.round,
.slider-two.round {
    border-radius: 26px;
}

.slider.round:before,
.slider-two.round:before {
    border-radius: 50%;
}

/**
 * Edit my account - End
 */

/* ########################################################################## */

/**
 * Report User - Start
 */

#report-user {
    margin: 20px auto !important;
    max-width: 1050px;
}

#report-user .tbs--headline--bug {
    font-size: 24px;
}

#report-user .tbs--container > .row:first-child {
    background-color: #fff;
    margin-left: -20px;
    padding-left: 5px;
    padding-right: 5px;
}

#report-user .tbs--headline--report {
    margin: 25px 0px 20px 15px !important;
}

#report-user .tbs--report--message {
    margin-top: 20px;
    margin-bottom: 10px;
}

#report-user .button--sign {
    margin-top: 20px;
}

#report-user form textarea {
    border-radius: 10px;
    border: 2px solid #ccc;
    width: 98.75%;
    padding: 10px 0px 10px 10px;
    box-sizing: border-box;
}

#report-user .tbs--form--main--error {
    margin-bottom: 10px;
}

/**
 * Report User - End
 */

/* ########################################################################## */

/**
 * Report Post - Start
 */

#report-post {
    margin: 20px auto !important;
    max-width: 1050px;
}

#report-post .tbs--container > .row:first-child {
    background-color: #fff;
    margin-left: -20px;
    padding-left: 5px;
    padding-right: 5px;
}

#report-post .tbs--headline--report {
    margin: 25px 0px 20px 15px !important;
}

#report-post .tbs--report--message {
    margin-top: 20px;
    margin-bottom: 10px;
}

#report-post .button--sign {
    margin-top: 20px;
}

#report-post form textarea {
    border-radius: 10px;
    border: 2px solid #ccc;
    width: 98.75%;
    padding: 10px 0px 10px 10px;
    box-sizing: border-box;
}

#report-post .tbs--form--main--error {
    margin-bottom: 10px;
}

#report-post-alert {
    width: 80%;
    margin: 10px auto;
}

#report-post-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

/**
 * Report Post - End
 */

/* ########################################################################## */

/**
 * Report Chat & Group - Start
 */

#report-chat-conversation,
#report-group-conversation {
    margin-top: 30px;
}

#report-chat-conversation > div > .container-fluid,
#report-group-conversation > div > .container-fluid {
    padding: 0px;
}

#report-chat-conversation > div:nth-child(2) > .container-fluid,
#report-group-conversation > div:nth-child(2) > .container-fluid {
    padding: 20px 15px 20px 20px;
}

#report-chat-conversation > div > .container-fluid > .row:first-child,
#report-group-conversation > div > .container-fluid > .row:first-child {
    background-color: transparent;
    padding-left: 5px;
    margin-left: -20px;
    margin-bottom: 10px;
}

#report-chat-conversation .tbs--headline--report,
#report-group-conversation .tbs--headline--report {
    margin: 0px 0px 20px 15px !important;
    color: #141c27;
    font-size: 24px;
    font-weight: 600;
    z-index: 1;
    padding-right: 10px;
    padding-left: 5px;
    background-color: #FAFAFA;
}

#report-chat-conversation .tbs--report--message,
#report-group-conversation .tbs--report--message {
    margin-bottom: 10px;
}

#report-chat-conversation .button--sign,
#report-group-conversation .button--sign {
    margin-top: 20px;
}

#report-chat-conversation form textarea,
#report-group-conversation form textarea {
    border-radius: 10px;
    border: 2px solid #ccc;
    width: 98.75%;
    padding: 10px 0px 10px 10px;
    box-sizing: border-box;
}

#report-chat-conversation .tbs--form--main--error,
#report-group-conversation .tbs--form--main--error {
    margin-bottom: 10px;
}

#report-chat-alert,
#report-group-alert {
    width: 80%;
    margin: 10px auto;
}

#report-chat-alert > div,
#report-group-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

/**
 * Report Chat & Group - End
 */

/* ########################################################################## */


/**
 * Setting - Start
 */

#settings {
    margin: 20px auto !important;
    max-width: 1050px;
}

#settings .tbs--new--title {
    padding: 0;
}

#settings a {
    text-decoration: none;
    color: #141c27;
}

.tbs--settings--block {
    padding-right: 10px;
}

#settings .tbs--headline--settings {
    margin: 0px;
    color: #141c27;
    font-size: 24px;
    font-weight: 600;
    z-index: 1;
    background-color: #FBFBFE;
    padding-right: 15px;
}

#settings .container > .row:first-child,
#settings .container-fluid > .row:first-child {
    border-bottom: 1px solid #ccc;
    margin: 0px 0px 0px -20px;
    padding-left: 20px;
    /*background-color: #141c27;*/
}

/*#settings .container > .row:not(:first-child),
#settings .container-fluid > .row:not(:first-child) {
    border-bottom: 1px solid #ccc;
    margin: 20px 15px 0px 0px;
    padding-bottom: 10px;
    height: 125px;
}*/

#settings > div:nth-child(2) > .container > .row,
#settings > div:nth-child(2) > .container-fluid > .row {
    border-bottom: 1px solid #ccc;
    margin: 20px 15px 0px 0px;
    padding-bottom: 10px;
    height: 125px;
}

#settings > div:nth-child(2) > .container > .row:first-child > div:first-child,
#settings > div:nth-child(2) > .container-fluid > .row:first-child > div:first-child,
#settings > div:nth-child(2) > .container > .row:first-child > div:nth-child(2),
#settings > div:nth-child(2) > .container-fluid > .row:first-child > div:nth-child(2) {
    margin-left: -35px;
}

#settings > div:nth-child(2) > .container > .row:nth-child(2) > div:first-child,
#settings > div:nth-child(2) > .container-fluid > .row:nth-child(2) > div:first-child {
    margin-bottom: -35px;
}

#settings > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(2) {
    margin-top: 15px;
}

#settings > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(2),
#settings > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(3) {
    flex: 0 0 100%;
    max-width: 100%;
    /*text-align: left !important;*/
}

#settings > div:nth-child(2) > .container > .row:last-child,
#settings > div:nth-child(2) > .container-fluid > .row:last-child {
    border-bottom: none;
    margin: 20px 15px 0px 0px;
    padding-bottom: 10px;
}

#settings .container > .row:not(:first-child) > div,
#settings .container-fluid > .row:not(:first-child) > div {
    padding-left: 0px;
}

#settings .container .row:nth-child(4) img,
#settings .container-fluid .row:nth-child(4) img,
#settings .container .row:nth-child(5) img,
#settings .container-fluid .row:nth-child(5) img {
    width: 25px;
}

#settings .container .row:nth-child(4) > div span,
#settings .container-fluid .row:nth-child(4) > div span,
#settings .container .row:nth-child(5) > div span,
#settings .container-fluid .row:nth-child(5) > div span {
    margin-left: 10px;
}

#settings .headline-custom {
    padding-bottom: 20px;
}

#settings .tbs--container {
    padding-bottom: 10px !important;
}

#tips-alert {
    width: 80%;
    margin: 10px auto;
}

#tips-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

/**
 * Setting - End
 */

/* ########################################################################## */

/**
 * Feedback & Bug Report - Start
 */

#feedback,
#bug-report {
    margin: 20px auto !important;
    max-width: 1050px;
}

#feedback .tbs--headline--feedback,
#bug-report .tbs--headline--bug,
#report-user .tbs--headline--bug,
#report-post .tbs--headline--report {
    margin: 0px 0px 20px 0px !important;
    color: #141c27;
    font-weight: 600;
    z-index: 1;
    background-color: #FBFBFE;
    padding-right: 15px;
    font-size: 24px;
}

#feedback > div:first-child > .container > .row:first-child,
#feedback > div:first-child > .container-fluid > .row:first-child,
#bug-report > div:first-child > .container > .row:first-child,
#bug-report > div:first-child > .container-fluid > .row:first-child {
    /*border-bottom: 1px solid #ccc;
    margin: 0px 0px 15px -20px;
    background-color: #141c27;*/
    padding-left: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #141c27;
}

#bug-report label {
    background-color: #374D6C;
    width: 190px;
    max-width: 190px;
}

.bug-report-btn,
.feedback-btn {
    width: 190px;
    max-width: 190px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.tbs--form--block--feedback,
.tbs--form--block--bug {
    margin-bottom: 10px;
    /*margin-top: 10px;*/
}

#feedback-alert,
#bug-alert,
#invitation-alert,
#invitation-dashboard-alert,
#new-invitation-dashboard-alert,
#new-invitation-chat-alert,
#publication-dashboard-alert,
#reply-alert,
#reply-of-reply-alert,
#create-post-alert,
#other-profile-alert,
#friends-profile-alert,
#introduce-friend-alert,
#report-user-alert,
#publication-detail-delete-alert,
#profile-favorite-alert,
#newsfeed-all-alert {
    width: 80%;
    margin: 10px auto;
}

#feedback-alert > div,
#bug-alert > div,
#invitation-alert > div,
#invitation-dashboard-alert > div,
#new-invitation-dashboard-alert > div,
#new-invitation-chat-alert > div,
#publication-dashboard-alert > div,
#reply-alert > div,
#reply-of-reply-alert > div,
#create-post-alert > div,
#other-profile-alert > div,
#friends-profile-alert > div,
#report-user-alert > div,
#publication-detail-delete-alert > div,
#profile-favorite-alert > div,
#newsfeed-all-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

#profile-favorite-alert,
#newsfeed-all-alert,
#new-invitation-chat-alert {
    position: fixed;
    bottom: 25px;
    left: 10%;
    right: 10%;
    z-index: 22000;
}

#new-invitation .form--invitation input[type="text"],
#new-invitation .form--invitation input[type="email"],
#new-invitation .form--invitation input[type="search"]{
    width: 98.75%;
}

#new-invitation .form--invitation input[type="search"]{
    padding: 10px !important;
}

#new-invitation label {
    color: #141c27;
}

#new-invitation .button--sign {
    margin-top: 20px;
}

.feedback-btn,
.bug-report-btn {
    color: #fff !important;
    background-color: #374D6C !important;
    border-color: #374D6C !important;
    margin-top: 10px !important;
}

#feedback form textarea,
#bug-report form textarea {
    border-radius: 10px;
    border: 1px solid #ccc;
    width: 99.5%;
    padding: 10px 0px 10px 10px;
    box-sizing: border-box;
}

#feedback form .tbs--default--image,
#bug-report form .tbs--default--image {
    border-radius: 10px;
    border: 2px solid #ccc;
}

#feedback-alert,
#bug-alert {
    margin-top: 10px;
}

#feedback-alert > div,
#bug-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

/**
 * Feedback & Bug Report - End
 */

/* ########################################################################## */

/**
 * Update post - Start
 */

#update-post {
    margin: 20px auto;
    max-width: 1050px;
}

#update-post .container > .row,
#update-post .container-fluid > .row {
    margin-left: 0px;
}

#update-post .container > .row:first-child,
#update-post .container-fluid > .row:first-child {
    background-color: #374d6c;
    padding-left: 20px;
    margin-left: -20px;
    margin-bottom: 10px;
}

#update-post .form-update-post form input[type="text"] {
    width: 98.75%;
}

#update-post .form-update-post form input#audio {
    width: 100%;
}

#update-post .form-update-post label {
    color: #141c27;
    margin-right: 20px;
}

#updating-post,
#audio-recorder {
    margin-right: 8px;
}

#updating-post > button,
#audio-recorder > button {
    width: 100%;
}

#update-post .form-update-post #updating-post.button--sign {
    margin-top: 20px !important;
}

#update-post-alert {
    margin-top: 30px;
}

#update-post-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

#update-post .tbs--headline--update-post {
    margin: 25px 0px 20px 0px !important;
}

#update-post .tbs--form--complete--post {
    margin-left: -15px;
    margin-right: 10px;
}

#feedback > div:first-child > .container > .row:first-child,
#feedback > div:first-child > .container-fluid > .row:first-child,
#bug-report > div:first-child > .container > .row:first-child,
#bug-report > div:first-child > .container-fluid > .row:first-child {
    /*border-bottom: 1px solid #ccc;
    margin: 0px 0px 15px -20px;
    background-color: #141c27;*/
    padding-left: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #141c27;
}

#bug-report > div:nth-child(2) > .tbs--container,
#feedback > div:nth-child(2) > .tbs--container {
    border: none;
    border-radius: 0px;
    padding: 0px 0px 20px 20px;
    overflow: hidden;
    background-color: transparent;
}

.tbs--form--block--update--post {
    margin-bottom: 10px;
}

#update-post-alert {
    width: 80%;
    margin: 10px auto;
}

#update-post-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

.update-post-btn {
    color: #fff !important;
    background-color: #141c27 !important;
    border-color: #141c27 !important;
    margin-top: 10px !important;
}

#update-post-alert form textarea {
    border-radius: 0;
    border: 1px solid #ccc;
    width: 97.75%;
    padding: 10px 0px 10px 10px;
    box-sizing: border-box;
}

#update-post-alert {
    margin-top: 10px;
}

#update-post .tbs--default--image,
#update-post .tbs--user--profile--image {
    width: 98.75%;
    max-height: calc(50vh) !important;
    border-radius: 10px;
    overflow: hidden;
}

#update-post .tbs--default--image {
    border: 2px solid #ccc;
}

#update-post .tbs--user--profile--image {
    border: 1px solid #ccc;
}

.image--upload--success {
    border: 1px solid #ccc !important;
}

#update-post .tbs--default--image img,
#update-post .tbs--user--profile--image img {
    border-radius: 10px;
}

#update-post .tbs--default--image img.tbs--upload--image--new {
    object-fit: cover !important;
    height: 200px;
}

#update-post .tbs--default--image img.tbs--upload--image--newest {
    object-fit: cover !important;
    /*height: 200px;*/
}

#update-post textarea {
    resize: none;
    width: 98.5%;
    padding: 10px;
    border-radius: 10px;
    /*max-height: 100px;*/
}

/**
 * Update Post - End
 */

/* ########################################################################## */

/**
 * Form Handling and Validation - Start
 */

.error-text,
.tbs--form--main--error {
    color: #f00;
    font-weight: 500;
}

.tbs--form--main--error {
    width: 300px;
    margin: auto;
    white-space: pre-wrap;
}

.error-field {
    border: 2px solid #f00 !important;
}

/**
 * Form Handling and Validation - End
 */

/* ########################################################################## */

/**
 * Chats & Groups & Public Groups - Start
 */

#groups,
#chats,
#public-groups {
    /*margin-top: 20px !important;
    margin-left: -15px !important;
    margin-right: -15px !important;*/
    margin: 20px auto !important;
    /*max-width: 1050px;*/
}

.create-new-community {
    margin-top: 20px;
}

#createNewGroupButton {
    opacity: 0;
    pointer-events: none;
    z-index: -1;
}

.tbs--new--group--error--members {
    color: #ff0000;
    margin-top: 20px;
}

.create-group-button > div {
    margin-top: 0px !important;
}

.create-group-button > div > div {
    border-radius: 10px !important;
    padding: 10px 20px !important;
    background-color: #374d6c !important;
    border-color: #374d6c !important;
    color: #fff !important;
}

#group-create.create-new-community input,
#group-create.create-new-community textarea {
    width: 97%;
}

#group-create.create-new-community .tbs--default--image {
    width: 96.75%;
}

.create-new-community .tbs--container > .row:first-child{
    background-color: #374d6c;
    color: #fff;
    border: 1px solid #ccc;
    margin: 0px -30px 10px;
    /*padding-left: 15px;*/
}

.create-new-community .tbs--container > .row:last-child {
    padding: 0px 15px;
}

#group-create.create-new-community > div:first-child {
    padding: 0;
}

.create-new-community-upload-image {
    width: 96.75%;
    object-fit: cover !important;
    max-height: calc(50vh) !important;
}

/*#group-create.create-new-community .tbs--default--image > img {
    object-fit: cover !important;
}*/

#groups .container-fluid,
#chats .container-fluid {
    border-radius: 10px;
    /*min-height: calc(87.5vh);*/
    padding-left: 0;
}

#groups #group-contacts .container-fluid,
#chats #chat-contacts .container-fluid {
    border-radius: 0px !important;
    border-top-color: #FBFBFE;
    border-left-color: #FBFBFE;
    border-right-color: #ccc;
    border-bottom-color: #FBFBFE;
    /*min-height: calc(87.5vh);*/
    padding-left: 0;
}

#groups #group-room-contact,
#chats #chat-room-contact {
    border-radius: 0px !important;
    border-top-color: #FBFBFE;
    border-left-color: #FBFBFE;
    border-right-color: #FBFBFE;
    border-bottom-color: #FBFBFE;
    /*min-height: calc(87.5vh);*/
    padding-left: 0;
}

#chat-room-contact #chat-channel,
#group-room-contact #group-channel {
    padding: 0px 10px;
}

#groups .container-fluid .tbs--headline--chats {
    margin: 25px 0px 0px 15px;
}

.tbs--headline--chats > span {
    margin-left: 20px;
}

.tbs--headline--chats > div {
    background-color: #FBFBFE;
}

.tbs--headline--chats > span > img {
    width: 30px;
}

#groups .dropdown,
#chats .dropdown {
    width: 50px;
    margin-left: auto;
}

#groups .dropdown-menu:before,
#chats .dropdown-menu:before {
    right: 16px;
}

#groups .dropdown-menu-right,
#chats .dropdown-menu-right {
    margin-top: -9px !important;
    inset: 0px auto auto 0px !important;
    transform: translate3d(-181px, 54px, 0px) !important;
}
#groups .dropdown-menu-right{
    /*margin-left: 74px !important;*/
    margin-left: 1px !important;
    padding: 2px !important;
}
#chats .dropdown-menu-right {
    /*margin-left: 101px !important;*/
    margin-left: 30px !important;
    padding: 2px !important;
}

.tbs--mute--group {
    margin-bottom: 0;
}

#group-contacts,
#chat-contacts {
    padding-right: 2.5px;
}

#group-room-contact,
#chat-room-contact {
    padding-left: 0px;
    height: calc(87.5vh);
    overflow-y: scroll;
    margin-left: -3.5px;
    overflow-x: hidden;
    border: 1px solid #ccc;
    border-radius: 0px 10px 10px 0px;
    padding-right: 0px;
}

#group-room-contact .tbs--container,
#chat-room-contact .tbs--container {
    /*border-radius: 0px 10px 10px 0px !important;*/
    margin-left: 15px;
}

#group-room-contact #group-new-message textarea {
    margin-left: 1px;
}

#group-contacts .tbs--container > div:not(:first-child),
#chat-contacts .tbs--container > div:not(:first-child) {
    border-bottom: 1px solid #ccc;
    min-height: 100px;
}

#group-contacts .tbs--container > div:not(:last-child),
#chat-contacts .tbs--container > div:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

#group-contacts .row:not(:first-child) a,
#chat-contacts .row:not(:first-child) a {
    text-decoration: none;
    color: #000;
}

#group-contacts .row:not(:first-child) > div > a,
#chat-contacts .row:not(:first-child) > div > a {
    padding: 0px;
}

#group-contacts .row:not(:first-child) > div > a > .row,
#chat-contacts .row:not(:first-child) > div > a > .row {
    margin: 15px 0px 10px;
    /*min-height: calc(12.5vh);*/
}

#group-contacts .row:not(:first-child) > div > a > .row > div,
#chat-contacts .row:not(:first-child) > div > a > .row > div {
    display: table-row;
    /*margin-top: -10px;*/
}

#group-contacts .row:not(:first-child) > div > a > .row > div > div,
#chat-contacts .row:not(:first-child) > div > a > .row > div > div {
    display: table-cell;
}

#group-contacts .row:not(:first-child) > div > a > .row > div > div img,
#group-contacts .row:not(:first-child) > div > a > .row > div > div svg,
#chat-contacts .row:not(:first-child) > div > a > .row > div > div img,
#chat-contacts .row:not(:first-child) > div > a > .row > div > div svg {
    margin-bottom: -25px;
    margin-top: 10px;
}

#group-contacts .row:not(:first-child) > div > a > .row > div > div > div,
#chat-contacts .row:not(:first-child) > div > a > .row > div > div > div {
    padding-left: 10px;
}

#chat-channel > div > .container-fluid,
#group-channel > div > .container-fluid {
    border: 1px solid #ccc;
    padding-bottom: 15px;
}

#chat-contacts > .tbs--container,
#group-contacts > .tbs--container {
    /*overflow: initial;*/
    overflow-y: scroll;
    height: calc(87.5vh) !important;
    padding-bottom: 0 !important;
    border-radius: 10px 0px 0px 10px;
}

#chat-contacts > .container-fluid > .row,
#group-contacts > .container-fluid > .row {
    margin: 0 !important;
}

#chat-contacts > .container-fluid > .row:first-child,
#group-contacts > .container-fluid > .row:first-child {
    background-color: #FBFBFE;
    /*border-radius: 10px 0px 0px 0px;*/
    color: #141c27;
}

#chat-contacts > .container-fluid > .row:first-child {
    padding-bottom: 65px;
}

#group-contacts > .container-fluid > .row:first-child > div:first-child > div:first-child > span > svg {
    transform: rotate(45deg);
    margin-right: 10px;
    width: 25px;
    height: 25px;
}

#chat-contacts > .container-fluid > .row > div,
#group-contacts > .container-fluid > .row > div {
    padding: 0 !important;
}

#chat-contacts > .container-fluid > .row:not(:first-child):hover,
#group-contacts > .container-fluid > .row:not(:first-child):hover {
    background-color: #eee;
}

.active-chat {
    background-color: #eee;
}

#group-contacts > div > div:nth-child(1) > div {
    position: relative;
}

#group-contacts > div > div:nth-child(1) > div > div > span {
    position: absolute;
    background-color: #FBFBFE;
    z-index: 1;
    top: 5px;
}

#group-contacts > div > div:nth-child(1) > div > div > span:last-child {
    right: 5px;
}

#chat-contacts > div > div:not(:first-child),
#group-contacts > div > div:not(:first-child) {
    position: relative;
}

#chat-contacts > div > div:not(:first-child) > div:nth-child(2),
#group-contacts > div > div:not(:first-child) > div:nth-child(2) {
    position: absolute;
    max-width: 10%;
    right: 20px;
}

.is-available {
    display: block;
}

.is-not-available {
    display: none;
}

.show-chat-menu {
    display: block !important;
}

.admin-and-new-members {
    margin-top: 20px;
}

#chat-room-contact > #chat-channel > div > .container-fluid > .row,
#group-room-contact > #group-channel > div > .container-fluid > .row {
    margin-left: 0 !important;
}

.tbs--chat--details {
    width: 25px;
    height: 25px;
    background-color: #fff;
    border: 1px solid #141c27;
    border-radius: 25px;
    display: block;
    margin: 10px 10px 10px auto;
}

.tbs--chat--details-without {
    width: 25px;
    height: 25px;
    background-color: #141c27;
    border-radius: 25px;
    display: block;
    margin: 10px 10px 10px auto;
}

.tbs--chat--details-without img,
.tbs--chat--details img {
    width: 6.5px;
    /*transform: rotate(90deg);*/
    object-fit: cover;
    /*margin-left: -1px;*/
    margin-bottom: 2px;
}

.tbs--submenu--chat--overlay {
    display: none;
    position: absolute;
    width: 200px;
    right: -75px;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #fff;
    z-index: 1000;
    top: 42px;
}

.tbs--submenu--chat--overlay:before {
    content: "";
    position: absolute;
    right: 91px;
    top: -6px;
    width: 10px;
    height: 10px;
    border-top: 1px solid #ccc;
    background-color: #fff;
    z-index: 1000;
    border-right: 1px solid #ccc;
    transform: rotate(-45deg);
}

.tbs--submenu--chat--overlay > div {
    padding: 10px 0px;
}

.tbs--welcome--chat,
.tbs--welcome--group {
    border: none !important;
    padding: 10px;
    padding-left: 20px;
}

.tbs--image--groups {
    width: 100%;
    max-height: 200px;
}

.tbs--image--chats {
    width: 50px;
    height: 50px;
}

.tbs--image--chats svg {
    width: 50px;
    height: 60px;
    margin-top: 20px;
}

.tbs--image--chats--no--image {
    margin-top: 0 !important;
}

.tbs--image--chats > div {
    width: 50px;
    height: 50px;
    padding: 0 !important;
    border-radius: 25px;
}

#chats .tbs--image--chats > div {
    /*background-color: #a2a2a2 !important;*/
    background-color: transparent !important;
}

#groups .tbs--image--chats > div {
    background-color: transparent !important;
}

#chats .tbs--image--chats,
#chat-forward-select-contact .tbs--image--chats--introduce,
#chat-forward-contact-select-contact .tbs--image--chats--introduce {
    /*background-color: #ccc !important;*/
    border-radius: 25px;
}

#chat-forward-select-contact .tbs--image--chats--introduce,
#chat-forward-contact-select-contact .tbs--image--chats--introduce {
    background-color: #ccc !important;
    border-radius: 25px;
}

#chat-forward-select-contact .tbs--image--chats,
#chat-forward-contact-select-contact .tbs--image--chats {
    background-color: transparent;
    border-radius: 25px;
}

#chat-forward-select-contact .tbs--contact--name--select,
#chat-forward-contact-select-contact .tbs--contact--name--select {
    line-height: 2.25;
}

#chat-forward-select-contact .tbs--contact--checkbox--select,
#chat-forward-contact-select-contact .tbs--contact--checkbox--select {
    line-height: 3.0;
}

#chat-forward-contact-select-contact .button--sign,
#chat-forward-select-contact .button--sign {
    margin-top: 20px;
}

.tbs--chat--name {
    font-size: 14px;
    font-weight: bold;
}

.tbs--chat--name--no--results {
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
}

#group-contacts-search,
#member-contacts-search,
#admin-contacts-search {
    padding-right: 0px !important;
}

#groupSearch {
    border-radius: 10px;
    border: 2px solid #ccc;
    width: 100%;
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    margin-top: -15px;
}

#memberSearch {
    width: 260px !important;
    border: 2px solid #ccc !important;
    padding-left: 10px !important;
}

#memberSearchOnly {
    width: 460px !important;
    border: 2px solid #ccc !important;
    padding-left: 10px !important;
}

#adminSearchOnly {
    width: 460px !important;
    border: 2px solid #ccc !important;
    padding-left: 10px !important;
}

.tbs--chat--update--date {
    font-size: 12px;
    color: #575757;
}

#chat-contacts .tbs--headline,
#group-contacts .tbs--headline {
    padding-left: 10px;
    color: #141c27 !important;
    margin: 5px 0px 30px 0px !important;
    font-weight: 600;
    z-index: 1000;
    background-color: #FBFBFE;
}

#group-contacts .tbs--headline {
    padding: 10px 0px;
}

#chat-contacts .tbs--headline span,
#group-contacts .tbs--headline span:first-child {
    padding-right: 10px;
    background-color: #FBFBFE;
    font-size: 24px;
    z-index: 1000;
}

#chat-contacts .tbs--headline {
    position: absolute;
}

#group-contacts .tbs--headline span:first-child {
    margin-top: -7px;
}

#group-contacts .tbs--headline span:last-child {
    padding-left: 10px;
    background-color: #FBFBFE;
}

#tbs--chats--default {
    border: none !important;
    border-radius: 0px !important;
}

.tbs--headline--chats {
    margin-left: 0 !important;
    width: 100px;
    margin: 20px 0px 20px 0px !important;
    /*padding-left: 20px;*/
    font-weight: 600;
    background-color: #FBFBFE;
    color: #141c27;
    z-index: 1000;
}

.no-groups,
.no-chats {
    width: 96% !important;
}

.tbs--no--group--headline {
    margin-left: 0px !important;
    width: 250px !important;
    margin: 20px 0px 20px 0px !important;
    padding-left: 30px;
    font-weight: 600;
    background-color: #fff;
    color: #141c27;
    z-index: 1000;
}

.tbs--image--groups img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.tbs--image--chats img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50px;
}

.tbs--image--groups--default img {
    width: 100%;
    height: 200px;
    object-fit: scale-down;
}

.tbs--groups--list {
    border: 1px solid #ccc;
    padding: 0px;
}

.tbs--chat--last--message {
    font-size: 14px;
    margin: 0px;
}

.hide--element {
    display: none;
}

#chats .btn.btn-primary,
#chats .btn.btn-primary:focus,
#chats .btn.btn-primary:link,
#chats .btn.btn-primary:visited,
#chats .btn.btn-primary:hover,
#chats .btn.btn-primary:active,
#groups .btn.btn-primary,
#groups .btn.btn-primary:focus,
#groups .btn.btn-primary:link,
#groups .btn.btn-primary:visited,
#groups .btn.btn-primary:hover,
#groups .btn.btn-primary:active,
.show > .btn-primary.dropdown-toggle,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle:link,
.show > .btn-primary.dropdown-toggle:visited,
.show > .btn-primary.dropdown-toggle:hover,
.show > .btn-primary.dropdown-toggle:active {
    color: transparent !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

#chats form .btn.btn-primary:active,
#chats form .btn.btn-primary:link,
#chats form .btn.btn-primary:hover,
#chats form .btn.btn-primary:visited,
#chats form .btn.btn-primary:focus,
#chats form .btn.btn-primary,
#groups form .btn.btn-primary:active,
#groups form .btn.btn-primary:link,
#groups form .btn.btn-primary:hover,
#groups form .btn.btn-primary:visited,
#groups form .btn.btn-primary:focus,
#groups form .btn.btn-primary {
    border-radius: 10px !important;
    box-shadow: 0px 2px 2px #ccc !important;
    background-color: #141c27 !important;
    border-color: #141c27 !important;
    color: #fff !important;
}

.tbs--error--un-mute {
    color: #f00;
    font-weight: bold;
    text-align: center;
}

#group--overview--admin img,
#group--overview--contact img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 25px;
}

#group--overview--admin > .row > div > .row,
#group--overview--contact > .row > div > .row {
    margin-bottom: 10px;
}

#group--overview--admin > .row > div > .row > div:nth-child(2),
#group--overview--admin > .row > div > .row > div:nth-child(3),
#group--overview--contact > .row > div > .row > div:nth-child(2),
#group--overview--contact > .row > div > .row > div:nth-child(3) {
    line-height: 2.75;
}

#group--overview--admin input[type="checkbox"],
#group--overview--contact input[type="checkbox"] {
    width: 50px;
}

#group--overview--admin .button--sign,
#group--overview--contact .button--sign {
    margin-top: 20px;
}

#group--overview--contact .tbs--members--list--hidden {
    display: none;
}

#group--overview--contact .tbs--members--list--active {
    display: block !important;
}

#chats .tbs--message--complete--block--one,
#groups .tbs--message--complete--block--one {
    margin-left: -15px;
    /*max-width: 93%;
    flex: 0 0 93%;*/
}

#chats .tbs--message--complete--block--two,
#groups .tbs--message--complete--block--two {
    max-width: 7%;
    flex: 0 0 7%;
}

/* Chats & Groups Channel */

#chat-room-contact #chat-channel,
#group-room-contact #group-channel {
    margin: 0px !important;
    max-width: none !important;
}

#chat-room-contact #chat-channel > div,
#group-room-contact #group-channel > div {
    padding-left: 0px;
    padding-right: 0;
}

#chat-room-contact #chat-channel > div,
#group-room-contact #group-channel > div {
    padding-left: 0px;
    padding-right: 0;
}

.tbs--chat--submenu > div > div:nth-child(2),
.tbs--group--submenu > div > div:nth-child(2) {
    text-align: center;
}

.tbs--chat--submenu > div > div:nth-child(2) > div,
.tbs--chat--submenu > div > div:nth-child(2) > div > a,
.tbs--chat--submenu > div > div:nth-child(2) > div > div,
.tbs--group--submenu > div > div:nth-child(2) > div,
.tbs--group--submenu > div > div:nth-child(2) > div > a,
.tbs--group--submenu > div > div:nth-child(2) > div > div {
    color: #141c27 !important;
    cursor: pointer;
}

#chat-channel,
#group-channel {
    max-width: 1050px !important;
    margin: 5px auto !important;
}

#chat-channel .container-fluid,
#group-channel .container-fluid{
    background-color: #fff;
    border-radius: 10px 10px 10px 10px;
}

#chat-room-contact #chat-channel .container-fluid,
#group-room-contact #group-channel .container-fluid{
    background-color: #fff;
    border-radius: 10px;
}

#chat-channel .form-create-message form .row div:first-child,
#group-channel .form-create-message form .row div:first-child {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: -25px;
}

#chat-channel .form-create-message form button,
#group-channel .form-create-message form button {
    font-size: 12px;
    padding: 12.5px 20px !important;
}

#chat-channel .form-create-message,
#group-channel .form-create-message {
    width: 100%;
}

#chat-new-message .container-fluid,
#group-new-message .container-fluid {
    background-color: transparent !important;
    border-radius: 0 !important;
    border: 0 !important;
    padding: 15px 30px 15px 15px !important;
}

#chat-new-message input,
#group-new-message input {
    width: 100%;
    padding: 10px 40px 10px 10px;
}

#chat-channel .button--sign,
#group-channel .button--sign {
    margin-top: 0;
}

#chat-channel form .row > div:nth-child(2),
#group-channel form .row > div:nth-child(2) {
    padding-left: 0;
}

#chat-channel > div:nth-child(3),
#group-channel > div:nth-child(3) {
    padding-bottom: 50px;
}

#chat-new-message,
#group-new-message {
    margin-top: -45px;
}

#chat-new-message.no-messages,
#group-new-message.no-messages {
    margin-top: 0px;
}

#chat-new-message textarea,
#group-new-message textarea {
    resize: none;
    padding: 10px 140px 10px 10px;
    border: 1px solid #ccc !important;
}

#chat-new-message textarea {
    width: 93%;
}

#chat-new-message .tbs--comment--end {
    right: 100px;
}

#chat-new-message #conversation-message-submit {
    right: 30px;
}

#group-new-message textarea {
    width: 98.75%;
}

#group-room-contact #group-new-message textarea {
    width: 100%;
}

#chat-new-message.tbs-group-mobile,
#group-new-message.tbs-group-mobile {
    position: fixed;
    bottom: 0px;
    width: 70%;
    margin: auto;
}

.new-message-conversion {
    margin-top: -45px;
}

#chat-new-message .container,
#group-new-message .container {
    padding: 0;
}

#chat-new-message textarea,
#group-new-message textarea {
    border-radius: 10px;
}

#textImage,
#textComment,
#textPost {
    min-height: 50px;
    max-height: 100px;
}

#new-comment-alert {
    width: 80%;
    margin: 10px auto;
}

#new-comment-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

.tbs--chat--message--contact,
.tbs--chat--message--own {
    width: 90%;
    margin: 10px 0px;
    padding: 10px;
}

.tbs--chat--message--own {
    margin-right: 15px;
}

.tbs--status {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 20px;
    height: 20px;
    /*background-color: #ccc;*/
    border-radius: 25px;
}

.tbs--status img {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 2.5px;
    top: 2.5px;
}

.tbs--chat--message--contact {
    border-radius: 10px;
    background-color: #C5CDDC;
    margin-right: auto;
}

.tbs--chat--message--contact .tbs--post--image > img,
.tbs--chat--message--contact .tbs--image--chat--post > img {
    width: 100% !important;
    /*height: 100% !important;*/
    min-height: 200px !important;
}

.tbs--chat--message--contact .tbs--post--link > .row {
    margin-left: 0;
    margin-right: 0;
}

.tbs--chat--message--contact > .row > div:nth-child(1) > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.tbs--chat--message--contact > .row > div:nth-child(2) > div:nth-child(1) {
    font-weight: bold;
}

.tbs--chat--message--own > .row > div:nth-child(1) > div,
.tbs--chat--message--contact > .row > div:nth-child(2) > div:nth-child(2) {
    color: #fff;
    font-size: 14px;
}

.tbs--chat--message--own > .row > div:nth-child(1) > div,
.tbs--chat--message--contact > .row > div:nth-child(2) > div {
    margin: 5px 0px;
}

.tbs--chat--message--own {
    border-radius: 10px;
    background-color: #2A3A51;
    margin-left: auto;
}

.tbs--post--conversation--details {
    margin: 10px 0px;
}

.tbs--chat--message--submenu--contact {
    margin: 0px;
    padding: 10px 5px;
    border-radius: 10px !important;
}

.tbs--chat--message--submenu--own {
    margin-left: auto !important;
    padding: 10px 5px;
    border-radius: 10px !important;
    background-color: #2A3A51 !important;
    margin-right: 15px;
}

.tbs--chat--message--alert--own {
    margin-left: auto !important;
    margin-right: initial;
}

.tbs--chat--message--alert--own > div {
    font-size: 16px;
    margin-top: 5px;
}

.tbs--chat--message--submenu--contact a,
.tbs--chat--message--submenu--own a {
    color: #fff;
    text-decoration: none;
}

.tbs--chat--message--submenu--contact img,
.tbs--chat--message--submenu--own img {
    margin: 0px 10px;
    width: 25px;
    height: 25px;
}

.tbs--chat--message--submenu--contact span > a > img,
.tbs--chat--message--submenu--own span > a > img {
    width: 30px;
    height: 30px;
}

.tbs--chat--message--submenu--contact > div,
.tbs--chat--message--submenu--own > div {
    margin: 0px 10px;
    display: inline-block;
}

.tbs--chat--message--submenu--contact span,
.tbs--chat--message--submenu--own span {
    color: #fff;
    font-weight: bold;
    padding-left: 5px;
}

#group--messages {
    margin: 0px;
}

.tbs--chat--message--contact--description,
.tbs--chat--message--contact--description a {
    word-break: break-word;
    font-size: 16px;
}

.tbs--additional--post--text {
    margin-top: 10px;
}

.tbs--pin--messages--available {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #141c27;
    color: #fff;
    border-bottom: none;
}

.tbs--role--admin {
    font-weight: bold;
    color: #141c27;
    padding-left: 10px;
}

.tbs--chat--forward {
    margin-bottom: 10px;
}

.tbs--chat--forward img {
    width: 25px;
}

.tbs--chat--forward span {
    padding-left: 10px;
}

.tbs--image--chat--post {
    position: relative;
    width: 100%;
}

.tbs--image--chat--post img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.tbs--image--chat--post--profile img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 25px;
}

.tbs--post--link,
.tbs--post--link:hover,
.tbs--post--link:active,
.tbs--post--link:visited,
.tbs--post--link:focus {
    text-decoration: none;
    color: #000;
}

.chat-post-link-contact > .row > div:first-child,
.chat-post-link-own > .row > div:first-child,
.community-post-link-contact > .row > div:first-child {
    display: table-row;
}

.chat-post-link-contact > .row > div:first-child > div,
.chat-post-link-own > .row > div:first-child > div,
.community-post-link-contact > .row > div:first-child > div {
    display: table-cell;
}

.chat-post-link-contact > .row > div:first-child > div:first-child > img,
.community-post-link-contact > .row > div:first-child > div:first-child > img {
    margin-right: 15px;
    /*margin-top: 5px;*/
}

.chat-post-link-own > .row > div:first-child > div:first-child > img {
    margin-right: 0px;
    /*margin-top: 5px;*/
}

.chat-post-link-contact > .row > div:first-child > div:last-child,
.chat-post-link-own > .row > div:first-child > div:last-child{
    position: relative;
    /*top: -9px;*/
}

#group-create .tbs--form--complete--post {
    color: #000 !important;
}

#group-create input {
    border-radius: 10px;
    border: 2px solid #ccc;
    width: 100%;
    padding: 10px 10px 10px 10px !important;
    box-sizing: border-box;
}

/*#group-create .tbs--upload--image {
    border-radius: 15px !important;
}*/

#group-create textarea {
    border-radius: 5px;
    border: 2px solid #ccc;
    width: 100%;
    padding: 10px 0px 10px 10px !important;
    box-sizing: border-box;
}

#group-create .button--sign {
    margin-top: 40px !important;
}

#group-select-contact .button--sign {
    margin-top: 20px !important;
}

.new-community-top-layout {
    margin-top: -5px;
}

#group-create label {
    color: #141c27;
}

#group-create .tbs--upload--image {
    background-color: #141c27;
}

#group-create .tbs--upload--image.new-upload,
#new-post .tbs--upload--image.new-upload {
    background-color: transparent;
}

.new-image-upload #edit-file {
    border: none !important;
    right: 0px;
    bottom: 0px;
    z-index: 1000;
}

/*#delete-file {
    position: absolute;
    top: 0;
    right: 0;
}*/

#delete-file img {
    width: 50px;
    height: 50px;
}

.new-image-upload #delete-file {
    border: none !important;
    right: 0px;
    bottom: 0px;
    z-index: 1;
}

#group-create .text-left div {
    width: 100px;
    text-align: center;
    margin-top: 10px;
}

#group-create .text-left img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
}

#group-create button {
    width: 100%;
}

#group-create.create-new-community .button--sign {
    width: 96%;
}

#group-select-contact img {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    object-fit: cover;
}

.tbs--contact--name--select {
    margin-top: 5px;
}

.tbs--contact--checkbox--select input {
    width: 25px !important;
    margin-top: 10px;
}

.modal-sm .modal-content {
    box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.4);
}

#select-contact-group {
    overflow-x: auto;
    white-space: nowrap;
}

.tbs--contact--group {
    display: inline-block;
    float: none;
    margin-right: 20px;
    color: #000;
}

.create-post-alert {
    width: 80%;
    margin: 10px auto;
}

.create-post-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

.tbs--comment--start {
    position: absolute;
    right: 145px;
    top: 10px;
    width: 30px;
    height: 30px;
}

.tbs--comment--start--group {
    right: 80px !important;
}

.tbs--comment--end {
    position: absolute;
    right: 35px;
    top: 10px;
    width: 30px;
    height: 30px;
}

#comment-with-image {
    margin-bottom: 50px;
}

#comment-with-image .button--sign {
    margin-top: 10px;
    /*margin-left: 20px;*/
    width: 100%;
}

#comment-with-image .button--sign button {
    font-weight: 500;
    width: 100%;
}

#comment-with-image .button--sign,
#comment-with-image label {
    display: inline-block;
}

#comment-with-image input[type="text"] {
    width: 100%;
    padding: 10px;
    margin: 15px 0px;
}

.upload-file-button {
    position: absolute;
    bottom: 0;
    right: 0;
}

.tbs--post--image img {
    width: 100%;
    max-height: calc(50vh);
    object-fit: cover;
    border-radius: 10px;
}

.chat-post-link-own > .row > div:nth-child(3),
.chat-post-link-contact > .row > div:nth-child(3),
.community-post-link-own > .row > div:nth-child(3),
.community-post-link-contact > .row > div:nth-child(3) {
    min-height: 200px;
}

.tbs--reverse--image {
    transform: scaleX(-1);
}

.tbs--toBottom--image {
    transform: rotate(45deg);
}

.tbs--accept--request {
    width: 25px;
}

.tbs--accept--request--activate {
    /*border: 2px solid #fff;
    padding: 2px;*/
}

#submenu-alert,
#submenu-alert-contact {
    width: 80%;
    margin: 10px auto;
}

#submenu-alert > div,
#submenu-alert-contact > div {
    font-size: 16px;
    margin-top: 5px;
}

#unpin-alert {
    width: 80%;
    margin: 10px auto;
}

#unpin-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

#chat-forward-select-contact .tbs--error--forward {
    color: #f00;
    font-weight: bold;
    text-align: center;
}

.tbs--chat--message--contact .tbs--reply--message--chats {
    color: #000 !important;
    border-left: 5px solid #000;
    padding-left: 10px;
}

.tbs--chat--message--own .tbs--reply--message--chats {
    color: #fff !important;
    border-left: 5px solid #fff;
    padding-left: 10px;
}

.tbs--headline--name {
    font-size: 20px;
}

.chat-post-link-own .tbs--chat--message--contact--description,
.chat-post-link-own .tbs--post--conversation--details,
.community-post-link-own .tbs--chat--message--contact--description,
.community-post-link-own .tbs--post--conversation--details {
    color: #fff !important;
}

.tbs--my--chat--messages,
.tbs--contact--chat--messages {
    display: table-row;
    margin-top: -15px;
}

.tbs--my--chat--messages > div,
.tbs--contact--chat--messages > div  {
    display: table-cell;
}

.tbs--my--chat--messages > div > div,
.tbs--contact--chat--messages > div > div  {
    margin-left: 20px;
}

.tbs--my--chat--messages img,
.tbs--contact--chat--messages img {
    margin-top: 15px;
}

.tbs--contact--chat--messages img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 25px;
}

.tbs--image--chat--post > img {
    border-radius: 10px !important;
}

.tbs--contact--chat--messages > div > div  {
    margin-bottom: 5px;
}

.tbs--contact--chat--messages > div > div:first-child {
    font-weight: bold;
}

.tbs--comment--chat--date {
    color: #141c27;
    font-size: 14px;
    font-weight: 400 !important;
    padding-left: 10px;
}

.image-comment-submit {
    position: relative;
}

#conversation-message-submit {
    margin: 0 !important;
    position: absolute;
    top: -5px;
    right: -30px;
}

#conversation-image-message-submit {
    /*margin: 0 !important;
    position: absolute;
    top: 27px;
    right: 10px;*/
}

#conversation-message-submit img,
#conversation-image-message-submit img {
    width: 35px;
    height: 35px;
    object-fit: cover;
}

#conversation-message-submit .btn
/*#conversation-image-message-submit .btn*/ {
    padding: 0px !important;
    border: none;
    border-radius: 0;
    background-color: transparent;
}

#chat-channel #conversation-message-submit .btn,
#group-channel #conversation-message-submit .btn {
    /*padding: 0px !important;*/
    border: none;
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

#chats #chat-channel #chat-new-message .tbs--message--complete--block--one textarea {
    width: 100%;
    margin-left: 0px;
}

#chats #chat-channel #chat-new-message .tbs--message--complete--block--one > div > .tbs--comment--start {
    right: 75px;
}

#chats #chat-channel #chat-new-message .tbs--message--complete--block--one img {
    right: 35px;
}

#chats #chat-channel #chat-new-message .tbs--message--complete--block--one .tbs--message--complete--block--two > div:first-child {
    right: -35px;
}

#chat-new-message > div > div > div > div > div.tbs--form--complete--post > form > div.row {
    margin-right: -75px;
}

#chat-room-contact #chat-new-message > div > div > div > div > div.tbs--form--complete--post > form > div.row {
    margin-right: -20px !important;
}

.error-message-distance {
    margin-top: -70px;
}

#erase-conversation-modal .button--sign {
    margin-top: 20px !important;
}

#delete-community-modal .button--sign {
    margin-top: 20px !important;
}

#add-to-your-network .button--sign {
    margin-top: 10px !important;
}

#add-to-your-network button {
    width: 150px !important;
}

.no-message-available {
    height: 100px;
    padding: 30px 15px;
}

.start-chat-conversation {
    height: calc(75vh);
}

#new-chat-conversation {
    position: absolute;
    top: 50%;
    transform: translate(-25px, -25px);
    width: auto;
    left: 20%;
}

#public-groups #group-contacts {
    padding-right: 0px !important;
}

#public-groups .tbs--container--feedlist > .row:first-child {
    margin: 0px 0px 10px 15px !important;
    background-color: transparent;
    color: #141c27;
    border: none;
    border-radius: 0;
}

#public-groups > .tbs--container--feedlist > div > div {
    padding: 0px !important;
    margin-bottom: 30px;
}

#public-groups > .tbs--container--feedlist > div > div > .tbs--headline {
    color: #141c27 !important;
    z-index: 1000;
    font-weight: 600;
    font-size: 24px;
    margin: 0px !important;
    background-color: #FBFBFE !important;
    padding-right: 10px;
    position: absolute;
}

.tbs--headline--outside {
    color: #141c27 !important;
    z-index: 1000;
    font-weight: 600;
    font-size: 24px;
    margin: 0px !important;
    background-color: transparent !important;
    padding-right: 10px;
    position: absolute;
}

#public-groups > .tbs--container--feedlist > div > div > .tbs--divider--title {
    right: 0 !important;
}

#public-groups > .tbs--container--feedlist > div > div > .tbs--additional--menu {
    top: 8px !important;
}

#public-groups #group-contacts > .tbs--container {
    padding: 0 !important;
    border-radius: 10px !important;
    height: calc(66.5vh) !important;
}

#no-groups {
    display: none;
}

#public-groups #group-contacts .tbs--container > div:first-child {
    background-color: #FFFFFF !important;
    padding-bottom: 20px;
}

#public-groups #group-contacts .tbs--container > div:first-child > div {
    background-color: #FFFFFF !important;
}

#public-groups #group-contacts .row:first-child > div > a  {
    text-decoration: none;
    color: #141c27;
}

#public-groups #group-contacts .row:first-child > div > a > .row {
    margin: 15px 0px 10px;
}

#public-groups #group-contacts .row:first-child > div > a > .row > div {
    display: table-row;
}

#public-groups #group-contacts .row:first-child > div > a > .row > div > div {
    display: table-cell;
}

#public-groups #group-contacts .row:first-child > div > a > .row > div > div:last-child {
    padding-left: 10px;
}

#tbs-no-public-groups {
   padding: 20px;
}

#group--public--logout--success button {
    width: 120px;
}

#group--public--logout--success .button--sign {
    margin: 25px 0px 20px 0px !important;
}

.additional-request-text {
    margin: 10px 0px;
}

/**
 * Chats & Groups & Public Groups - End
 */

/* ########################################################################## */

/**
 * Community Info - Start
 */

#community {
    background-color: #fff;
    border: 1px solid #ccc;
    margin-top: 20px;
    border-radius: 10px;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

#community .tbs--default--image,
#community .tbs--community--image {
    text-align: center;
    /*border: 1px solid #ccc;*/
    border: none !important;
    width: 100%;
    position: relative;
    object-fit: cover;
    /*border-radius: 10px;*/
}

#community .tbs--default--image img {
    width: 100%;
    object-fit: scale-down;
    border-radius: 10px 10px 0px 0px;
}

#community .tbs--community--image img {
    width: 100%;
    object-fit: cover;
    border-radius: 0px;
    max-height: calc(65vh);
}

#community .row:not(:first-child) {
    padding-bottom: 15px;
}

#community .row:nth-child(2) {
    margin-right: -45px;
    margin-left: -45px;
}

#community > div > .community > .row:nth-child(3) > div {
    margin-bottom: 10px;
    margin-top: 10px;
}

#community-admin-user div:not(:first-child) {
    overflow-x: auto;
    white-space: nowrap;
}

#community-admin-user > .tbs--group--info--admin > .tbs--group--admin--member,
#community-admin-user > .tbs--group--info--user > .tbs--group--user--member {
    display: inline-block;
    float: none;
    padding-right: 20px;
    margin-right: 10px;
    color: #000;
    text-decoration: none;
    position: relative;
}

#community-admin-user img {
    width: 125px;
    height: 125px;
    object-fit: cover;
    border-radius: 50%;
}

#community-admin-user > div > div > a > div {
    width: 125px;
    position: relative;
}

#community-admin-user > div > div > a > div.text-center {
    margin-top: 10px;
}

#community-admin-user > div > div > a {
    color: #374D6C;
    text-decoration: none;
}

.tbs--community--button {
    color: #fff;
    background-color: #374D6C;
    padding: 10px;
    border: 1px solid #374D6C;
    border-radius: 10px;
    width: 111px;
    height: 52.5px;
    margin: 10px auto;
    cursor: pointer;
}

.tbs--community--button--requests {
    width: 185px !important;
    height: 52.5px !important;
    padding: 12.5px 10px !important;
}

.tbs--community--button--requests--pending {
    position: absolute;
    top: 10px;
    background-color: #ff0000;
    border: 1px solid #ff0000;
    width: 20px;
    height: 20px;
    border-radius: 25px;
}

.tbs--community--button--requests--overlay {
    width: 120px !important;
    height: 52.5px !important;
}

.tbs--community--button--requests--overlay span {
    /*text-align: center !important;*/
    margin-left: 35px !important;
}

.tbs--community--button--requests--submit {
    text-align: center !important;
}

.tbs--community--button--requests--main {
    padding: 20px;
}

.tbs--community--button--requests--main--two {
    padding: 20px;
}

.tbs--community--button--requests--main--two:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
}

.tbs--request--join--community {
    padding-bottom: 0px !important;
}

.tbs--request--join--community > div:nth-child(2){
    padding-top: 10px !important;
}

.tbs--community--button img {
    /*object-fit: cover;*/
    width: 25px;
    height: 25px;
}

.tbs--community--button .unmute img {
    /*object-fit: cover;*/
    width: 20px;
    height: 20px;
}

.tbs--community--button span.unmute > span {
    position: absolute;
    margin-left: 14px;
}

.tbs--community--button span.leave-additional > span {
    margin-left: 12px;
}

.tbs--community--button span > span {
    margin-left: 20px;
    text-align: right;
}

#request-list img {
    width: 125px;
    height: 125px;
    object-fit: cover;
    border-radius: 50%;
}

.tbs--image--request {
    margin-bottom: 10px;
}

#community > div > .community > .row:nth-child(3) > div:nth-child(2) > a > div > span > span {
    margin-left: 15px;
}

.tbs--public--community--outside {
    padding-bottom: 20px;
}

.tbs--chat--link a {
    text-decoration: none;
}

.tbs--button--middle > .tbs--community--button {
    display: inline-block;
}

.tbs--headline--users {
    color: #141c27;
    font-weight: bold;
    margin: 25px 0px;
}

.tbs--headline--users--admin {
    font-size: 14px;
    margin-left: 20px;
    cursor: pointer;
}

#mute-alert {
    width: 80%;
    margin: 10px auto;
}

#mute-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

#mute-create .row > div:first-child {
    padding: 0;
}

#mute-create .button--sign {
    margin-top: 20px;
}

#mute-create .button--sign button {
    margin-right: 20px;
    width: 100px;
}

#communityMute label input,
#communityMute label span {
    display: inline-block;
    font-weight: 500;
}

#communityMute input {
    width: 50px !important;
}

#community label {
    color: #141c27;
    margin-top: 20px;
}

#community input[type="text"] {
    border-radius: 10px;
    border: 1px solid #ccc;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

#community .button--sign {
    margin-top: 40px;
}

#tip-chats .container-fluid,
#tip-groups .container-fluid {
    border-radius: 10px;
    height: auto !important;
    padding-left: 10px;
    padding-bottom: 20px;
    margin-top: 0px;
    min-height: 0px !important;
    margin-bottom: 15px !important;
}

#update-community-alert {
    width: 80%;
    margin: 10px auto;
}

#update-community-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

#community-admin > .row > div > .row > div,
#community-member > .row > div > .row > div {
    margin: 10px 0px;
}

#community-admin > .row > div > .row > div:not(:first-child),
#community-member > .row > div > .row > div:not(:first-child) {
    line-height: 2.75;
}

#community-admin img,
#community-member img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 25px;
}

#community-admin input[type="checkbox"],
#community-member input[type="checkbox"] {
    width: 50px;
}

#community-admin .button--sign,
#community-member .button--sign {
    margin-top: 20px;
}

#admin-alert,
#member-alert {
    width: 80%;
    margin: 10px auto;
}

#admin-alert > div,
#member-alert > div {
    font-size: 16px;
    margin-top: 5px;
}

#community > div > div > .row:nth-child(1) {
    background-color: #374d6c;
    margin-left: -30px;
    margin-right: -30px;
    border-radius: 10px 10px 0px 0px;
    /*margin-bottom: 15px;*/
}

#community > div > div > .row:nth-child(1) > .tbs--headline {
    padding-left: 15px;
    font-size: 24px;
}

#delete-community {
    width: 17px;
    height: 17px;
    margin-left: 3px;
}

#community-main-block {
    position: relative;
}

#community-main-block label {
    position: relative;
}

#community-main-block #upload-image {
    position: absolute;
    bottom: 17px;
    left: 7px;
    z-index: 100;
    height: 54px;
    width: 73px;
    opacity: 0;
}

.tbs--created--chat--date {
    margin: 0px;
    /*border-radius: 0px 0px 10px 10px;*/
    font-size: 12px;
    text-align: left;
    padding: 10px 25px 10px 10px;
    background-color: #374d6c;
    color: #fff;
}

#community-admin-user div:not(:first-child)::-webkit-scrollbar {
    display: none;
}

#community-admin-user div:not(:first-child) {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

#no-member {
    /* Previous */
    /*width: 100% !important;
    height: 100% !important;*/
    width: 250px !important;
    object-fit: scale-down !important;
    border-radius: 0 !important;
}

#textImage {
    resize: none;
    width: 99%;
    padding: 10px 50px 10px 10px;
    margin-top: 20px;
}

#upload-file {
    width: 50px !important;
    height: 50px !important;
    object-fit: cover;
    border-radius: 50% !important;
    border: 2.5px solid #fff;
    background-color: #141C27;
}

#edit-file {
    position: absolute;
    bottom: -5px;
    right: 0px;
}

#community .form-update-community button {
    width: 100%;
}

#community textarea {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    height: auto !important;
}

.tbs--group--info--admin {
    position: relative;
}

.tbs--group--info--invite {
    position: absolute;
    left: 100px;
    top: 0px;
    width: 45px;
    height: 45px;
    background-color: #141C27;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    z-index: 1000;
}

.tbs--group--info--invite svg {
    width: 25px;
    height: 25px;
    margin-top: 10px;
}

.tbs--public--communinity--request {
    width: 200px !important;
    height: 50px !important;
    text-align: center !important;
    cursor: pointer;
}

.tbs--public--communinity--request > span > span {
    margin-left: 0px !important;
}

.tbs--public--community--description {
    overflow: scroll !important;
}

/**
 * Community Info - End
 */

/* ########################################################################## */

/**
 * Chats & Groups - Publications - End
 */

#group-publication,
#chat-publication {
    margin-top: 30px;
}

#group-publication div .container-fluid,
#chat-publication div .container-fluid {
    margin: 0px 0px 20px 0px;
    padding: 0;
}

#chat-publication > div:first-child .container-fluid,
#group-publication > div:first-child .container-fluid {
    background-color: #374d6c;
}

#chat-publication .tbs--headline,
#group-publication .tbs--headline {
    font-size: 24px;
}

#group-publication a,
#chat-publication a {
    text-decoration: none;
    color: #000;
}

#group-publication > div > .container-fluid > a > .row > div:nth-child(4),
#chat-publication > div > .container-fluid > a > .row > div:nth-child(4) {
    margin: 10px 0px;
}

.tbs--post--element {
    margin: 25px 0px 25px 15px;
}

.chat--publications .row {
    margin: 0;
    padding: 10px 10px 10px 0px !important;
}

#group-publication .tbs--contact--data--feedlist span,
#chat-publication .tbs--contact--data--feedlist span {
    padding: 0px 2.5px;
}

.post-user-data {
    display: table-row;
    margin-top: -30px;
}

.post-user-data > div {
    display: table-cell;
}

#chat-publication .tbs--contact--data--feedlist--image > img,
#group-publication .tbs--contact--data--feedlist--image > img {
    min-height: 200px;
    width: 100% !important;
}

#chat-publication .tbs--contact--data--feedlist--title,
#group-publication .tbs--contact--data--feedlist--title {
    margin-top: 10px;
}

.post-user-data > div:last-child {
    padding-left: 15px;
}

.post-user-data img {
    margin-top: 40px;
}

/**
 * Chats & Groups - Publications - End
 */

/* ########################################################################## */

/**
 * Network (Highlight) - Start
 */

#network {
    margin: 20px auto !important;
    max-width: 1050px;
}

#network .tbs--container {
    padding: 0px 15px;
}

#network .tbs--container--network {
    padding: 0px 15px 15px 15px;
    overflow: hidden;
    background-color: transparent;
}

#network .tbs--container--network--switch {
    padding: 0px 15px;
    /*overflow: hidden;*/
    background-color: transparent;
    margin-top: 30px;
}

#network .tbs--container--network--blocks {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 0px 0px 20px 0px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #fff;
}

#network .tbs--container--network--blocks:nth-child(2),
#network .tbs--container--network--blocks:nth-child(3) {
    height: auto !important;
}

#network .tbs--container--network--blocks {
    height: calc(64vh);
}

#network .tbs--container--network--blocks img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

#network .tbs--container--network--blocks--no--image {
    width: 100px;
    height: 100px;
    object-fit: scale-down !important;
}

#network > div > div.tbs--container--network.container-fluid > div > div:nth-child(1) {
    background-color: #374d6c;
    border-radius: 10px;
}

/*.tbs--container--network--blocks > div {
    overflow-x: auto;
    white-space: nowrap;
}

.tbs--container--network--blocks > div > div {
    display: inline-block;
}*/

.tbs--post--title--network {
    font-weight: 500;
    font-size: 24px;
}

.tbs--no--post--network {
    font-weight: 500;
    font-size: 24px;
    margin-top: -70px;
}

.no-data-image {
    width: 50px !important;
    height: 50px !important;
    object-fit: scale-down !important;
}

.no--image--container--network {
    /*margin: 10px auto !important;
    width: 50px;*/
    text-align: center;
}

.tbs--no--data--network {
    position: absolute;
    top: 350%;
}

#network .tbs--container--network--blocks > div > div > a > div:not(:first-child) {
    margin-top: 10px;
}

.tbs--times--highlight {
    margin: 10px 0px 25px 15px;
    text-align: left;
}

.tbs--times--highlight > div {
    display: inline-block;
    margin-right: 15px;
    color: #fff;
    cursor: pointer;
}

.tbs--network--slider {
    margin-left: auto;
    width: 100%;
}

.tbs--network--slider img {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.tbs--network--slider div {
    display: inline-block;
}

.tbs--slider--image--left {
    padding-left: 0px;
    position: absolute;
    left: -40px;
    top: 0;
}

.tbs--slider--image--right {
    padding-left: 0px;
    position: absolute;
    right: -40px;
    top: 0;
}

#network .tbs--slider--image--right,
#network .tbs--slider--image--left {
    /*background-color: white;*/
    border-radius: 50%;
}

#network .tbs--slider--image--left > img {
    width: 20px;
    height: 20px;
}

#network .tbs--network--slider img {
    object-fit: scale-down;
    border-radius: 0 !important;
}

.tbs--network--slider img {
    /*border: 1px solid #fff;
    background-color: #fff;*/
    border-radius: 25px;
}

/*.tbs--network--main--header > div {
    display: inline-block;
}*/

#network .tbs--network--slider > .switch > .slider {
    border: 1px solid #fff;
}

.tbs--container--network--blocks > .row > div {
    margin-bottom: 20px;
}

.tbs--highlight--image img {
    width: 60px !important;
    object-fit: cover !important;
    margin: 30px 0px;
}

.tbs--awards--highlights--one {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: -20px;
}

.tbs--awards--highlights--two {
    line-height: 6.5;
}

.tbs--awards--highlights--two img {
    width: 30px;
}

.tbs--highlight--active--link {
    color: #FF9100 !important;
    /*font-weight: bold;*/
}

.tbs--container--network--blocks a {
    color: #000;
    text-decoration: none;
}

.tbs--container--network--blocks--two a {
    color: #141c27;
    text-decoration: underline;
}

.tbs--container--network--blocks--two {
    color: #141c27;
    font-weight: bold;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.tbs--container--network--blocks--two > .row {
    margin: auto;
}

.tbs--container--network--blocks--two > .row > div {
    /*border: 1px solid #ccc;*/
    padding: 35px 0px;
    border-radius: 10px;
}

.tbs--container--network--blocks--two > .row > div > div:first-child {
    font-size: 64px;
}

.tbs--container--network--blocks--custom > .row > .tbs--post--detail--block {
    margin-left: 30px;
    min-height: 110px;
}

.tbs--container--network--blocks--custom > .row > .tbs--post--detail--block > a > div {
    margin-right: 60px;
}

.tbs--container--network--blocks--custom img {
    /*width: 100% !important;
    height: 100% !important;*/
    object-fit: cover;
}

.tbs--headline--network {
    color: #fff;
    background-color: #374d6c;
    font-weight: 500;
    font-size: 20px;
    margin: 0px 0px 10px 0px;
    padding: 10px 0px 10px 30px;
}

.tbs-counter-block {
    position: relative;
}

.tbs-counter-block img {
    border-radius: 50%;
}

.tbs-counter {
    position: absolute;
    margin-top: 0 !important;
    right: 40px;
    bottom: -10px;
    background-color: #141c27;
    color: #fff;
    padding: 4px 10px;
    border-radius: 25px;
    border: 2px solid #fff;
    width: 35px;
    height: 35px;
}

.tbs-friend-name-network {
    font-weight: 500;
}

.tbs-friend-position-company-network {
    color: #ccc;
    display: none;
}

.new--awards--style {
    background-color: #fff;
    width: 100% !important;
}

.all--awards--data {
    margin: auto;
    width: 70% !important;
}

.all--awards--data--specific {
    font-size: 24px;
}

.all--awards--data--specific:first-child {
    margin-bottom: 25px;
}

#all-awards > .row:last-child {
    cursor: pointer;
}

/*#all-awards > .row:first-child {
    background-color: #374d6c;
}*/

#all-awards > .row:first-child > div {
    padding: 5px 0px 0px 0px;
    background-color: #374d6c;
    border-radius: 10px 10px 0px 0px;
    color: #fff;
}

#all-awards > .row:nth-child(2) {
    cursor: pointer;
}

#all-awards > .row:first-child > div > div {
    border-radius: 10px 10px 0px 0px;
    font-size: 20px !important;
    padding: 5px 0px 0px 30px;
}

.show {
    display: block !important;
}

.hide {
    display: none !important;
}

.hideElement {
    opacity: 0 !important;
}

/* Toggle input slider */

.tbs--network--slider > .switch {
    margin-left: auto;
    margin-right: 25px;
}

/*.tbs--network--slider > .switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px;
}*/

.tbs--network--slider > .switch input {
    display:none;
}

.tbs--network--slider > .switch > .slider {
    background-color: #374d6c;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
    border: 1px solid #374d6c;
}

.tbs--network--slider > .switch > .slider:before {
    background-color: #fff;
    bottom: 2.5px;
    content: "";
    height: 20px;
    left: 2px;
    position: absolute;
    transition: .4s;
    width: 20px;
}

.tbs--network--active {
    background-color: #374d6c !important;
}

.tbs--network--active:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
}

.tbs--network--deactivate {
    background-color: #374d6c !important;
}

.tbs--network--deactivate:before {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
}

input#network-slider:checked + .tbs--network--slider > .switch > .slider {
    background-color: #21F396;
}

input#network-slider:checked + .tbs--network--slider > .switch > .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
}

.tbs--network--slider > .switch > .slider.round {
    border-radius: 34px;
}

.tbs--network--slider > .switch > .slider.round:before {
    border-radius: 50%;
}

#network .network--text--image img {
    width: 100%;
    height: 250px;
    margin-top: 10px;
    border-radius: 10px;
}

#network .network--text--image > div:last-child {
    height: 270px;
}

#network .tbs--awards--user--highlights {
    position: relative;
}

#my-awards-overview .tbs--awards--user--highlights > span {
    position: absolute;
    top: 0;
    background-color: #141c27;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

/**
 * Network (Highlight) - End
 */

/* ########################################################################## */

/**
 * Network (Contacts / Friends) - Start
 */

#network-contacts {
    margin: 20px auto; /* !important; */
    max-width: 1050px;
}

#network-headline {
    background-color: #141c27;
    margin-left: -20px;
    padding-left: 10px;
}

#network-contacts .tbs--network--slider > .switch > .slider {
    border-color: #fff !important;
}

#network-headline > div:nth-child(2) {
    margin-top: 8px;
    padding-right: 50px;
}

.slider-main-switch-container {
    position: relative;
    right: 30px;
    bottom: -100px;
    z-index: 20;
}

.slider-main-switch-container-active {
    position: relative;
    right: 30px;
    bottom: -105px;
    z-index: 20;
}

#trusted .tbs--container > .row:first-child,
#feature .tbs--container > .row:first-child {
    margin-bottom: 10px;
}

#trusted .tbs--container > .row:first-child {
    margin-left: 20px;
    margin-right: 10px;
}

#network-contacts > div:nth-child(1) > .container-fluid {
    padding-bottom: 0;
}

#network-contacts > div:nth-child(2) > .container-fluid {
    margin: 0px 0px 10px 0px;
    padding: 0 !important;
}

#network-contacts > div:nth-child(3) > .container-fluid {
    padding: 0;
}

input#network-slider-contact:checked + .tbs--network--slider > .switch > .slider {
    background-color: #21F396;
}

input#network-slider-contact:checked + .tbs--network--slider > .switch > .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
}

.tbs--slider--image--right--network {
    padding-left: 0px;
    position: absolute;
    right: -40px;
    top: -2px;
}

.nav-is-active {
    color: #141c27;
}

.network-is-mobile {
    margin-top: 100px;
}

.network-main {
    margin-top: -10px !important;
}

.network-main-mobile {
    margin-top: 1px !important;
}

.network-feature {
    margin-top: -65px !important;
    bottom: -95px !important;
}

.network-trusted {
    margin-top: -55px !important;
    bottom: -90px !important;
}

/**
 * Network (Contacts / Friends) - End
 */

/* ########################################################################## */

/**
 * Network (Awards) - Start
 */

#my-awards-overview a,
#contact-awards-overview a {
    text-decoration: none;
    color: #000;
}

#my-awards-overview img,
#contact-awards-overview img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 25px;
}

.tbs--container--network--blocks--two > .row > div:last-child {
    display: none;
}

/**
 * Network (Awards) - End
 */

/* ########################################################################## */

/**
 * Message (General alert) - Start
 */

#feed-list > div:first-child {
    padding: 0;
}

/*#feed-list .tbs--container--feedlist > .row > div,*/
#network-contacts > div:nth-child(2) #network-headline > div:first-child > div {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

/**
 * Message (General alert) - End
 */

/* ########################################################################## */

/**
 * TBS - End
 */

#tbs-detail {
    margin-top: 20px;
}

#tbs-detail .container-fluid {
    padding-top: 20px;
}

.tbs--logo--description {
    width: 100%;
}

#tbs-detail .container-fluid .row > div {
    padding-right: 30px;
}

.tbs--block--description {
    margin-bottom: 20px;
}

.tbs--logo--description > img {
    width: 100%;
    height: 250px;
    border-radius: 25px;
    object-fit: cover;
}

.tbs--header--description h1 {
    font-size: 24px !important;
    color: #141c27;
    margin: 30px;
}

.tbs--block--description h2 {
    font-size: 18px !important;
    color: #000;
    margin: 10px 0px;
}

.tbs--block--description a,
.tbs--block--description a:hover {
    color: #141c27;
    text-decoration: underline;
}

.tbs--block--description > div {
    margin: 10px 0px;
}

/**
 * TBS - End
 */

/* ########################################################################## */

/**
 * React-Emoji / Emoji-mart - Start
 */

.react-emoji {
    width: 91% !important;
}

.react-input-emoji--input {
    padding-right: 130px;
}

.react-input-emoji--button {
    position: absolute;
    right: 100px;
    top: 0;
    z-index: 1000000;
}

.emoji-mart {
    width: 94% !important;
}

.emoji-mart-bar {
    width: 100%;
}

/**
 * React-Emoji / Emoji-mart - End
 */

/* ########################################################################## */

/**
 * Off-Canvas Mmenu - Start
 */

nav#menu {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    /* z -index: 100; */
}

#burger-menu {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.mm-menu * {
    background-color: #fff !important;
}

.mm-navbar__title {
    padding: 24px 0px;
    background-color: #141c27 !important;
}

.mm-navbar__title span {
    color: #fff;
    background-color: #141c27 !important;
}

.mm-navbar {
    border-bottom: 1px solid #fff;
    background-color: #374d6c !important;
    color: #fff;
}

.mm-navbar > div {
    margin: 12.2px 0px;
}

.mm-btn_close:after {
    right: 32px !important;
}

.mm-btn_close:before,
.mm-btn_close:after {
    width: 10px !important;
    height: 10px !important;
}

.mm-navbar > div,
.mm-navbar > div > span,
.mm-navbar > a {
    background-color: #374d6c !important;
    color: #fff !important;
}

.mm-navbar > a:after,
.mm-navbar > a:before {
    border-color: #fff !important;
}

.mm-listview > li:nth-child(1),
.mm-listview > li:nth-child(2),
.mm-listview > li:nth-child(3) {
    display: none;
}

#mm-0 > ul > li > a {
    color: #141c27 !important;
}

#mm-0 > ul > li:nth-child(1) > a.tbs--active--link::after,
#mm-0 > ul > li:nth-child(2) > a.tbs--active--link::after,
#mm-0 > ul > li:nth-child(3) > a.tbs--active--link::after,
#mm-0 > ul > li:nth-child(4) > a.tbs--active--link::after,
#mm-0 > ul > li:nth-child(5) > a.tbs--active--link::after,
#mm-0 > ul > li:nth-child(12) > a.tbs--active--link::after,
#mm-0 > ul > li:nth-child(13) > a.tbs--active--link::after,
#mm-0 > ul > li:nth-child(14) > a.tbs--active--link::after {
    content: "-";
    width: 10px;
    height: 40px;
    background-color: #141c27;
    border-radius: 0px 15px 15px 0px;
    position: absolute;
    left: 0px;
}

#mm-0 > ul > li:nth-child(1) > a.tbs--active--link::after,
#mm-0 > ul > li:nth-child(13) > a.tbs--active--link::after,
#mm-0 > ul > li:nth-child(14) > a.tbs--active--link::after {
    top: 5px;
}

#mm-0 > ul > li:nth-child(2) > a.tbs--active--link::after,
#mm-0 > ul > li:nth-child(3) > a.tbs--active--link::after,
#mm-0 > ul > li:nth-child(4) > a.tbs--active--link::after,
#mm-0 > ul > li:nth-child(5) > a.tbs--active--link::after,
#mm-0 > ul > li:nth-child(12) > a.tbs--active--link::after {
    top: 10px;
}

#my-coins,
#public-group {
    /*pointer-events: none;*/
    color: #141c27;
}

#my-coins span,
#public-group span {
    padding-left: 17px;
    margin-top: 5px;
    position: absolute;
    color: #141c27;
}

.my-coins-active {
    color: #141c27 !important;
}

#menu-close-icon {
    background-color: transparent !important;
    color: #fff !important;
}

.mm-listitem:last-child svg {
    margin-left: 5px;
}

.mm-listitem:last-child > span {
    color: #141c27 !important;
}

/**
 * Off-Canvas Mmenu - End
 */

/* ########################################################################## */

/**
 * Glide Slider - Start
 */

.glide {
    overflow-y:hidden;
}

.glide__slides {
    width: 100%;
}

.glide__arrow {
    background-color: #C6CDDC;
    border-color: #C6CDDC !important;
    border-radius: 25px;
    padding: 10px !important;
}

.glide__arrow--left {
    left: 0rem;
}

.glide__arrow--right {
    right: 0rem;
}

/*.glide__slide {
    width: 125px !important;
}*/

/**
 * Glide Slider - End
 */

/* ########################################################################## */

/**
 * My Coins - Start
 */

#my-coins-info {
    margin-top: 20px;
    margin-left: 0px !important;
}

#my-coins-overview {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
}

#my-coins-overview > div:first-child > h2 {
    margin-bottom: 25px;
}

/**
 * My Coins - Start
 */

/* ########################################################################## */

/**
 * Action Menu - Start
 */

#action-menu {
    position: fixed;
    bottom: 85px;
    right: 25px;
    text-align: end;
    z-index: 100000;
}

.hidden-menu {
    display: none !important;
}

#all-menu-action img {
    width: 40px;
    height: 40px;
}

.menu-action-element {
    margin: 10px 5px;
}

.menu-action-element:nth-child(3) > span {
    margin-right: -25px;
}

.menu-action-element span {
    margin-right: 10px;
}

.menu-action-element span img {
    width: 50px !important;
    height: 40px !important;
}

.menu-action-element:last-child span img {
    width: 130px !important;
    height: 40px !important;
}

#main-menu-action img {
    width: 50px;
    height: 50px;
}

.action-menu-rotation {
    transform: rotate(-90deg);
}

/**
 * Action Menu - End
 */

/* ########################################################################## */

/**
 * Mobile App Banner - Start
 */

#mobile-app-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    /*margin-left: 0px;
    margin-right: 0px;*/
}

#mobile-app-banner.accepted {
    display: none !important
}

#mobile-app-banner > div {
    padding: 0px;
}

#mobile-app-banner > div > div {
    background-color: #374d6c;
    color: #fff;
    padding: 10px;
}

#mobile-app-banner > div > div > div:first-child {
    padding-bottom: 10px;
}

.tbs--mobile-app-banner {
    position: relative;
}

.tbs--hide--mobile-app-banner {
    position: absolute;
    right: 0;
    top: 0;
}

.tbs--mobile-app-banner a {
    text-decoration: underline;
    color: #fff;
}

/**
 * Mobile App Banner - Start
 */

/* ########################################################################## */

/**
 * Mobile App Dashboard - Start
 */

#mobile-app-dashboard a {
    text-decoration: underline;
    color: #141C27;
}

#mobile-app-dashboard {
    margin-left: 0 !important;
    margin-top: 20px;
}

/**
 * Mobile App Dashboard - Start
 */

/* ########################################################################## */

/**
 * SVG - Start
 */

#invitation svg {
    margin-left: 10px;
}

#invitation svg,
#invitation-overlay svg,
#notification-overlay svg,
#mainNavi svg,
#menu svg {
    width: 30px;
    height: 25px;
}

#invitation > div:nth-child(2) > div > div {
    margin: 0px -15px;
}

#invitation > div:nth-child(2) > div {
    border: none !important;
    border-radius: 0px !important;
    background-color: transparent !important;
    padding: 0px !important;
}

#invitation > div:nth-child(3) > div {
    padding-top: 20px;
}

.tbs--pin--messages--available img {
    width: 30px;
    height: 30px;
}

.tbs--pin--messages--available > .row > div:last-child > img {
    width: 15px;
    height: 15px;
}

#menu svg {
    margin-right: 20px;
}

#settings svg,
#settings img {
    width: 75px;
    height: 40px;
    cursor: pointer;
}

.tbs--sidebar--menu svg {
    width: 30px;
    height: 30px;
}

.tbs--sidebar--menu > li:last-child {
    margin-top: calc(30vh);
    /*position: absolute;*/
    /*bottom: 25px;*/
}

.tbs--main--data--contact svg {
    width: 45px;
    height: 45px;
}

.tbs--chat--friends--image img {
    width: 45px !important;
    height: 45px !important;
}

.tbs--quick--action svg {
    width: 20px;
    height: 20px;
}

#network .tbs--favourite--image {
    object-fit: initial !important;
    width: 25px !important;
    height: 25px !important;
}

/**
 * SVG - End
 */

/* ########################################################################## */

/**
 * Generally - Start
 */

hr {
    border-color: #141c27;
}

.alert-success {
    background-color: #374d6c !important;
    color: #fff !important;
}

.alert-success > div {
    font-size: 16px;
    margin-top: 5px;
}

img[src=""] {
    display: none;
}

input:focus,
textarea:focus {
    outline: none;
}

textarea {
    resize: vertical;
    min-height: 45px;
    border-radius: 10px;
    border: 2px solid #ccc;
}

.button--sign {
    margin: 100px 0px 10px 0px;
}

.w20-flex {
    flex: 0 0 20%;
    width: 20%;
    text-align: center;
    cursor: pointer;
}

.wProfile-flex {
    flex: 0 0 14.3%;
    width: 14.3%;
    text-align: center;
}

.wEditDelete-flex {
    flex: 0 0 12%;
    width: 12%;
    text-align: center;
}

.button--sign > button,
.button--sign > button:hover,
.button--sign > button:focus,
.button--sign > button:active,
.button--sign > button:link {
    border-radius: 10px;
    padding: 10px 20px !important;
    /*box-shadow: 0px 2px 2px #141c27;*/
    background-color: #374D6C;
    border-color: #374D6C;
}

label {
    margin: 10px 0px;
    font-weight: bold;
}

input {
    border-radius: 10px;
    border: 2px solid #ccc;
    width: 300px;
    padding: 10px 10px 10px 40px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input, textarea {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

input::-webkit-scrollbar,
textarea::-webkit-scrollbar{
    display: none;
}

.custom--image--one {
    margin-right: 24px !important;
}

.custom--image--two {
    margin-right: 34px !important;
}

.tbs--image {
    width: 30px;
    border-radius: 25px;
    object-fit: cover;
}

.tbs--header {
    /*background-image: linear-gradient(to bottom right, #C5E6EF, #1F9ABA);*/
    background-color: #374D6C;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 100px;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

.tbs--contact--data--feeds,
.tbs--contact--data--feeds--dca {
    font-size: 12px;
}

.tbs--contact--data--feeds--dca {
    border-top: 1px solid #ccc;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.tbs--contact--data--feeds--dca .tab-content {
    padding-top: 10px;
}

.tbs--contact--data--feeds img {
    width: 20px;
    height: 20px;
}

.tbs--contact--data--feeds span > span,
.tbs--contact--data--feeds span > a > span {
    padding: 10px 10px 10px 10px;
    color: #141C27 !important;
}

.tbs--disabled {
    pointer-events: none;
}

#new-invitation input[type="text"],
#new-invitation input[type="email"],
#update-post input[type="text"],
#new-post input[type="text"],
#post-feed input[type="text"],
#my-profile input[type="text"],
#my-profile input[type="email"],
#my-account input[type="text"],
#my-account input[type="email"],
#my-account input[type="password"],
#forgotPasswordForm input[type="text"],
#forgotPasswordForm input[type="email"],
#forgotPasswordForm input[type="password"],
#registerForm input[type="text"],
#registerForm input[type="password"],
#registerForm input[type="email"] {
    padding: 10px 10px 10px 15px !important;
}

input[type="text"]::placeholder {
    color: #141c27 !important;
}

input[type="text"]:-ms-input-placeholder {
    color: #141c27 !important;
}

input[type="text"]::-ms-input-placeholder {
    color: #141c27 !important;
}

input[type="password"]::placeholder {
    color: #141c27 !important;
}

input[type="password"]:-ms-input-placeholder {
    color: #141c27 !important;
}

input[type="password"]::-ms-input-placeholder {
    color: #141c27 !important;
}

input[type="email"]::placeholder {
    color: #141c27 !important;
}

input[type="email"]:-ms-input-placeholder {
    color: #141c27 !important;
}

input[type="email"]::-ms-input-placeholder {
    color: #141c27 !important;
}

textarea::placeholder {
    color: #141c27 !important;
}

textarea:-ms-input-placeholder {
    color: #141c27 !important;
}

textarea::-ms-input-placeholder {
    color: #141c27 !important;
}

#post-feed input[type="text"] {
    height: 50px;
    margin-left: 7px;
    border-right: 0px;
}

.tbs--user--profile--image {
    width: 98.75%;
    object-fit: cover;
}

.tbs--upload--image {
    /*background-color: #141c27;*/
    color: #fff !important;
    font-family: sans-serif;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px 20px !important;
    /*box-shadow: 0px 2px 2px #ccc;*/
    font-weight: 500 !important;
}

#new-post .tbs--upload--image {
    background-color: #141c27;
    color: #fff !important;
    font-family: sans-serif;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px 20px !important;
    /*box-shadow: 0px 2px 2px #ccc;*/
    font-weight: 500 !important;
}

#new-post #upload-image-new-post {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

#upload-image-new-post .tbs--upload--image {
    background-color: transparent !important;
    color: #fff !important;
    font-family: sans-serif;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px 20px !important;
    /*box-shadow: 0px 2px 2px #ccc;*/
    font-weight: 500 !important;
}

#upload-image-new-post.edit-post {
    position: absolute;
    bottom: 0;
    right: 0;
}

.new-image-upload {
    width: 100%;
}

.new-image-upload .tbs--default--image > .tbs--user--profile--image--upload {
    object-fit: cover !important;
}

.new-image-upload .tbs--default--image > .tbs--user--profile--image--default {
    /*object-fit: scale-down !important;*/
    object-fit: cover !important;
}

label[for="upload-image"] > div {
    border: 2px solid #ccc;
    border-radius: 10px;
}

label[for="upload-image"] > .tbs--upload--image--new--post {
    border: 1px solid #ccc !important;
    border-radius: 10px;
}

label[for="upload-image"] > div img {
    border-radius: 10px;
    cursor: pointer;
}

.tbs--default--image {
    text-align: center;
    border: 1px solid #ccc;
    width: 98.75%;
    position: relative;
    object-fit: cover;
}

.tbs--default--image > img {
    width: 100%;
    object-fit: cover !important;
    object-fit: scale-down !important;
    max-height: calc(50vh) !important;
}

.is-not-visible {
    display: none;
}

.is-visible {
    display: block;
}

/**
* Generally - End
*/

/**
 * ##########################################################################
 * ##########################################################################
 */

/**
 * Breakpoints (Media Queries)
 */

@media screen and (min-width: 768px){
    #sidebar {
        position: fixed;
        overflow-y: auto;
        height: 90%;
    }
    #mainContent {
        position: absolute;
        right: 0;
    }
    #new-invitation .tbs--headline--invitation, #notification .tbs--headline--notification {
        color: #141C27;
        margin: 15px 0px 20px 0px !important;
        font-size: 24px;
        font-weight: 600;
        z-index: 1;
        background-color: #FAFAFA;
        padding-right: 10px;
        position: absolute;
        left: 5px;
    }

    #invitation .tbs--headline--invitation,
    #new-invitation .tbs--headline--invitation
    /*#notification .tbs--headline--notification*/ {
        padding-left: -15px;
        margin: 15px 0px 20px -15px !important;
    }
    #new-invitation .tbs--divider--title--invitation {
        right: -10px !important;
        z-index: 0;
    }
    .tbs--divider--title--invitation {
        right: 0px !important;
        z-index: 0;
    }
}

@media screen and (min-width: 768px) and (max-width: 950px){
    /*.tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 67%;
        width: 100%;
        border: 1px solid #ccc !important;
    }*/
}

@media screen and (min-width: 768px){
    #login-register-back {
        margin-left: 0px;
    }
}

@media screen and (max-width: 767px){
    #loginForm, #forgotPasswordForm {
        /*background-image: url('./assets/images/login.5ad69cdb.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-size: auto;*/
        background-color: #fff;
    }
    #registerForm {
        /*background-image: url('./assets/images/signup.d73ccc0b.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-size: auto;*/
        background-color: #fff;
    }
    #loginForm input,
    #forgotPasswordForm input,
    #registerForm input {
        border: 2px solid #ccc;
    }
    #loginForm p,
    #forgotPasswordForm p,
    #registerForm p {
        color: #141C27;
    }
    .tbs--main--block--bottom {
        margin-top: 50px;
        bottom: 0;
    }
    #errorNotLogin .tbs--form--block {
        margin: auto;
        max-width: 280px;
    }
    .tbs--form--block {
        position: relative;
        top: 0;
        left: 0;
        margin: 0;
        transform: none;
    }
    .tbs--form--complete {
        width: 300px;
        margin: auto;
        color: #fff;
    }
    #new-invitation > div:nth-child(2) {
        padding: 0;
    }
    .tbs--form--complete--invitation,
    .tbs--form--complete--post,
    .tbs--form--complete--profile {
        color: #fff;
    }
    .tbs--form--complete--invitation input,
    .tbs--form--complete--post input,
    .tbs--form--complete--profile input {
        border: 2px solid #ccc;
    }
    .tbs--main--title {
        font-size: 32px;
        color: #141C27;
        margin-top: 30px;
    }

    .tbs--main--subtitle {
        color: #141C27;
    }
    .tbs--form--title {
        font-size: 24px !important;
        margin: 100px 0px 25px 0px;
        color: #141C27;
    }
    .tbs--form--title--register {
        margin: 75px 0px 15px 0px !important;
    }
    .button--sign {
        margin: 30px 0px 10px 0px;
    }
    .link--native--style {
        text-decoration: none;
        color: #141C27;
    }
    .link--native--style:hover,
    .link--native--style:focus,
    .link--native--style:active,
    .link--native--style:link  {
        text-decoration: none;
        color: #141C27;
    }
    label {
        color: #141c27;
    }
    #communityMute label {
        color: #000 !important;
    }
    input {
        border-radius: 10px;
        border: 0px solid #ccc;
        width: 300px;
        padding: 10px 10px 10px 40px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    nav {
        box-shadow: none !important;
        background-color: #141c27 !important;
    }
    #mainContent,
    #dashboardContent {
        padding-left: 0px;
        padding-right: 0px;
    }
    #dashboardContent > div {
        margin-left: 0px;
        margin-right: 0px;
    }
    #trusted,
    #feature,
    #feeds {
        margin-top: 0px !important;
    }
    #trusted > div,
    #feature > div {
        padding-left: 0px;
        padding-right: 0px;
    }
    #trusted > div > .tbs--container > .row:nth-child(2) > div {
        margin-bottom: 20px;
    }
    #feeds > div {
        padding-right: 0px;
        padding-left: 0px;
    }
    .tbs--container {
        border: 0px solid #ccc;
        border-radius: 0px;
        padding: 0px;
        /*margin-top: 17px;*/
    }
    .tbs--contact--image {
        margin-left: 0px;
    }
    .tbs--contact--trusted,
    .tbs--contact--feature {
        ont-size: 13px;
        padding-top: 5px;
        margin-left: 0px;
    }
    .tbs--contact--trusted--position,
    .tbs--contact--feature--position {
        color: #ccc;
        margin-left: 0px;
    }
    .container {
        max-width: 100% !important;
    }
    #own--feeds {
        margin-top: 30px;
        margin-right: -30px;
        margin-left: -30px;
    }
    #other--profile--feeds {
        margin-top: 30px;
        margin-right: -30px;
        margin-left: -30px;
    }
    #scroll {
        margin-right: 2.5px;
        margin-left: 2.5px;
    }
    .tbs--container--feedlist--item {
        border: 1px solid #ccc;
        border-radius: 0px;
        padding: 10px 10px;
        overflow: hidden;
        background-color: #fff;
        margin-bottom: 20px;
    }
    .tbs--container--favourites--item {
        border: 1px solid #ccc;
        border-radius: 0px;
        padding: 10px 0px;
        overflow: hidden;
        background-color: #fff;
        margin-bottom: 20px;
    }
    .tbs--container--favourites--item > .row:first-child {
        margin-right: 10px !important;
    }
    .tbs--container--favourites--item > .row:not(:first-child) {
        margin-right: 20px !important;
    }
    .tbs--container--favourites--item > .row:not(:first-child) > div:first-child {
        padding-right: 0px !important;
    }
    .tbs--container--favourites--item > .row:not(:first-child) > div:not(:first-child) {
        padding-left: 15px !important;
        padding-right: 0px !important;
    }
    .tbs--container--post--item {
        border: 1px solid #ccc;
        border-radius: 0px;
        padding: 10px 0px;
        overflow: hidden;
        background-color: #fff;
        margin-bottom: 20px;
    }
    .tbs--container--post--item > .row:first-child {
        margin-right: 10px !important;
    }
    .tbs--container--post--item > .row:not(:first-child) {
        margin-right: 20px !important;
    }
    .tbs--container--post--item > .row:not(:first-child) > div:first-child {
        padding-right: 0px !important;
    }
    .tbs--container--post--item > .row:not(:first-child) > div:not(:first-child) {
        padding-left: 0px !important;
    }
    .tbs--contact--data--feeds--dca nav {
        box-shadow: none !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        background-color: transparent !important;
    }
    .tbs--container--favourites > .row {
        background-color: transparent;
        color: #141C27;
        /*border: 1px solid #ccc;
        border-radius: 0;*/
        margin: 0px -15px 10px -15px;
    }
    .tbs--contact--discussion--profiles > .row:first-child > div:nth-child(2),
    .tbs--contact--discussion--reply--profiles > .row:first-child > div:nth-child(2) {
        padding-right: 0 !important;
        padding-left: 0px !important;
    }
    #invitation-overlay .dropdown-menu-right {
        right: -130.5px;
        left: auto;
        top: 60px;
    }
    #invitation-overlay .dropdown-menu:before {
        right: 138.5px;
    }
    #notification-overlay .dropdown-menu-right {
        right: -70.5px;
        left: auto;
        top: 63px;
    }
    #notification-overlay .dropdown-menu:before {
        right: 78.5px;
    }
    #notification {
        margin-top: 0px !important;
    }
    #notification .row.dropdown--element,
    #notification a .row.dropdown--element {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
    #invitation > div:nth-child(2),
    #notification > div:first-child {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    #notification .container > .row:first-child,
    #notification .container-fluid > .row:first-child {
        border-bottom: 1px solid;
        margin: 0px 0px 0px 0px;
    }
    #invitation .tbs--headline--invitation,
    #new-invitation .tbs--headline--invitation,
    #notification .tbs--headline--notification {
        color: #141C27;
        margin: 15px 0px 20px 0px !important;
        font-size: 24px;
        font-weight: 600;
        z-index: 1;
        background-color: #FAFAFA;
        padding-left: 15px;
    }
    #new-invitation .tbs--headline--invitation {
        padding-left: 0px !important;
    }
    /*#invitation .tbs--headline--invitation,
    #new-invitation .tbs--headline--invitation,
    #notification .tbs--headline--notification {
        color: #141C27;
        margin: 15px 0px 20px 0px !important;
        font-size: 24px;
        font-weight: 600;
        z-index: 1;
        background-color: #FAFAFA;
        padding-left: 15px;
    }*/
    #notification .tbs--headline--notification {
        padding-left: 0px !important;
    }
    #new-invitation .container {
        padding: 0px 0px 15px 15px;
    }
    #notification .container > .row:first-child,
    #notification .container-fluid > .row:first-child,
    #settings .container > .row:first-child,
    #settings .container-fluid > .row:first-child  {
        /*border-bottom: 1px solid #ccc;*/
        margin: 30px 0px 0px 0px;
    }
    #invitation .container > .row:nth-child(2),
    #invitation .container-fluid > .row:nth-child(2) {
        border-bottom: 1px solid #ccc;
        margin: 0px 15px 0px 15px;
    }
    #invitation .container > .nav,
    #invitation .container-fluid > .nav {
        margin-left: 0px !important;
        margin-right: 15px !important;
        box-shadow: none !important;
        background-color: transparent !important;
    }
    #invitation,
    #new-invitation {
        margin-top: 0;
    }
    #new-invitation .container,
    #new-invitation .container-fluid{
        padding-left: 15px;
    }
    #invitation > div:first-child {
        padding: 0;
    }
    #invitation .container,
    #invitation .container-fluid {
        padding-bottom: 30px;
    }
    #invitation .row.dropdown--element,
    #invitation a .row.dropdown--element {
        margin-left: 15px !important;
        margin-right: 15px !important;
        margin-bottom: 25px !important;
    }
    #invitation .invitation--element,
    #notification .notification--element {
        background-color: #FBFBFB;
        border: 2px solid #F9F9F9;
        border-radius: 10px;
        max-width: 90%;
        margin: 0px auto 7px !important;
        padding-bottom: 5px;
    }
    #invitation-overlay .dropdown--element,
    #notification-overlay .dropdown--element {
        background-color: #FBFBFB;
        border: 2px solid #F9F9F9;
        border-radius: 10px;
    }
    #settings {
        margin-top: 0px;
    }
    #settings > div:first-child {
        padding: 0;
    }
    #settings .tbs--headline--settings {
        margin: 0px;
    }
    #settings .container > .row:not(:first-child),
    #settings .container-fluid > .row:not(:first-child) {
        border-bottom: 1px solid #ccc;
        margin: 20px 15px 0px 15px;
        padding-bottom: 10px;
    }
    #settings .container > .row:last-child,
    #settings .container-fluid > .row:last-child {
        border-bottom: none;
        margin: 20px 15px 0px 15px;
        padding-bottom: 10px;
    }
    #menu-other-profile .dropdown-menu-right,
    #menu-friends .dropdown-menu-right {
        right: -26.5px !important;
        left: auto !important;
        top: 50px !important;
        position: absolute;
        border: 1px solid #ccc;
        transform: none !important;
    }
    .tbs--container--tips {
        margin: 25px 0px;
    }
    #feed-list > #tip-feedlist > .tbs--container--tips {
        margin: 25px 0px 0px 0px;
    }
    #profile--submenu {
        margin-top: 0px;
    }
    #tip-trusted .tbs--container--tips,
    #tip-feature .tbs--container--tips {
        margin: 25px 0px 0px 0px;
    }
    #feature > div:first-child,
    #trusted > div:first-child {
        padding: 0px 15px;
    }
    #trusted .tbs--headline,
    #feature .tbs--headline {
        padding-left: 15px !important;
    }
    #feedback,
    #bug-report {
        margin-top: 0px;
    }
    #feedback > div,
    #bug-report > div {
        padding: 0px;
    }
    #feedback > div:first-child,
    #bug-report > div:first-child {
        margin-top: 10px;
    }
    #feedback .container > .row:first-child,
    #feedback .container-fluid > .row:first-child,
    #bug-report .container > .row:first-child,
    #bug-report .container-fluid > .row:first-child {
        /*border-bottom: 1px solid #ccc;
        margin: 0px 0px 15px -20px;
        background-color: #141c27;*/
        padding-left: 35px;
        font-size: 18px;
        /*font-weight: 600;*/
        color: #141c27;
    }
    #feedback .container > .row:not(:first-child),
    #feedback .container-fluid > .row:not(:first-child),
    #bug-report .container > .row:not(:first-child),
    #bug-report .container-fluid > .row:not(:first-child) {
        padding-left: 15px;
    }
    #my-profile {
        margin-top: 0;
    }
    #my-profile > div {
        padding: 0px;
    }
    #my-profile > div:nth-child(2) > .container {
        padding-left: 15px;
    }
    #my-profile .tbs--headline--update-post {
        margin: 25px 0px 20px 15px !important;
    }
    #my-profile form .row > div:first-child div:not(:first-child) label,
    #my-profile form .row > div:last-child div label {
        margin-top: 20px;
    }
    #my-account {
        margin-top: 0;
    }
    #my-account > div {
        padding: 0px;
    }
    #my-account > div > .container .tbs--form--complete--profile {
        padding-left: 15px;
    }
    #my-account .tbs--headline--update-profile {
        margin: 25px 0px 20px 0px !important;
    }
    #my-account .tbs--container > .row:first-child {
        /*border-bottom: 1px solid #ccc;
        margin-right: 15px !important;
        margin-left: 15px;*/
        border-bottom: 1px solid #ccc;
        margin-right: 0 !important;
        margin-left: -20px;
        background-color: #374d6c;
        padding-left: 35px;
    }
    #my-account .tbs--container > .row:nth-child(4) {
        border-top: 1px solid #ccc;
        margin-right: 15px !important;
        margin-left: 15px;
        padding-top: 15px;
        padding-left: -15px;
    }
    #my-account > div:nth-child(1) > div > div:nth-child(2),
    #my-account > div:nth-child(1) > div > div:nth-child(3) {
        padding-left: 15px;
    }
    #update-post {
        margin-top: 0;
    }
    #update-post > div {
        padding: 0px;
    }
    #update-post .tbs--headline--update-user {
        margin: 25px 0px 20px 0px !important;
    }
    #update-post .tbs--headline--update-post {
        margin: 25px 0px 20px 15px !important;
    }
    #update-post .tbs--form--complete--post {
        margin-left: 0px;
    }
    #new-post {
        margin-top: 0px !important;
    }
    #new-post .tbs--container > .row:nth-child(1) {
        margin-left: 0 !important;
    }
    #new-post > div:nth-child(2),
    #new-post > .tbs--container {
        padding: 0px;
    }
    #new-post .tbs--headline--create-post {
        margin: 25px 0px 20px 15px !important;
    }
    #new-post .tbs--form--complete--post {
        margin-left: 0px;
    }
    #report-user {
        margin-top: 0px;
    }
    #report-user > div:first-child {
        padding: 0px;
    }
    #report-user .container,
    #report-user .container-fluid {
        padding-left: 15px;
    }
    #report-post {
        margin-top: 0px;
    }
    #report-post > div:first-child {
        padding: 0px;
    }
    #report-post .container,
    #report-post .container-fluid {
        padding-left: 15px;
        padding-right: 10px;
    }
    #groups,
    #chats {
        margin-right: 0 !important;
        margin-top: -17px !important;
        padding: 0;
    }
    #groups > div,
    #chats > div {
        padding: 0;
    }
    #groups > div:first-child,
    #chats > div:first-child {
        padding: 0px;
        border-radius: 10px;
    }
    #groups .container-fluid > .row:first-child,
    #chats .container-fluid > .row:first-child {
        margin-left: 0;
        margin-top: 0;
        border-radius: 0px !important;
    }
    #groups .container-fluid .tbs--headline--chats,
    #chats .container-fluid .tbs--headline--chats {
        margin: 20px 0px 20px 0px !important;
    }
    #chats .container-fluid .tbs--headline--chats--single {
        padding: 25px 0px !important;
    }
    #groups .row,
    #chats .row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
    #group-contacts .row:not(:first-child) > div > a > .row,
    #chat-contacts .row:not(:first-child) > div > a > .row {
        margin: 15px 0px 10px;
    }
    #groups-contacts > .container-fluid,
    #chats-contacts > .container-fluid {
        border-radius: 0px !important;
    }
    #groups > div:first-child > .container-fluid,
    #chats > div:first-child > .container-fluid {
        border-radius: 10px !important;
    }
    #groups .container-fluid .row:not(:first-child),
    #chats .container-fluid .row:not(:first-child) {
        margin-left: 0px !important;
    }
    #groups .container-fluid, #chats .container-fluid {
        margin-top: 17px;
        border-radius: 0;
    }
    #chat-new-message.tbs-group-mobile,
    #group-new-message.tbs-group-mobile {
        position: fixed;
        bottom: 60px;
        width: 100%;
    }
    .tbs--chat--details {
        width: 25px;
        height: 25px;
        background-color: #fff;
        border: 1px solid #141c27;
        border-radius: 25px;
        display: none;
    }
    .tbs--chat--details-without {
        width: 25px;
        height: 25px;
        background-color: #141c27;
        border-radius: 25px;
        display: none;
    }
    .tbs--additional--menu--chats {
        top: 6px !important;
        right: 0 !important;
    }
    #tbs-detail {
        margin-top: 0;
    }
    #tbs-detail > div {
        padding: 0;
    }
    .tbs--logo--description {
        padding-left: 30px;
    }
    .tbs--block--description {
        padding: 0px 30px;
    }
    #community {
        margin-top: 0px;
        border-radius: 0px;
    }
    #community > div:nth-child(2){
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    #report-chat-conversation,
    #report-group-conversation {
        margin-top: 0 !important;
    }
    #report-chat-conversation > div,
    #report-group-conversation > div {
        padding: 0;
    }
    #report-chat-conversation > div:first-child,
    #report-group-conversation > div:first-child {
        margin-top: 15px;
    }
    #report-chat-conversation > div > .container-fluid,
    #report-group-conversation > div > .container-fluid {
        padding: 0px 10px;
    }
    #group-publication div,
    #chat-publication div {
        padding: 0;
    }
    #group-publication div:not(:first-child) .container-fluid,
    #chat-publication div:not(:first-child) .container-fluid {
        padding-left: 10px;
    }
    #group-publication div .container-fluid,
    #chat-publication div .container-fluid {
        border: 1px solid #ccc;
    }
    .tbs--post--element {
        margin: 25px 0px;
    }
    #network > div {
        padding: 0;
    }
    #network .tbs--network--slider > .switch {
        margin-right: 40px;
    }
    #network .tbs--headline {
        color: #fff;
        font-weight: 500;
        margin: 25px 0px;
    }
    #network-contacts > div:first-child {
        padding: 0;
    }
    #network-contacts > div:first-child > .container-fluid {
        padding-left: 15px;
    }
    .tbs--comment--default--discussion > .tbs--discussion--comment--detail--block {
        width: 85%;
        margin-left: 10px;
    }
    .tbs--comment--default--discussion--reply > .tbs--discussion--comment--detail--block {
        width: 84%;
        margin-left: 10px;
    }
    .tbs--discussion--comment--reply--awards {
        margin-left: 0px !important;
    }
    .tbs--comment--default--discussion .tbs--line--form > div:first-child > div:first-child > form > div:first-child > div:first-child {
        max-width: 70% !important;
    }
    .tbs--container--network--blocks {
        padding: 0px 15px !important;
        border: 0px solid #ccc !important;
        border-radius: 0px !important;
    }
    .tbs--headline--network {
        margin: 0px -15px 10px -15px;
    }
    .tbs--container--network--blocks--custom .no-data-image {
        width: 50px !important;
        height: 50px !important;
        object-fit: scale-down !important;
    }
    .tbs--container--network > .row > div:nth-child(2) > div {
        text-align: left !important;
        margin-left: 0px !important;
    }
    /*.tbs--container--network--blocks > .row > div {
        padding-left: 0px;
        padding-right: 0px;
    }*/
    .tbs--container--network--blocks--two > .row > div {
        /*border: 1px solid #ccc;*/
        padding: 35px 0px;
        /*margin-bottom: 15px;*/
    }
    .tbs--container--network--blocks--custom > .row > div:nth-child(2) {
        margin-left: 0px;
    }
    .tbs--container--network--blocks--custom img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
    }
    .tbs--container--network--blocks--custom > .row > .tbs--post--detail--block > a > div:nth-child(1) {
        margin-right: 0px;
    }

    .tbs--container--network--blocks--custom > .row > .tbs--post--detail--block {
        margin-left: 0px;
    }

    .tbs--container--network--blocks--custom > .row > .tbs--post--detail--block > a > div {
        margin-right: 0px;
    }
    #network .tbs--network--post--bookmark {
        margin-left: -30px !important;
        margin-top: -7.5px;
    }
    #group-contacts > div > div:nth-child(1) > div > div > span {
        position: absolute;
        background-color: #FBFBFE;
        z-index: 1;
        top: 5px;
    }
    #group-contacts > div > div:nth-child(1) > div > div > span:last-child {
        right: 23px;
    }
    #my-coins-info {
        margin-top: 0px !important;
        margin-left: 0px !important;
    }
    #my-coins-overview {
        padding: 20px;
    }
    .post-user-data > .tbs--contact--data--feedlist {
        padding-left: 15px !important;
    }
    #my-coins span {
        padding-left: 17px;
        margin-top: 5px;
        position: absolute;
        color: #fff;
    }
    .tbs--headline--feeds {
        margin: 25px 0px 20px 30px;
        padding-left: 15px;
    }
    .tbs--container--feedlist > .row:first-child {
        background-color: #141c27;
        color: #fff;
        border: 1px solid #ccc;
        border-radius: 0;
        margin: 10px -15px;
        padding-left: 15px;
    }
    #profile #tbs--create--new--post .tbs--container--feedlist > .row:first-child {
        color: #141c27;
        background-color: transparent;
        margin: 0px -30px 10px -30px;
        border: none;
        border-radius: 0px;
    }
    #profile #profile--submenu .tbs--container--feedlist > .row,
    #network--submenu .tbs--container--feedlist > .row {
        background-color: transparent;
        color: #141c27;
        border: none;
        border-radius: 0px;
        padding-left: 0;
    }
    #network--submenu .tbs--container--feedlist > .row .tbs--headline {
        padding-left: 10px;
    }
    #network--submenu .tbs--container--feedlist > .row .tbs--divider--title {
        right: 10px !important;
        z-index: 0;
    }
    #network-contacts > div:nth-child(3) {
        padding-left: 0;
        padding-right: 0;
    }
    .slider-main-switch-container {
        position: absolute;
        right: 15px;
        bottom: 235px;
        z-index: 20;
    }
    .slider-main-switch-container-active {
        position: absolute;
        right: 15px;
        bottom: 238px;
        z-index: 20;
    }
    #feed-list {
        margin: -10px auto 30px !important;
        max-width: 1050px;
    }
    #network-contacts,
    #favourites {
        margin: 1px auto 30px !important;
        max-width: 1050px;
    }
    #new-post,
    #report-user,
    #report-post,
    #invitation,
    #new-invitation,
    #feedback,
    #bug-report,
    #my-profile,
    #my-account {
        margin: 1px auto 30px !important;
        max-width: 1050px;
    }
    #notification{
        margin: -30px auto 30px !important;
        max-width: 1050px;
    }
    #settings {
        margin: 15px auto 30px !important;
        max-width: 1050px;
    }
    #network > div > div.tbs--container--network.container-fluid > div > div:nth-child(1) {
        background-color: #374d6c;
        border-radius: 0px;
    }
    #network-contacts > div:nth-child(2) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    #group-publication,
    #chat-publication {
        margin-top: 0px;
    }
    #community > div > div > .row:nth-child(1) {
        background-color: #374d6c;
        margin-left: -30px;
        margin-right: -30px;
        border-radius: 0px;
        /*margin-bottom: 15px;*/
    }
    #new-comment-postfeed input,
    #new-comment-postfeed textarea {
        flex: 0 0 93%;
    }
    #new-comment-postfeed > div {
        flex: 0 0 7%;
    }
    #chat-new-message.tbs-group-mobile-nothing {
        position: fixed;
        /*bottom: 120px !important;*/
        width: 100% !important;
        margin: auto;
    }
    #network {
        margin-top: 0px !important;
    }
    .tbs--times--highlight {
        margin: 10px 0px 25px 0px;
        text-align: left;
    }
    .create-new-community {
        margin-top: -10px;
    }
    .create-new-community .tbs--container > .row:first-child {
         background-color: #374d6c;
         color: #fff;
         border: 1px solid #ccc;
         margin: 0px -30px 10px;
         padding-left: 15px;
    }
    .create-new-community .tbs--container > .row:first-child .tbs--headline {
        font-size: 24px;
    }
    /*#chat-new-message textarea,
    #group-new-message textarea {
        width: 100%;
    }*/
    #invitation svg,
    #invitation-overlay svg,
    #notification-overlay svg,
    #mainNavi svg,
    #menu svg {
        width: 20px;
        height: 20px;
    }
    #post-feed .tbs--contact--data--post--favourite img {
        width: 125px;
        /*margin-right: 7px !important;*/
    }
    #my-awards-overview .tbs--awards--user--highlights > span {
        right: 85px;
    }
    #tbs--create--new--post {
        padding-left: 0px !important;
    }
    /*.tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 70%;
        width: 100%;
        border: 1px solid #ccc !important;
    }*/
    #feed-list .tbs--new--title {
        margin-top: 30px;
    }
    #settings > div:nth-child(2) {
        padding-left: 0;
        padding-right: 0;
    }
    #settings > div:nth-child(2) > .container,
    #settings > div:nth-child(2) > .container-fluid {
        padding-left: 15px;
    }
    #feedback > div:nth-child(2) > div > div:nth-child(1) > div,
    #bug-report > div:nth-child(2) > div > div:nth-child(1) > div {
        padding-left: 0px !important;
    }
    #chat-contacts > .container-fluid > .row > div,
    #group-contacts > .container-fluid > .row > div {
        padding: 0 !important;
        margin-top: 10px !important;
    }
    #chat-contacts > .container-fluid > .row > div {
        margin-right: 25px !important;
    }
    #chat-contacts > div > div:nth-child(1) > div.tbs--divider--title.tbs--additional--menu--chats {
        margin-right: 10px !important;
        z-index: 0;
    }
    .tbs--container--network--blocks--two {
        color: #141c27;
        font-weight: bold;
        padding: 0;
        margin: 0;
        border: none;
        border-radius: 0px;
    }
    #notification > div:nth-child(2) {
        padding: 0;
    }
    .tbs--divider--title--notification {
        top: 50px;
        margin-right: 20px;
        width: 85%;
        z-index: 0;
    }
    #report-user > div:nth-child(1),
    #report-post > div:nth-child(1) {
        margin-top: 10px !important;
    }
    #report-user > div:nth-child(2),
    #report-post > div:nth-child(2) {
        padding: 0 !important;
    }
    /*#feedback .tbs--headline--feedback,
    #bug-report .tbs--headline--bug,*/
    #report-user .tbs--headline--bug,
    #report-post .tbs--headline--report {
        padding-left: 15px;
    }
    #invitation > div:nth-child(3) > div > div.row > div > p {
        color: #000;
        padding: 0px 15px;
    }
    .tbs--divider--title--invitation {
        top: 20px;
        width: 90%;
        margin-right: 35px;
        z-index: 0;
    }
    #chat-contacts .tbs--headline span,
    #group-contacts .tbs--headline span:first-child {
        padding-left: 15px;
    }
    .logo--headline {
        padding-left: 9px;
    }
    .tbs--contact--data--feedlist--image {
        width: 100%;
        height: 50%;
        margin-top: 20px;
        object-fit: cover;
    }
    #feed-list .tbs--contact--data--feedlist--image,
    #other--profile .tbs--contact--data--feedlist--image,
    #other--profile--feeds .tbs--contact--data--feedlist--image {
        padding-left: 15px;
    }
    #mobile-app-dashboard {
        margin-top: 0px;
    }
    #bug-report > div:nth-child(2) > .tbs--container,
    #feedback > div:nth-child(2) > .tbs--container {
        padding: 0;
    }
    #feedback form textarea, #bug-report form textarea {
        width: 97%;
    }
    #tip-chats .container-fluid,
    #tip-groups .container-fluid {
        margin-bottom: 0px !important;
    }
    .tbs--contact--data--favourites--image > img {
        width: 100%;
        min-height: 50%;
        border-radius: 10px;
        object-fit: cover;
    }
    .tbs--audio--feed {
        position: absolute;
        top: 35%;
        left: 47.5%;
        width: 50px;
        height: 50px;
        transform: translate(0px, 25px);
        display: block;
    }

    #network .tbs--container--network--blocks {
        height: calc(75vh);
    }
    .tbs--no--post--network {
        font-weight: 500;
        font-size: 24px;
        margin-top: 00px;
    }
    /**
     * Offcanvas Mmenu
     */
    /*.mm-menu * {
        background-color: #141c27 !important;
        color: #fff;
    }*/
    .mm-listview > li:nth-child(3) {
        display: flex;
    }
    /*#mm-0 > ul > li > a {
        color: #fff !important;
    }
    .mm-listitem {
        border-color: #fff;
    }*/
    /**
     * Custom Offcanvas Menu
     */

    #offcanvas-menu {
        height: 100%;
        width: 280px;
        position: fixed;
        z-index: 1400;
        top: 0;
        right: -280px;
        background-color: #F8F9FA !important;
        overflow-x: hidden;
        transition: 0.5s;
        margin-top: 71px;
        border: 1px solid #ccc;
        box-shadow: 0px 0px 10px #ccc;
    }

    #offcanvas-menu > div {
        padding-bottom: 100px;
    }

    .width-menu{
        right: 0 !important;
    }

    #public-groups {
        margin-left: -15px !important;
    }
    #public-groups #group-contacts > .tbs--container {
        border-radius: 0 !important;
        border: 1px solid #ccc;
    }
}

@media screen and (max-width: 510px){
    #memberSearchOnly {
        width: 260px !important;
        border: 2px solid #ccc !important;
        padding-left: 10px !important;
    }

    #adminSearchOnly {
        width: 260px !important;
        border: 2px solid #ccc !important;
        padding-left: 10px !important;
    }
}

@media screen and (max-width: 575px){
    .modal-sm {
        width: 80%;
        margin: auto;
    }
    /*.tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 58%;
        width: 100%;
        border: 1px solid #ccc !important;
    }*/
}

@media screen and (min-width: 526px) and (max-width: 575px){
    #my-awards-overview .tbs--awards--user--highlights > span {
        right: 95px;
    }
}

@media screen and (min-width: 476px) and (max-width: 525px){
    #my-awards-overview .tbs--awards--user--highlights > span {
        right: 85px;
    }
}

@media screen and (min-width: 421px) and (max-width: 475px){
    #my-awards-overview .tbs--awards--user--highlights > span {
        right: 70px;
    }
}

@media screen and (min-width: 361px) and (max-width: 420px){
    #my-awards-overview .tbs--awards--user--highlights > span {
        right: 60px;
    }
}

@media screen and (min-width: 301px) and (max-width: 360px){
    #my-awards-overview .tbs--awards--user--highlights > span {
        right: 45px;
    }
}

@media screen and (max-width: 300px){
    #my-awards-overview .tbs--awards--user--highlights > span {
        right: 30px;
    }
    /*.tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 60%;
        width: 100%;
        border: 1px solid #ccc !important;
    }*/
    /*.tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 25% !important;
        width: 100%;
        border: 1px solid #ccc !important;
    }*/
}

@media screen and (max-width: 846px){
    .sidebar--menu--image {
        padding: 0px 0px 0px 0px;
    }
    .sidebar--menu--image--custom {
        padding: 0px 1px 0px 0px;
    }
    #sidebar span {
        padding-left: 10px;
    }
}

@media screen and (min-width: 847px){
    .sidebar--menu--image {
        padding: 0px 0px 0px 0px;
        /* margin-left: 30px; */
    }
    .sidebar--menu--image--custom {
        padding: 0px 1px 0px 0px;
        margin-left: 30px;
    }
    #sidebar span {
        padding-left: 10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .tbs--main--block--two {
        position: relative;
        top: 0;
        left: 0;
        margin: 0;
        transform: translate(0%, 0%);
        text-align: center;
        margin: 20px 0px;
    }
    .tbs--main--block .tbs--main--subtitle {
        margin-top: 250px;
        font-size: 20px;
    }
    .tbs--form--block {
        position: absolute;
        top: 40%;
        left: 50%;
        margin-top: 75px;
        transform: translate(-50%, -50%);
    }
    .tbs--form--title {
        font-size: 24px;
        /*margin-bottom: 20px;*/
    }
    .button--sign {
        margin-top: 50px;
    }
    #my-profile form .row > div:first-child div:not(:first-child) label,
    #my-profile form .row > div:last-child div label {
        margin-top: 20px;
    }
    #group-contacts > div > div:nth-child(1) > div > div > span {
        position: absolute;
        background-color: #FBFBFE;
        z-index: 1;
        top: 5px;
    }
    #group-contacts > div > div:nth-child(1) > div > div > span:last-child {
        right: 23px;
    }
    #group-contacts .container-fluid,
    #chats-contacts .container-fluid,
    #group-contacts .container-fluid > .row:first-child,
    #chats-contacts .container-fluid > .row:first-child {
        border-radius: 0;
    }
    #chat-contacts > .tbs--container,
    #group-contacts > .tbs--container {
        border-radius: 10px !important;
    }
    #my-awards-overview .tbs--awards--user--highlights > span {
        right: 45px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px){
    #group-contacts > div > div:nth-child(1) > div > div > span {
        position: absolute;
        background-color: #FBFBFE;
        z-index: 1;
        top: 5px;
    }
    #group-contacts > div > div:nth-child(1) > div > div > span:last-child {
        right: -5px;
    }
}

@media screen and (max-width: 1036px){
    .tbs--main--data--contact--feature div:first-child,
    .tbs--main--data--contact--introduce div:first-child,
    .tbs--main--data--contact--chat div:first-child {
        display: block;
        margin: auto;
        margin-bottom: 10px;
        max-width: 62px;
    }
}

/*@media screen and (max-width: 395px){
    .tbs--contact--details .tbs--contact--data--post,
    .tbs--contact--details .tbs--contact--data--feedlist {
        max-width: 200px;
    }
    .tbs--container--post--image,
    .tbs--container--feedlist--image {
        margin-top: -60px !important;
    }
}*/

@media screen and (max-width: 270px){
    .tbs--contact--details .tbs--contact--data--post,
    .tbs--contact--details .tbs--contact--data--feedlist {
        max-width: 110px;
    }
    #new-comment-postfeed input,
    #new-comment-postfeed textarea {
        flex: 0 0 75%;
    }
    #new-comment-postfeed > div {
        flex: 0 0 25%;
    }
}

@media screen and (min-width: 271px) and (max-width: 300px){
    .tbs--contact--details .tbs--contact--data--post,
    .tbs--contact--details .tbs--contact--data--feedlist {
        max-width: 130px;
    }
    .tbs--main--data--contact--mutual--featured--user a {
        display: block;
        margin-bottom: 20px;
    }
    #new-comment-postfeed input,
    #new-comment-postfeed textarea {
        flex: 0 0 75%;
    }
    #new-comment-postfeed > div {
        flex: 0 0 25%;
    }
}

@media screen and (max-width: 370px){
    .tbs--network--main--header > .row > div:first-child,
    .tbs--network--main--header > .row > div:nth-child(2) {
        flex: 0 0 100%;
        max-width: 100%;
    }
    #network .tbs--container--network--switch {
        margin-top: 0;
    }
    #logout-modal .btn,
    #awards-info .btn {
        width: 75px;
    }

    #memberSearch {
        width: 180px !important;
        border: 2px solid #ccc !important;
        padding-left: 10px !important;
    }

    #memberSearchOnly {
        width: 180px !important;
        border: 2px solid #ccc !important;
        padding-left: 10px !important;
    }

    #adminSearchOnly {
        width: 180px !important;
        border: 2px solid #ccc !important;
        padding-left: 10px !important;
    }
}

@media screen and (max-width: 360px){
    .tbs--main--data--contact--mutual--featured--user a {
        display: block;
        margin-bottom: 20px;
    }
    #network .tbs--network--post--bookmark {
        margin-left: -45px !important;
        margin-top: -7.5px;
    }
}

@media screen and (max-width: 490px){
    .tbs--main--data--contact--mutual--featured--user a {
        display: block;
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 301px) and (max-width: 490px){
    #new-comment-postfeed input,
    #new-comment-postfeed textarea {
        flex: 0 0 87%;
    }
    #new-comment-postfeed > div {
        flex: 0 0 13%;
    }
}

@media screen and (min-width: 301px) and (max-width: 336px){
    .tbs--contact--details .tbs--contact--data--post,
    .tbs--contact--details .tbs--contact--data--feedlist {
        max-width: 165px;
    }
}

@media screen and (min-width: 337px) and (max-width: 425px){
    .tbs--contact--details .tbs--contact--data--post,
    .tbs--contact--details .tbs--contact--data--feedlist {
        max-width: 200px;
    }
}

@media screen and (min-width: 426px) and (max-width: 502px){
    .tbs--contact--details .tbs--contact--data--post,
    .tbs--contact--details .tbs--contact--data--feedlist {
        max-width: 300px;
    }
}

@media screen and (min-width: 768px) and (max-width: 830px){
    .tbs--contact--details .tbs--contact--data--post,
    .tbs--contact--details .tbs--contact--data--feedlist {
        max-width: 330px;
    }
    .tbs-highlight-posts .tbs--container--post--image,
    .tbs-highlight-posts .tbs--container--feedlist--image {
        margin-top: 35px !important;
    }
    /*.tbs--container--post--image,
    .tbs--container--feedlist--image {
        margin-top: 0px !important;
    }*/
}

@media screen and (min-width: 768px) and (max-width: 923px){
    .modal-dialog {
        left: 105px;
    }
}

@media screen and (min-width: 924px) and (max-width: 991px){
    .modal-dialog {
        left: 120px;
    }
}

@media screen and (max-width: 991px) {
    #groups .dropdown-menu:before,
    #chats .dropdown-menu:before {
        right: 32.5px;
    }
    #groups .dropdown-menu-right,
    #chats .dropdown-menu-right {
        margin-top: -9px !important;
        inset: 0px auto auto 0px !important;
        transform: translate3d(-202px, 54px, 0px) !important;
    }
    #groups .dropdown-menu-right{
        margin-left: 19px !important;
        z-index: 1000;
    }
    #chats .dropdown-menu-right {
        margin-left: 48px !important;
        z-index: 1000;
    }
    .tbs--chat--details {
        width: 25px;
        height: 25px;
        background-color: #fff;
        border: 1px solid #141c27;
        border-radius: 25px;
        display: block;
        margin-left: -20px;
    }
    .tbs--chat--details-without {
        width: 25px;
        height: 25px;
        background-color: #141c27;
        border-radius: 25px;
        display: block;
        margin-left: -20px;
    }
    .tbs--submenu--chat--overlay {
        display: none;
        position: absolute;
        width: 200px;
        right: 5px !important;
        border: 1px solid #ccc;
        padding: 10px;
        background-color: #fff;
        z-index: 1000;
        top: 42px;
    }
    .tbs--submenu--chat--overlay:before {
        content: "";
        position: absolute;
        right: 11px;
        top: -6px;
        width: 10px;
        height: 10px;
        border-top: 1px solid #ccc;
        background-color: #fff;
        z-index: 1000;
        border-right: 1px solid #ccc;
        transform: rotate(-45deg);
    }
    .tbs--container--network > .row > div:nth-child(2) > div {
        text-align: left !important;
        margin-top: 0px !important;
    }
    #chat-contacts > div > div:not(:first-child),
    #group-contacts > div > div:not(:first-child) {
        position: relative;
    }
    #chat-contacts > div > div:not(:first-child) > div:nth-child(2),
    #group-contacts > div > div:not(:first-child) > div:nth-child(2) {
        position: absolute;
        max-width: 10%;
        right: 0;
    }
    #groups #group-contacts .container-fluid,
    #chats #chat-contacts .container-fluid {
        border-radius: 0px !important;
        border-top-color: #FBFBFE;
        border-left-color: #FBFBFE;
        border-right-color: #FBFBFE;
        border-bottom-color: #FBFBFE;
        /*min-height: calc(87.5vh);*/
        padding-left: 0;
    }
}

@media screen and (min-width: 992px) {
    .tbs--chat--details {
        width: 25px;
        height: 25px;
        background-color: #fff;
        border: 1px solid #141c27;
        border-radius: 25px;
        display: block;
    }

    .tbs--chat--details-without {
        width: 25px;
        height: 25px;
        background-color: #141c27;
        border-radius: 25px;
        display: block;
    }
    /*#group-room-contact #group-new-message > div > div > div > div > div.tbs--form--complete--post > form > div.row {
        margin-right: -65px;
    }*/
    #tip-chats,
    #tip-groups {
        padding-right: 5px;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a > div {
        /*height: 100%;*/
    }
    .new-feedlist .tbs-friend-posts.row > div.tbs--contact--details {
        height: 80px;
    }
    .tbs--contact--data--feedlist--title {
        /*height: 100px;*/
        margin-bottom: 15px;
    }
    .no-description-and-image {
        opacity: 0;
    }
    .tbs--contact--data--feedlist--without--image {
        /*height: 50% !important;*/
    }
    .tbs--audio--feed {
        /*top: 65% !important;*/
    }
    #network .tbs--container--network--blocks--custom {
        display: inline-block;
        width: 49%;
    }
    #network .tbs--container--network--blocks {
        height: calc(67vh);
    }
    #my-awards-overview .tbs--awards--user--highlights > span {
        right: 25px;
    }
    #network .network--text--image > div:last-child {
        height: 170px !important;
    }
    .onboarding-details {
        top: 50% !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1100px){
    /*.new-feedlist > div:nth-child(3) > .tbs--container--feedlist {
        display: flex;
        flex-wrap: wrap;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a {
        flex: 0 0 46.75%;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a:nth-child(even) {
        margin-left: 20px;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a:nth-child(odd) {
        margin-right: 20px;
    }*/
    #network .tbs--container--network--blocks--custom:nth-child(odd) {
        margin-left: 5px;
    }
    #network .tbs--container--network--blocks--custom:nth-child(even) {
        margin-right: 5px;
    }
    .tbs-highlight-posts > div:nth-child(2) > div:nth-child(2) > div {
        width: 250px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1118px){
    #network .tbs--container--network--blocks--custom:nth-child(odd) {
        margin-left: 5px;
    }
    #network .tbs--container--network--blocks--custom:nth-child(even) {
        margin-right: 5px;
    }
}

@media screen and (min-width: 1119px) and (max-width: 1300px){
    #network .tbs--container--network--blocks--custom:nth-child(odd) {
        margin-left: 7.5px;
    }
    #network .tbs--container--network--blocks--custom:nth-child(even) {
        margin-right: 7.5px;
    }
    .tbs-highlight-posts > div:nth-child(2) > div:nth-child(2) > div {
        width: 250px;
    }
}

@media screen and (min-width: 1301px){
    #network .tbs--container--network--blocks--custom:nth-child(odd) {
        margin-left: 9px;
    }
    #network .tbs--container--network--blocks--custom:nth-child(even) {
        margin-right: 9px;
    }
}

/*@media screen and (min-width: 1101px) and (max-width: 1200px){
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist {
        display: flex;
        flex-wrap: wrap;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a {
        flex: 0 0 47.25%;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a:nth-child(even) {
        margin-left: 20px;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a:nth-child(odd) {
        margin-right: 20px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1305px){
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist {
        display: flex;
        flex-wrap: wrap;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a {
        flex: 0 0 47.5%;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a:nth-child(even) {
        margin-left: 20px;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a:nth-child(odd) {
        margin-right: 20px;
    }
}

@media screen and (min-width: 1306px) and (max-width: 1400px){
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist {
        display: flex;
        flex-wrap: wrap;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a {
        flex: 0 0 47.75%;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a:nth-child(even) {
        margin-left: 20px;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a:nth-child(odd) {
        margin-right: 20px;
    }
}

@media screen and (min-width: 1401px){
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist {
        display: flex;
        flex-wrap: wrap;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a {
        flex: 0 0 47.85%;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a:nth-child(even) {
        margin-left: 20px;
    }
    .new-feedlist > div:nth-child(3) > .tbs--container--feedlist > a:nth-child(odd) {
        margin-right: 20px;
    }
}*/

@media screen and (min-width: 768px) and (max-width: 991px){
    .modal-dialog {
        left: 140px;
    }
    .tbs--container--network--blocks--two > .row > div {
        /*border: 1px solid #ccc;*/
        /*max-width: 30% !important;*/
        /*margin-right: 10px;*/
        padding: 35px 0px;
        /*margin-bottom: 15px;*/
    }
    .tbs--container--network--blocks--two > .row > div:first-child {
        margin-left: 0px !important;
    }
    .tbs--container--network--blocks--two > .row > div:last-child {
        margin-right: 0px !important;
    }
}

@media screen and (min-width: 767px) and (max-width: 787px){
    .tbs--container--network--blocks--two > .row > div:nth-child(2) {
        /*margin-left: 20px !important;
        margin-right: 20px !important;*/
    }
}

@media screen and (min-width: 788px) and (max-width: 991px){
    .tbs--container--network--blocks--two > .row > div:nth-child(2) {
        /*margin-left: 25px !important;
        margin-right: 25px !important;*/
    }
}

@media screen and (min-width: 992px) and (max-width: 1050px){
    .tbs--container--network--blocks--two > .row > div:nth-child(2) {
        /*margin-left: 22.5px !important;
        margin-right: 22.5px !important;*/
    }
}

@media screen and (min-width: 1051px) and (max-width: 1200px){
    .tbs--container--network--blocks--two > .row > div:nth-child(2) {
        /*margin-left: 26px !important;
        margin-right: 26px !important;*/
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px){
    .tbs--container--network--blocks--two > .row > div:nth-child(2) {
        /*margin-left: 32px !important;
        margin-right: 32px !important;*/
    }
}

@media screen and (min-width: 1301px) and (max-width: 1350px){
    .tbs--container--network--blocks--two > .row > div:nth-child(2) {
        /*margin-left: 33.5px !important;
        margin-right: 33.5px !important;*/
    }
}

@media screen and (min-width: 1351px){
    .tbs--container--network--blocks--two > .row > div:nth-child(2) {
        /*margin-left: 36px !important;
        margin-right: 36.5px !important;*/
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px){
    .modal-dialog {
        left: 140px;
    }
    .tbs--container--network--blocks--two > .row > div {
        /*border: 1px solid #ccc;*/
        /*max-width: 30% !important;
        margin-right: 10px;*/
        padding: 35px 0px;
        /*margin-bottom: 15px;*/
    }
    .tbs--container--network--blocks--two > .row > div:first-child {
        margin-left: 0px !important;
    }
    .tbs--container--network--blocks--two > .row > div:last-child {
        margin-right: 0px !important;
    }
}

@media screen and (max-width: 270px){
    #group-contacts .row:not(:first-child) > div > a > .row > div > div:last-child,
    #chat-contacts .row:not(:first-child) > div > a > .row > div > div:last-child {
        max-width: 125px;
    }
}

@media screen and (min-width: 271px) and (max-width: 400px){
    #group-contacts .row:not(:first-child) > div > a > .row > div > div:last-child,
    #chat-contacts .row:not(:first-child) > div > a > .row > div > div:last-child {
        max-width: 175px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1030px){
    #group-contacts .row:not(:first-child) > div > a > .row > div > div:last-child,
    #chat-contacts .row:not(:first-child) > div > a > .row > div > div:last-child {
        max-width: 170px;
    }
}

@media screen and (min-width: 1031px) and (max-width: 1300px){
    #group-contacts .row:not(:first-child) > div > a > .row > div > div:last-child,
    #chat-contacts .row:not(:first-child) > div > a > .row > div > div:last-child {
        max-width: 200px;
    }
}

@media screen and (max-width: 270px){
    .tbs-counter {
        position: absolute;
        margin-top: 0!important;
        right: 50px !important;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 271px) and (max-width: 300px){
    .tbs-counter {
        position: absolute;
        margin-top: 0!important;
        right: 70px !important;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 301px) and (max-width: 330px){
    .tbs-counter {
        position: absolute;
        margin-top: 0!important;
        right: 95px !important;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 331px) and (max-width: 350px){
    .tbs-counter {
        position: absolute;
        margin-top: 0!important;
        right: 105px !important;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 351px) and (max-width: 380px){
    .tbs-counter {
        position: absolute;
        margin-top: 0!important;
        right: 110px !important;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 381px) and (max-width: 390px){
    .tbs-counter {
        position: absolute;
        margin-top: 0 !important;
        right: 115px !important;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
    #most-awarded > div,
    #most-featured > div {
        max-width: 100% !important;
        text-align: center;
        flex: 0 0 100% !important;
    }
}

@media screen and (min-width: 391px) and (max-width: 480px){
    .tbs-counter {
        position: absolute;
        margin-top: 0 !important;
        right: 10px;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 481px) and (max-width: 570px){
    .tbs-counter {
        position: absolute;
        margin-top: 0 !important;
        right: 25px;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 571px) and (max-width: 680px){
    .tbs-counter {
        position: absolute;
        margin-top: 0 !important;
        right: 35px;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 681px) and (max-width: 767px){
    .tbs-counter {
        position: absolute;
        margin-top: 0 !important;
        right: 50px;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 768px) and (max-width: 900px){
    .tbs-counter {
        position: absolute;
        margin-top: 0 !important;
        right: 25px;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 1001px) and (max-width: 1200px){
    .tbs-counter {
        position: absolute;
        margin-top: 0 !important;
        right: 60px;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1360px){
    .tbs--container--network--blocks--two > .row > div {
        /*border: 1px solid #ccc;*/
        /*max-width: 30% !important;
        margin-right: 10px;*/
        padding: 35px 0px;
        /*margin-bottom: 15px;*/
    }
    .tbs--container--network--blocks--two > .row > div:first-child {
        margin-left: 0px !important;
    }
    .tbs--container--network--blocks--two > .row > div:last-child {
        margin-right: 0px !important;
    }
}

@media screen and (min-width: 1361px){
    .tbs--container--network--blocks--two > .row > div {
        /*border: 1px solid #ccc;*/
        /*max-width: 30% !important;
        margin-right: 10px;*/
        padding: 35px 0px;
        /*margin-bottom: 15px;*/
    }
    .tbs--container--network--blocks--two > .row > div:first-child {
        margin-left: 0px !important;
    }
    .tbs--container--network--blocks--two > .row > div:last-child {
        margin-right: 0px !important;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px){
    .tbs-counter {
        position: absolute;
        margin-top: 0 !important;
        right: 70px;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1400px){
    .tbs-counter {
        position: absolute;
        margin-top: 0 !important;
        right: 80px;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1500px){
    .tbs-counter {
        position: absolute;
        margin-top: 0 !important;
        right: 90px;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 1501px){
    .tbs-counter {
        position: absolute;
        margin-top: 0 !important;
        right: 100px;
        bottom: -10px;
        background-color: #141c27;
        color: #fff;
        padding: 4px 10px;
        border-radius: 25px;
        border: 2px solid #fff;
    }
}

@media screen and (min-width: 1201px){
    .modal-dialog {
        left: 170px;
    }
    .tbs--message--complete--block--one {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .tbs--message--complete--block--two {
        max-width: 5%;
        flex: 0 0 5%;
    }
}

@media screen and (max-width: 280px){
    #settings .tbs--container {
        overflow: auto;
        height: calc(100vh);
    }
    #settings .container > .row:not(:first-child) h3,
    #settings .container-fluid > .row:not(:first-child) h3 {
        font-size: 20px;
    }
}

@media screen and (min-width: 281px) and (max-width: 340px){
    .mm-listview li:nth-child(1),
    .mm-listview li:nth-child(2){
        display: flex;
    }
    #invitation-overlay,
    #notification-overlay {
        display: none;
    }
    #mainNavi {
        height: 68px;
    }
}

@media screen and (max-width: 280px){
    #invitation-overlay,
    #notification-overlay {
        display: none;
    }
    .mm-listview li:nth-child(1),
    .mm-listview li:nth-child(2){
        display: flex;
    }
    .mm-listview > li:nth-child(2) > a.dropdown-item > img{
        margin-right: 25px;
    }
    .tbs-my-posts > div:first-child > div:nth-child(2),
    .tbs-friend-posts > div:first-child > div:nth-child(2),
    .tbs-highlight-posts > div:nth-child(2) > div:nth-child(2),
    .tbs-my-posts > div:first-child > div:nth-child(2),
    .tbs-friend-posts > div:first-child > div:nth-child(2),
    .tbs-highlight-posts > div:nth-child(2) > div:nth-child(2) {
        display: table-cell;
        width: 67%;
    }
    #network-contacts,
    #new-post,
    #report-post,
    #favourites,
    #my-profile,
    #my-account,
    #feedback,
    #bug-report,
    #report-chat-conversation,
    #report-group-conversation,
    #chat-publication,
    #group-publication,
    #invitation,
    #new-invitation,
    #network,
    #feeds {
        margin-top: -10px !important;
    }
    #feed-list,
    .create-new-community {
        margin-top: -18px !important;
    }
    #notification,
    #settings {
        margin-top: -37px !important;
    }
    #groups,
    #chats {
        margin-top: -24px !important;
    }
    #mainNavi {
        height: 68px;
    }
}

@media screen and (max-width: 280px){
    #invitation-overlay .dropdown-menu-right,
    #notification-overlay .dropdown-menu-right {
        /*min-width: 100px;*/
        max-width: 170px;
    }
}

@media screen and (min-width: 281px) and (max-width: 400px){
    #invitation-overlay .dropdown-menu-right,
    #notification-overlay .dropdown-menu-right {
        /*min-width: 170px;*/
        max-width: 240px;
    }
}

@media screen and (max-width: 440px){
    .tbs--main--data--contact--feature,
    .tbs--main--data--contact--introduce,
    .tbs--main--data--contact--chat {
        margin: 15px auto;
        display: block;
        width: 100%;
    }

    .tbs--main--data--contact--chat {
        width: auto;
        display: block;
    }
}

@media screen and (max-width: 280px){
    #own--feeds > div:nth-child(2) > div:nth-child(1) > div > a > div:nth-child(2) > .tbs--contact--details{
        min-width: 100px !important;
        max-width: 190px !important;
    }
    #own--feeds > div:nth-child(2) > div:nth-child(1) > div > a > div:nth-child(2) > .tbs--contact--details > .tbs--contact--data--feedlist{
        margin-right: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 281px) and (max-width: 320px){
    #own--feeds > div:nth-child(2) > div:nth-child(1) > div > a > div:nth-child(2) > .tbs--contact--details{
        min-width: 100px !important;
        max-width: 200px !important;
    }
    #own--feeds > div:nth-child(2) > div:nth-child(1) > div > a > div:nth-child(2) > .tbs--contact--details > .tbs--contact--data--feedlist{
        margin-right: 0;
        padding-right: 0;
    }
}

@media screen and (max-width: 230px){
    #audio-recording-update-post > div > div > div > div > div > div:nth-child(1) > audio {
        max-width: 140px;
    }
}

@media screen and (max-width: 260px){
    .tbs--public--communinity--request {
        width: 100px !important;
        height: 100px !important;
        text-align: center !important;
    }
}

@media screen and (min-width: 231px) and (max-width: 330px){
    #audio-recording-update-post > div > div > div > div > div > div:nth-child(1) > audio {
        max-width: 200px;
    }
}

@media screen and (min-width: 321px) and (max-width: 360px){
    #own--feeds > div:nth-child(2) > div:nth-child(1) > div > a > div:nth-child(2) > .tbs--contact--details{
        min-width: 200px !important;
        max-width: 260px !important;
    }
    #own--feeds > div:nth-child(2) > div:nth-child(1) > div > a > div:nth-child(2) > .tbs--contact--details > .tbs--contact--data--feedlist{
        margin-right: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 361px) and (max-width: 400px){
    #own--feeds > div:nth-child(2) > div:nth-child(1) > div > a > div:nth-child(2) > .tbs--contact--details{
        min-width: 200px !important;
        max-width: 280px !important;
    }
    #own--feeds > div:nth-child(2) > div:nth-child(1) > div > a > div:nth-child(2) > .tbs--contact--details > .tbs--contact--data--feedlist{
        margin-right: 0;
        padding-right: 0;
    }
}

@media screen and (max-width: 300px){
    .tbs--comment--default--discussion .tbs--discussion--comment--detail--block {
        width: 79% !important;
    }
    .tbs--comment--default--discussion--reply .tbs--discussion--comment--detail--block {
        width: 74% !important;
        /*margin-left: 50px;*/
    }
    #post-feed button {
        padding: 12.5px 0px !important;
    }
    .tbs--contact--data--feeds span > span, .tbs--contact--data--feeds span > a > span {
        padding: 10px !important;
    }
    #dashboard nav a, #dashboard nav > div {
        margin-left: 3px !important;
        margin-right: 3px !important;
    }
    .tbs--container--tips > div > div:nth-child(2) {
        margin-left: -30px;
    }
}

@media screen and (min-width: 301px) and (max-width: 380px){
    .tbs--comment--default--discussion .tbs--discussion--comment--detail--block {
        width: 79% !important;
    }
    .tbs--comment--default--discussion--reply .tbs--discussion--comment--detail--block {
        width: 74% !important;
        /*margin-left: 50px;*/
    }
    .tbs--comment--default--discussion .tbs--line--form > div:first-child > div:first-child > form > div:first-child > div:first-child {
        max-width: 59.5% !important;
    }
    .tbs--container--tips > div > div:nth-child(2) {
        margin-left: -20px;
    }
}

@media screen and (max-width: 380px){
    #most-awarded > div,
    #most-featured > div {
        max-width: 100% !important;
        text-align: center;
        flex: 0 0 100% !important;
    }
    #network-headline > div:nth-child(2) {
        margin-top: 8px;
        padding-right: 70px;
        margin-left: -25px;
    }
}

@media screen and (min-width: 381px) and (max-width: 430px){
    .tbs--comment--default--discussion .tbs--discussion--comment--detail--block {
        width: 85%;
    }
    .tbs--comment--default--discussion--reply .tbs--discussion--comment--detail--block {
        width: 83%;
        /*margin-left: 50px;*/
    }
}

@media screen and (min-width: 431px) and (max-width: 575px){
    .tbs--comment--default--discussion .tbs--line--form > div:first-child > div:first-child > form > div:first-child > div:first-child {
        max-width: 63.5% !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 948px){
    .tbs--comment--default--discussion .tbs--discussion--comment--detail--block {
        width: 87%;
    }
    .tbs--comment--default--discussion--reply .tbs--discussion--comment--detail--block {
        width: 86%;
        /*margin-left: 50px;*/
    }
}

@media screen and (min-width: 949px){
    .tbs--comment--default--discussion .tbs--discussion--comment--detail--block {
        width: 90%;
    }
    .tbs--comment--default--discussion--reply .tbs--discussion--comment--detail--block {
        width: 89%;
        /*margin-left: 50px;*/
    }
}

@media screen and (max-width: 300px){
    #chat-new-message textarea {
        width: 80%;
    }
}

@media screen and (min-width: 301px) and (max-width: 380px){
    #chat-new-message textarea {
        width: 82.5%;
    }
}

@media screen and (min-width: 381px) and (max-width: 430px){
    #chat-new-message textarea {
        width: 86%;
    }
}

@media screen and (min-width: 431px) and (max-width: 550px){
    #chat-new-message textarea {
        width: 88%;
    }
}

@media screen and (min-width: 551px) and (max-width: 680px){
    #chat-new-message textarea {
        width: 90%;
    }
}

@media screen and (min-width: 681px) and (max-width: 767px){
    #chat-new-message textarea {
        width: 92%;
    }
}

@media screen and (min-width: 768px) and (max-width: 870px){
    #chat-new-message textarea {
        width: 89%;
    }
}

@media screen and (min-width: 871px) and (max-width: 991px){
    .tbs--comment--default--discussion .tbs--line--form > div:first-child > div:first-child > form > div:first-child > div:first-child {
        max-width: 67.5% !important;
    }
    #chat-new-message textarea {
        width: 90.5%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1100px){
    #chat-new-message textarea {
        width: 91%;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1200px){
    #chat-new-message textarea {
        width: 92%;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px){
    #chat-new-message textarea {
        width: 92.5%;
    }
}

@media screen and (min-width: 1401px){
    #chat-new-message textarea {
        width: 93.5%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1100px){
    .tbs--contact--discussion--reply--profiles {
        margin-left: 10px;
    }
    .tbs--contact--discussion--profiles > .row > div > .tbs--discussion--comment--detail--block,
    .tbs--contact--discussion--profiles > .row > div > .reply--comment {
        margin-left: 15px;
    }
}

@media screen and (min-width: 1101px){
    .tbs--contact--discussion--reply--profiles {
        margin-left: 10px;
    }
    .tbs--contact--discussion--profiles > .row > div > .tbs--discussion--comment--detail--block,
    .tbs--contact--discussion--profiles > .row > div > .reply--comment {
        margin-left: 15px;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1300px){
    .tbs--contact--discussion--reply--profiles {
        margin-left: 20px;
    }
}

@media screen and (min-width: 1301px){
    .tbs--contact--discussion--reply--profiles {
        margin-left: 30px;
    }
}

@media screen and (max-width: 400px){
    .tbs--featured--users--name {
        max-width: 100px;
        word-break: break-all;
    }
    /*.tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 40%;
        width: 100%;
        border: 1px solid #ccc !important;
    }*/
}

@media screen and (max-width: 320px){
    #loginForm input,
    #loginForm .tbs--form--complete,
    #forgotPasswordForm input,
    #forgotPasswordForm .tbs--form--complete,
    #resetPasswordForm input,
    #resetPasswordForm .tbs--form--complete,
    #registerForm input,
    #registerForm .tbs--form--complete {
        max-width: 270px;
    }
    .tbs--other--email span {
        top: -15px !important;
        right: 0px !important;
    }
    #other-email-form {
        margin-top: 20px;
    }
}

@media screen and (max-width: 290px){
    #loginForm input,
    #loginForm .tbs--form--complete,
    #forgotPasswordForm input,
    #forgotPasswordForm .tbs--form--complete,
    #resetPasswordForm input,
    #resetPasswordForm .tbs--form--complete,
    #registerForm input,
    #registerForm .tbs--form--complete  {
        max-width: 250px;
    }
}

@media screen and (max-width: 247px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 15%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 248px) and (max-width: 280px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 22%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 281px) and (max-width: 308px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 33.5%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 309px) and (max-width: 333px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 41%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 334px) and (max-width: 363px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 46.25%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 364px) and (max-width: 400px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 51.5%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 401px) and (max-width: 425px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 56.5%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 426px) and (max-width: 454px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 59.5%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 455px) and (max-width: 490px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 62.25%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 491px) and (max-width: 520px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 65%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 520px) and (max-width: 547px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 67%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 548px) and (max-width: 585px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 69%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 586px) and (max-width: 625px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 71%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 626px) and (max-width: 675px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 73%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 676px) and (max-width: 730px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 75%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 731px) and (max-width: 767px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 77%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 810px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 67.25%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 811px) and (max-width: 861px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 69%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 862px) and (max-width: 897px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 70.5%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 898px) and (max-width: 915px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 72%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 916px) and (max-width: 980px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 73%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 981px) and (max-width: 1015px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 75%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 1016px) and (max-width: 1095px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 76%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 1096px) and (max-width: 1140px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 78%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 1141px) and (max-width: 1195px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 79%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 1196px) and (max-width: 1269px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 80%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 1270px) and (max-width: 1320px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 82%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 1321px) and (max-width: 1430px){
    .tbs--create--post--container .tbs--container--feedlist--item input {
        padding: 10px !important;
        margin-left: 15px;
        max-width: 82%;
        width: 100%;
        border: 1px solid #ccc !important;
    }
}

@media screen and (min-width: 271px) and (max-width: 350px){
    .form--comments form > .row {
        margin-left: -5px;
        margin-right: 30px;
    }
}

@media screen and (min-width: 351px) and (max-width: 440px){
    .form--comments form > .row {
        margin-left: -5px;
        margin-right: 20px;
    }
}

@media screen and (min-height: 401px) {
    #delete-file {
        position: absolute;
        top: 0;
        right: 0;
    }
}

@media screen and (max-height: 400px) {
    .new-image-upload #delete-file {
        border: none !important;
        right: 80px;
        bottom: 0px;
        z-index: 1000;
    }

    #delete-file {
        position: absolute;
        bottom: 0 !important;
        right: 0;
    }
}

/*@media screen and (min-width: 1481px){
    .slider-main-switch-container {
        position: absolute;
        right: 100px;
        bottom: 162px;
        z-index: 20;
    }

    .slider-main-switch-container-active {
        position: absolute;
        right: 100px;
        bottom: 255px;
        z-index: 20;
    }
}*/
